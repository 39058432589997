<template>
  <v-card
    max-width="100%"
    class="mx-auto elevation-10"
    elevation-10
    color="white lighten-3"
  >
    <v-app-bar dark color="blue lighten-5">
      <v-btn icon>
        <v-icon class="black--text">mdi-arrow-left </v-icon>
      </v-btn>
      <v-toolbar-title class="black--text">Completar Cadastro</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-container class="fill-height" fluid>
      <v-row  fluid>
        <v-col cols="12" align-self="center" >
            <form-pf-component></form-pf-component>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import FormPfComponent from './FormPfComponent.vue';
export default {
  components: { FormPfComponent },
    mounted() {
    
    if(this.$store.state.auth.user.user_juridica){
      this.tabs= 0
    }else{
      this.tabs = 1
      
    }
  },
  data: () => ({
    tabs: null,
  }),

};
</script>

