var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"v-container"},[_c('search-protocols-component',{on:{"emit-busca":_vm.retornoBusca,"emit-pdf":_vm.gerarPdf}}),_c('v-layout',{attrs:{"row":"","wrap":"","justify-space-around":""}},[_c('v-flex',{staticClass:"pa-1",attrs:{"xs12":"","md12":""}},[_c('v-card',{staticClass:"mx_h",attrs:{"elevation":"0"}},[_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.list,"footer-props":{
              'items-per-page-text': 'Registros por página: ',
              'page-text': '{0}-{1} de {2}',
              'items-per-page-all-text': 'Listar todos',
            }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',[_c('th',[_vm._v("Protocolo")]),_c('th',[_vm._v("Lote")]),_c('th',[_vm._v("Devedor")]),_c('th',[_vm._v("CNPJ/CPF (Devedor)")]),_c('th',[_vm._v("Data vencimento")]),_c('th',[_vm._v("Valor Vencido")]),_c('th',[_vm._v("Nº Titulo")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Ações")])])]},proxy:true},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.protocolo))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.lote))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.cliente.name))]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatarCpfCnpj(item.cliente.cpf_cnpj))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.converterDataTimestamp(item.data_vencimento))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.converterDinherio(item.valor_vencido))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.numero_titulo))]),_c('td',{staticClass:"text-center"},[_c('v-chip',{staticClass:"white-text",attrs:{"label":"","small":"","color":_vm.coresStatus(item.status)}},[_vm._v(_vm._s(item.nome))])],1),_c('td',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(item.path_anexo != null)?_c('v-icon',[_vm._v("mdi-paperclip ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Dívida possui documento anexo.")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(item.coautores > 0)?_c('v-icon',[_vm._v("mdi-account-multiple ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Dívida possui coautores.")])]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.log(item.protocolo)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.historico(item.protocolo)}}},[_c('v-icon',[_vm._v("mdi-file-document ")])],1)],1)])]}}])},[_c('v-alert',{attrs:{"slot":"no-data","value":true},slot:"no-data"},[_vm._v(" Sem dados ")])],1)],1),_c('v-card-text',[_c('log-component',{attrs:{"dialog_log":_vm.dialog_log,"protocolo":_vm.protocolo,"registros":_vm.registros},on:{"close":function($event){_vm.dialog_log = false}}})],1),_c('v-card-text',[_c('history-component',{attrs:{"dialog_history":_vm.dialog_history,"visualizacao":_vm.visualizacao},on:{"close":function($event){_vm.dialog_history = false}}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }