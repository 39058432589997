<template>
  <v-container class="mx_h">
    <v-expansion-panels flat :value="0">
      <v-expansion-panel>
        <v-expansion-panel-header> Filtros </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="my-6">
            <v-col cols="2">
              <v-datetime-field
                only-date
                v-model="datainclusao"
                labelDate="Data Início"
                color="blue"
                filled
                rounded
                dense
                background-color="white"
              >
              </v-datetime-field>
            </v-col>
            <v-col cols="2">
              <v-datetime-field
                only-date
                v-model="datainclusaoFinal"
                labelDate="Data Fim"
                color="blue"
                filled
                rounded
                dense
                background-color="white"
              >
              </v-datetime-field>
            </v-col>

            <v-col cols="3">
              <v-text-field
                filled
                rounded
                dense
                background-color="white"
                v-model="srcCredor"
                label="CPF/CNPJ Credor"
              >
              </v-text-field>
            </v-col>

            <v-col cols="3">
              <v-text-field
                filled
                rounded
                dense
                background-color="white"
                v-model="srcConsumidor"
                label="CPC/CNPJ Consumidor"
              >
              </v-text-field>
            </v-col>

            <v-col cols="4" sm="4" md="4">
              <v-select
                v-model="srcOrigem"
                :items="[{ name: 'MONITORA' }, { name: 'PGFN' }]"
                item-text="name"
                clearable
                filled
                            rounded
                            dense
                            background-color="white"
                item-value="name"
                label="Origem da inclusão"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-layout row wrap justify-end fill-height> </v-layout>
              <v-flex align-self-end>
                <v-btn
                  class="orange mr-1"
                  color="white--text "
                  @click="getPreDividas"
                  ><v-icon>mdi-magnify</v-icon>Buscar</v-btn
                >

                <v-btn
                  class="grey mr-1"
                  @click="limparFiltros"
                  color="white--text "
                  ><v-icon>mdi-close-circle-outline</v-icon>Limpar
                  Filtros</v-btn
                >
              </v-flex>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-layout row wrap justify-space-around fill-height>
      <!-- TABELA -->
      <v-flex xs12 md12 class="pa-1">
        <v-card class="mx_h" elevation="0">
          <v-card-text>
            <v-data-table
              dense
              :headers="[
                { text: 'Ações' },
                { text: 'Data' },
                { text: 'Origem' },
                { text: 'Status', width: '140px' },
                { text: 'Usuário', width: '240px' },
                { text: 'N. Doc.' },
                { text: 'Dt.Emissão' },
                { text: 'Dt.Vencimento' },
                { text: 'Valor' },
                { text: 'Cnpj Credor', width: '180px' },
                { text: 'Credor Cadastrado' },
              ]"
              :items="list"
              item-key="lote"
              :single-expand="true"
              :search="search"
              show-expand
              class="elevation-0"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td></td>
                  <td class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          elevation="0"
                          small
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="modalAnalizar(item)"
                        >
                          <v-icon dark> mdi-text-box-check-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Exibir Detalhes</span>
                    </v-tooltip>
                  </td>
                  <td>{{ item.datacriacao }}</td>
                  <td>{{ item.origem }}</td>
                  <td>
                    {{ item.integrado == 0 ? "Não Integrado" : "Integrado" }}
                  </td>
                  <td>{{ item.user_id + "-" + item.userDetails.name }}</td>
                  <td>{{ item.numero_titulo }}</td>
                  <td>{{ converterDataTimestamp(item.data_emissao) }}</td>
                  <td>{{ converterDataTimestamp(item.data_vencimento) }}</td>
                  <td>{{ converterDinherio(item.valor_vencido) }}</td>
                  <td>{{ formatarCpfCnpj(item.cnpj_credor) }}</td>
                  <td>{{ item.empresa_negativa ? "S" : "N" }}</td>
                </tr>
              </template>
              <v-alert slot="no-results" :value="true">
                Sua busca por "{{ search }}" não encontrou resultados.
              </v-alert>
              <v-alert slot="no-data" :value="true"> Sem dados </v-alert>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialogRejeitar" scrollable max-width="1000">
      <v-card>
        <v-card-title>Rejeitar Dívida</v-card-title>
        <v-card-text>
          <v-row class="pt-5">
            <v-col cols="12" sm="12" md="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                label="Motivo"
                outlined
                readonly
                color="blue "
                dense
                :value="prenchido(item.motivoRejeicao)"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" scrollable max-width="1000">
      <v-card>
        <v-card-title>Informação da Pré-Dívida</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px">
          <v-row class="pt-5">
            <v-col cols="8" sm="8" md="8">
              <h3>Dados do consumidor</h3>
            </v-col>
            <v-col cols="8" sm="8" md="8">
              <v-text-field
                label="Nome"
                outlined
                readonly
                color="blue "
                dense
                :value="prenchido(item.userDetails.name)"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4" sm="4" md="4">
              <v-text-field
                label="CPF/CNPJ"
                outlined
                readonly
                color="blue "
                dense
                :value="formatarCpfCnpj(prenchido(item.userDetails.cpf_cnpj))"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                label="E-mail"
                outlined
                readonly
                color="blue "
                dense
                :value="prenchido(item.userDetails.email)"
              >
              </v-text-field>
            </v-col>

            <v-col cols="6" sm="6" md="6">
              <v-text-field
                label="Telefone Principal"
                outlined
                readonly
                color="blue "
                dense
                :value="prenchido(item.userDetails.telefone)"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                label="Endereço"
                outlined
                readonly
                color="blue "
                dense
                :value="prenchido(item.endereco.endereco)"
              >
              </v-text-field>
            </v-col>
            <v-col cols="3" sm="2" md="2">
              <v-text-field
                label="Nro"
                outlined
                readonly
                color="blue "
                dense
                :value="prenchido(item.endereco.numero)"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4" sm="4" md="4">
              <v-text-field
                label="Bairro"
                outlined
                readonly
                color="blue "
                dense
                :value="prenchido(item.endereco.bairro)"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4" sm="4" md="4">
              <v-text-field
                label="Cep"
                outlined
                readonly
                color="blue "
                dense
                :value="prenchido(item.endereco.cep)"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                label="Município"
                outlined
                readonly
                color="blue "
                dense
                :value="prenchido(item.endereco.municipio)"
              >
              </v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2">
              <v-text-field
                label="UF"
                outlined
                readonly
                color="blue "
                dense
                :value="prenchido(item.endereco.uf)"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <h3>Dados da Dívida</h3>
            </v-col>

            <v-col cols="3" sm="3" md="3">
              <v-text-field
                label="Número do Documento"
                outlined
                readonly
                color="blue "
                dense
                :value="prenchido(item.numero_titulo)"
              >
              </v-text-field>
            </v-col>
            <v-col cols="3" sm="3" md="3">
              <v-text-field
                label="Nosso número"
                outlined
                readonly
                color="blue "
                dense
                :value="prenchido(item.nosso_numero)"
              >
              </v-text-field>
            </v-col>
            <v-col cols="3" sm="3" md="3">
              <v-text-field
                label="Espécie"
                outlined
                readonly
                color="blue "
                dense
                :value="prenchido(item.especie)"
              >
              </v-text-field>
            </v-col>
            <v-col cols="3" sm="3" md="3">
              <v-text-field
                label="Valor Original"
                outlined
                readonly
                color="blue "
                dense
                :value="prenchido(converterDinherio(item.valor_original))"
              >
              </v-text-field>
            </v-col>
            <v-col cols="3" sm="3" md="3">
              <v-text-field
                label="Valor Vencido"
                outlined
                readonly
                color="blue "
                dense
                :value="prenchido(converterDinherio(item.valor_vencido))"
              >
              </v-text-field>
            </v-col>
            <v-col cols="3" sm="3" md="3">
              <v-text-field
                label="Data Emissão"
                outlined
                readonly
                color="blue "
                dense
                :value="prenchido(converterDataTimestamp(item.data_emissao))"
              >
              </v-text-field>
            </v-col>
            <v-col cols="3" sm="3" md="3">
              <v-text-field
                label="Data Vencimento"
                outlined
                readonly
                color="blue "
                dense
                :value="prenchido(converterDataTimestamp(item.data_vencimento))"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" sm="12" md="12" v-if="item.empresa_negativa">
              <v-row class="pt-5">
                <v-col cols="12" sm="12" md="12">
                  <h3>Dados da Credor(Ativo no sistema Negativa)</h3>
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    label="CNPJ/CPF"
                    outlined
                    readonly
                    color="blue "
                    dense
                    :value="prenchido(formatarCpfCnpj(item.cnpj_credor))"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="9" sm="9" md="9">
                  <v-text-field
                    label="Razão Social"
                    outlined
                    readonly
                    color="blue "
                    dense
                    :value="prenchido(item.credor.name)"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="7" sm="7" md="7">
                  <v-text-field
                    label="Endereço"
                    outlined
                    readonly
                    color="blue "
                    dense
                    :value="prenchido(item.credor.endereco_sub.endereco)"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2" sm="2" md="2">
                  <v-text-field
                    label="Nro"
                    outlined
                    readonly
                    color="blue "
                    dense
                    :value="prenchido(item.credor.endereco_sub.numero)"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    label="Bairro"
                    outlined
                    readonly
                    color="blue "
                    dense
                    :value="prenchido(item.credor.endereco_sub.bairro)"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12" v-if="!item.empresa_negativa">
              <v-row class="pt-5">
                <v-col cols="12" sm="12" md="12">
                  <h3>Dados da Credor(Não utiliza o sitema)</h3>
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    label="CNPJ/CPF"
                    outlined
                    readonly
                    color="blue "
                    dense
                    :value="prenchido(formatarCpfCnpj(item.cnpj_credor))"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="9" sm="9" md="9">
                  <v-text-field
                    label="Razão Social"
                    outlined
                    readonly
                    color="blue "
                    dense
                    :value="prenchido(empresa_s_cadastro.nome)"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="5" sm="5" md="5">
                  <v-text-field
                    label="Fantasia"
                    outlined
                    readonly
                    color="blue "
                    dense
                    :value="prenchido(empresa_s_cadastro.fantasia)"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2" sm="2" md="2">
                  <v-text-field
                    label="Situação"
                    outlined
                    readonly
                    color="blue "
                    dense
                    :value="prenchido(empresa_s_cadastro.situacao)"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="5" sm="5" md="5">
                  <v-text-field
                    label="Natureza Juridica"
                    outlined
                    readonly
                    color="blue "
                    dense
                    :value="prenchido(empresa_s_cadastro.natureza_juridica)"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2" sm="2" md="2">
                  <v-text-field
                    label="Tipo"
                    outlined
                    readonly
                    color="blue "
                    dense
                    :value="prenchido(empresa_s_cadastro.tipo)"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="7" sm="7" md="7">
                  <v-text-field
                    label="Endereco"
                    outlined
                    readonly
                    color="blue "
                    dense
                    :value="prenchido(empresa_s_cadastro.logradouro)"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2" sm="2" md="2">
                  <v-text-field
                    label="Nro"
                    outlined
                    readonly
                    color="blue "
                    dense
                    :value="prenchido(empresa_s_cadastro.numero)"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    label="Bairro"
                    outlined
                    readonly
                    color="blue "
                    dense
                    :value="prenchido(empresa_s_cadastro.bairro)"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <v-text-field
                    label="E-mail"
                    outlined
                    readonly
                    color="blue "
                    dense
                    :value="prenchido(empresa_s_cadastro.email)"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    label="Telefone"
                    outlined
                    readonly
                    color="blue "
                    dense
                    :value="prenchido(empresa_s_cadastro.telefone)"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Fechar
          </v-btn>
          <v-btn color="blue darken-1" text @click="dialogRejeitar = true">
            Invalidar Registro
          </v-btn>
          <v-btn
            color="blue darken-1"
            :disabled="!item.empresa_negativa"
            text
            @click="integrarDivida"
          >
            Integrar Dívida
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import helper from "../../mixins/helper";

//import pdf from "vue-pdf";

export default {
  components: {
    /*pdf*/
  },
  mixins: [helper],
  data: () => ({
    dialog: false,
    dialogRejeitar: false,

    datainclusao: null,
    datainclusaoFinal: null,
    srcConsumidor: null,
    srcCredor: null,
    srcOrigem: null,
    empresa_s_cadastro: {
      nome: null,
      telefone: null,
    },
    credorsemcadastro: {},
    list: [],
    item: {
      userDetails: {
        name: null,
        fantasia: null,

        telefone: null,
        email: null,
        bairro: null,
        numero: null,
        cep: null,
        logradouro: null,
        tipo: null,
        situacao: null,
        natureza_juridica: null,

        atividade_principal: [
          {
            text: null,
          },
        ],
      },
      endereco: {
        endereco: null,
        numero: null,
        bairro: null,
        cep: null,
        municipio: null,
        uf: null,
      },
    },
    search: "",
  }),
  mounted() {
    //  this.getEmpresas();
    this.getPreDividas();
  },

  methods: {
    notificacao(title, msg, icon) {
      this.$swal({
        toast: true,
        position: "top-center",
        showConfirmButton: false,
        timer: 3000,
        icon: icon,
        title: title,
        
        text: msg,
      });
    },

    integrarDivida() {
      this.$http
        .post("preDivida/gerarDivida", {
          predivida_id: this.item.id,
          empresa_id: this.item.credor.id,
        })
        .then(
          (response) => {
            console.log(response.data);
            this.notificacao("Sucesso", response.data.message, "sucess");
          },
          (response) => {
            console.log(response);
          }
        );
    },

    async dadosEmpresaNCadastrada(cnpj) {
      this.$http.get(`buscarEmpresaReceita/${cnpj}`).then(
        (response) => {
          this.empresa_s_cadastro = response.data;
          console.log(response.data);
        },
        (response) => {
          console.log(response.data);
        }
      );
    },

    getPreDividas() {
      this.$http
        .get(
          `getPreDividas?srcOrigem=${this.srcOrigem}&&datainclusao=${this.datainclusao}&datainclusaoFinal=${this.datainclusaoFinal}&srcConsumidor=${this.srcConsumidor}&srcCredor=${this.srcCredor}`
        )
        .then(
          (response) => {
            console.log(response.data);
            this.list = response.data;
          },
          (response) => {
            console.log(response);
          }
        );
    },
    async modalAnalizar(item) {
      this.item = item;
      await this.dadosEmpresaNCadastrada(item.cnpj_credor);
      this.dialog = true;
    },
    prenchido(item) {
      return item === null ? "Não preenchido" : item;
    },
  },
};
</script>

<style>
.degrade {
  background: #f12711;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f5af19, #f12711);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f5af19, #f12711);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

td {
  font-weight: bold !important;
  color: #000 !important;
}

.mx_h {
  height: 24%;
  margin-left: 0 !important;
}

.degrade-titulo-02 {
  background: #00b7ff;
  background: linear-gradient(
    90deg,
    #ac0042 0%,
    rgba(255, 255, 255, 0.3477766106442577) 43%,
    rgba(255, 255, 255, 0) 100%
  );
}

.table-secundary {
  border-bottom: solid #00000021;
  border-left: solid #00000021;
  border-right: solid #00000021;
  background-color: #eeeeee;
}

.text-res {
  max-width: 40ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
