<template>
  <v-container class="mx_h">
    <v-layout row wrap justify-space-around fill-height>
      <v-flex xs12 md12>
        <v-toolbar dense elevation="0">
          <v-spacer></v-spacer>
          <v-btn
            color="#FFD400"
            large
            elevation="0"
            style="color: #0052a0"
            @click="cadastrarEmpresa"
          >
            <v-icon small>mdi-plus-circle </v-icon>
            <span style="font-size: 12px; font-weight: 600; color: #0052a0"
              >CADASTRAR NOVO CREDOR</span
            >
          </v-btn>
        </v-toolbar>
      </v-flex>
      <v-flex xs12 md12>
        <v-dialog v-model="dialogDetalhes" scrollable max-width="1000">
          <v-card :loading="ldn_detalhes">
            <template slot="progress">
              <v-progress-linear
                color="orange"
                indeterminate
              ></v-progress-linear>
            </template>

            <v-dialog v-model="planoinfoShow" max-width="800">
              <v-card>
                <v-card-title> Detalhes do plano contratado </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <h2 style="color: orange">
                        <strong> {{ empresainfos.nome }}</strong>
                      </h2>
                    </v-col>

                    <v-col cols="12">
                      <v-simple-table dense>
                        <tr>
                          <th>Serviço</th>
                          <th>Limite transações</th>
                          <th>Valor unitário (Excedente)</th>
                        </tr>
                        <tbody>
                          <tr
                            v-for="item in empresainfos.plano.servicos"
                            :key="item.id"
                          >
                            <td class="text-center">{{ item.descricao }}</td>
                            <td class="text-center">{{ item.qt_max }}</td>
                            <td class="text-center">
                              {{ converterDinherio(item.preco_venda) }}
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                    <v-col cols="12"> </v-col>
                  </v-row>
                  <v-card-actions class="justify-end">
                    <h3 style="color: black">
                      Valor do Plano:
                      {{ converterDinherio(empresainfos.plano.total) }}
                    </h3>
                  </v-card-actions>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="planoinfoShow = false">Fechar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-card-title>
              <v-row>
                <v-col cols="12" style="margin-bottom: 0px">
                  Detalhes da empresa
                </v-col>
                <v-col
                  cols="12"
                  style="margin-top: -5px"
                  v-if="
                    empresainfos.status != 'Ativo' && empresainfos.etapa < 5
                  "
                >
                  <v-btn
                    elevation="0"
                    small
                    color="error"
                    @click="completarCadastro(empresainfos.id, empresainfos.user_juridica )"
                    >Completar Cadastro Agora!</v-btn
                  >
                </v-col>
             
              </v-row>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <h3>Dados Cadastrais</h3>
                </v-col>
                <v-col cols="6">
                  <p>
                    Razão Social: <br /><b> {{ empresainfos.name }}</b>
                  </p>
                </v-col>
                <v-col cols="6">
                  <p>
                    CNPJ/CPF: <br /><b>
                      {{ formatarCpfCnpj(empresainfos.cpf_cnpj) }}</b
                    >
                  </p>
                </v-col>
                <v-col cols="6">
                  <p>
                    Fantasia: <br /><b> {{ empresainfos.nameFantasy }}</b>
                  </p>
                </v-col>
                <v-col cols="6">
                  <p>
                    E-mail:<br /><b> {{ empresainfos.email2 }}</b>
                  </p>
                </v-col>
                <v-col cols="6">
                  <p>
                    Telefone:<br /><b> {{ empresainfos.telefone }}</b>
                  </p>
                </v-col>
                <v-col cols="6">
                  <p>
                    Telefone (WhatsApp):<br /><b> {{ empresainfos.whats }}</b>
                  </p>
                </v-col>
                <v-col cols="6">
                  <p>
                    Endereço:<br /><b> {{ empresainfos.endereco }}</b>
                  </p>
                </v-col>
                <v-col cols="2">
                  <p>
                    Número:<br /><b> {{ empresainfos.numero }}</b>
                  </p>
                </v-col>
                <v-col cols="2">
                  <p>
                    Complemento:<br /><b> {{ empresainfos.complemento }}</b>
                  </p>
                </v-col>
                <v-col cols="4">
                  <p>
                    Bairro:<br /><b> {{ empresainfos.bairro }}</b>
                  </p>
                </v-col>
                <v-col cols="3">
                  <p>
                    Município:<br /><b> {{ empresainfos.municipio }}</b>
                  </p>
                </v-col>
                <v-col cols="3">
                  <p>
                    UF:<br /><b> {{ empresainfos.uf }}</b>
                  </p>
                </v-col>
                <v-col cols="2">
                  <p>
                    CEP:<br /><b> {{ empresainfos.cep }}</b>
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <h3>Dados da contratação  <!--<v-btn fab text x-small><v-icon color="primary">mdi-information</v-icon></v-btn>--></h3> 
                </v-col>
                <v-col cols="3">
                  <p>
                    Data de criação do registro:<br /><b>
                      {{
                        moment(empresainfos.created_at).format("DD/MM/YYYY")
                      }}</b
                    >
                  </p>
                </v-col>
                <v-col cols="3">
                  <p>
                    Plano contratado:<br />
                    <v-btn
                      color="blue"
                      text
                      @click="detalhesPlano(empresainfos.plano_id)"
                    >
                      {{ empresainfos.nome }}
                    </v-btn>
                  </p>
                </v-col>
                <v-col cols="4">
                  <p>
                    Plano Contratado:<br /><b>
                      {{
                        empresainfos.status == "Em análise"
                          ? "Aguardando validação de cadastro"
                          : empresainfos.status
                      }}</b
                    >
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <h3>Dados Bancários</h3>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    filled
                    rounded
                    v-model="empresainfos.banco"
                    label="Banco"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    filled
                    rounded
                    v-model="empresainfos.agencia"
                    label="Agência"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    filled
                    rounded
                    v-model="empresainfos.conta"
                    label="Conta Corrente"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <h3>Canais de Comunicação</h3>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    filled
                    rounded
                    v-model="empresainfos.comunicacao_email"
                    label="E-mail"
                    placeholder="sac@stoneup.com.br"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    filled
                    rounded
                    v-model="empresainfos.comunicacao_site"
                    placeholder="http://stoneup.com.br"
                    label="Site Institucional"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    filled
                    rounded
                    v-model="empresainfos.comunicacao_telefone"
                    v-phone:model="empresainfos.comunicacao_telefone"
                    label="Telefone"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <h3>Usuários Vinculados</h3>
                </v-col>

                <v-col cols="12">
                  <v-simple-table dense>
                    <tr>
                      <th>Nome</th>
                      <th>E-mail</th>
                      <th>Status</th>
                    </tr>
                    <tbody>
                      <tr v-for="item in empresainfos.usuarios" :key="item.id">
                        <td class="text-center">{{ item.name }}</td>
                        <td class="text-center">{{ item.email }}</td>
                        <td class="text-center">{{ item.status }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                style="background-color: #ffd400; margin-right: 7.5px"
                @click="dialogDetalhes = false"
              >
                <v-icon small color="#0052A0">mdi-close-circle </v-icon>
                <span style="color: #0052a0">Fechar</span></v-btn
              >
              <v-btn @click="salvarModificacoes()"  style="background-color:#0052A0; margin-right: 7.5px"
                ><v-icon small color="#ffd400"> mdi-check-circle </v-icon> <span style="color:#ffd400">Salvar</span></v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>

      <v-flex xs12 md12 class="pa-1 mx_h">
        <v-card :loading="lgn_table" class="mx_h" elevation="0">
          <template slot="progress">
            <v-progress-linear color="orange" indeterminate></v-progress-linear>
          </template>
          <v-card-text class="card-stone-background">
            <v-data-table
              :items="list"
              :footer-props="{
                'items-per-page-text': 'Registros por página: ',
                'page-text': '{0}-{1} de {2}',
                'items-per-page-all-text': 'Listar todos',
              }"
              :headers="[
                { text: 'CNPJ / CPF' },
                { text: 'Nome/Razão social' },
                { text: 'Status', align: 'center' },
                { text: 'Ações ' },
              ]"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ formatarCpfCnpj(item.cpf_cnpj) }}
                  </td>
                  <td>{{ item.name }}</td>

                  <td class="text-center">
                    <v-btn
                      elevation="0"
                      v-if="item.status == 'Ativo'"
                      small
                      color="info"
                      >{{ item.status }}</v-btn
                    >
                    <v-btn
                      elevation="0"
                      v-else-if="item.status == 'Em análise'"
                      @click="aguardandoInfo"
                      small
                      color="warning"
                      >{{ item.status }}</v-btn
                    >
                    <v-btn
                      elevation="0"
                      v-else
                      @click="completarCadastro(item.empresa_id , item.user_juridica)"
                      small
                      color="gray"
                      >Cadastro Incompleto</v-btn
                    >
                  </td>
                  <td class="text-center">
                    <!-- <v-btn
                      elevation="2"
                      small
                      color="error"
                      v-if="item.status != 'Ativo' && item.etapa < 5"
                      @click="completarCadastro(item.empresa_id)"
                      >Completar Cadastro</v-btn
                    >-->
                    <v-btn
                      elevation="0"
                      small
                      style="background-color: #ffa900; color: white"
                      @click="informacoesEmpresa(item.empresa_id)"
                      ><v-icon dark> mdi-eye </v-icon>
                      VISUALIZAR
                    </v-btn>
                  </td>
                </tr>
              </template>
              <v-alert slot="no-data" :value="true">
                Sem dados para exibir
              </v-alert>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 md3 class="pa-1"> </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
//import paginationComponent from "../../components/paginationComponent";
import helper from "../../mixins/helper";

export default {
  mixins: [helper],
  components: {
    /* paginationComponent */
  },

  data: () => ({
    loading: false,
    lgn_table: false,
    pagination: [],

    empresainfos: {
      plano: {
        servicos: [],
      },
    },
    dialogDetalhes: false,
    ldn_detalhes: false,
    planoinfoShow: false,
    list: [],
  }),
  mounted() {
    this.getEmpresas();
  },
  methods: {
    getEmpresas() {
      this.lgn_table = true;
      this.$http.get("getEmpresa").then(
        (response) => {
          this.list = response.data;

          this.lgn_table = false;
        },
        (response) => {
          console.log(response);
          this.lgn_table = false;
          // error callback
        }
      );
    },
    detalhesPlano() {
      this.planoinfoShow = true;
    },
    mphone(v) {
      var r = v.replace(/\D/g, "");
      r = r.replace(/^0/, "");
      if (r.length > 10) {
        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
      } else if (r.length > 5) {
        r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
      } else if (r.length > 2) {
        r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
      } else {
        r = r.replace(/^(\d*)/, "($1");
      }
      return r;
    },
    aguardandoInfo() {
      this.$swal({
        toast: false,
        position: "top-center",
        showConfirmButton: true,
        icon: "info",
        confirmButtonColor: "#2778c4",
        title: "Aguardando validação equipe interna",
        html: "Prezado,<br>Sua empresa encontra-se em processo de validação dos dados informados por nossa equipe interna para que possamos oferecer um melhor atendimento. Não se preocupe, o processo não demora. Obrigado por escolher a StoneUp.",
      });
    },
    async salvarModificacoes() {
      this.ldn_detalhes = true;
      let empresa_id = this.empresainfos.empresa_id;
      let dataForm = this.empresainfos;
      this.$http.post("edicaoEmpresa/" + empresa_id, dataForm).then(
        () => {
          this.$swal({
            toast: false,
            position: "top-center",
            showConfirmButton: true,
            icon: "success",
            title: "Cadastro atualizado com sucesso",
          });
          this.dialogDetalhes = false;
          this.ldn_detalhes = false;
        },
        (error) => {
          console.log(error);
          this.ldn_detalhes = false;
        }
      );
    },
    async informacoesEmpresa(empresa_id) {
      this.lgn_table;
      this.ldn_detalhes = true;
      this.dialogDetalhes = true;
      this.empresainfos = {};

      await this.$http.get("empresaInformacoes/" + empresa_id).then(
        (response) => {
          console.log(response);
          this.empresainfos = response.data;
          this.empresainfos.id = empresa_id;
          this.ldn_detalhes = false;
          this.lgn_table;
        },
        (response) => {
          console.log(response);
          this.ldn_detalhes = false;
          this.lgn_table;
          // error callback
        }
      );

      this.empresainfos.empresa_id = empresa_id;
    },
    navigate(page) {
      this.$http.get("getEmpresa?page=" + page).then(
        (response) => {
          this.list = response.data.data;
          this.pagination = response.data;
        },
        (response) => {
          console.log(response);
          // error callback
        }
      );
    },

    cadastrarEmpresa() {
      this.$router.push({ name: "Cadastro de Credor" });
    },
    completarCadastro(empresa_id, is_juridica) {
      //alert(empresa_id);
    
      this.$router.push({
        name: "Cadastro de Credor",
        params: { empresa_id: empresa_id, tipo_fj: is_juridica },
      });
    },
  },
};
</script>
<style>
.degrade {
  background: #f12711; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f5af19,
    #f12711
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f5af19,
    #f12711
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

td {
  font-family: "Montserrat";

  color: #0052a0 !important;
}
.mx_h {
  height: 100%;
}
p {
  color: #4a4949;
}
</style>

