export default {
    data: () => ({}),

    methods: {
        formatarCpfCnpj(cpf_cnpj) {

            if (cpf_cnpj != null) {

                if (cpf_cnpj.length == 14) {

                    return cpf_cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
                } else {
                    return cpf_cnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
                }
            } else {
                return ''
            }
        },

        formatarTelefone(telefone) {
            return telefone.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4");
        },

        converterDataTimestamp(timestamp) {

            if (timestamp != null) {

                var data = timestamp.split('-')
                return data[2] + '/' + data[1] + '/' + data[0]
            } else {
                return ''
            }
        },

        converterDataTime(timestamp) {

            var meses = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
            var a = new Date(timestamp);
            var year = a.getFullYear();
            var month = meses[a.getMonth()];
            var date = a.getDate();
            var hora = a.getHours();
            var minuto = a.getMinutes();
            var segundo = a.getSeconds();
            return ("0" + date).slice(-2) + '/' + month + '/' + year + "  " + hora + ":" + minuto + ":" + segundo
        },

        converterDinherio(num) {
            var formatter = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            });
            if (num != null) {
                return formatter.format(parseFloat(num).toFixed(2))
            } else {
                num = 0.0
                return formatter.format(parseFloat(num).toFixed(2))
            }
        },

        converterTiposNotificacoes(tipo) {
            switch (tipo) {
                case '01':
                    return "E-mail"
                case '02':
                    return "SMS"
                case '03':
                    return "Voice"
                case '04':
                    return "Correios"
                case '05':
                    return "Manual"
                case '06':
                    return "Monitora"
                default:
                    break
            }
        },
        converterIconsNotificacoes(tipo) {
            switch (tipo) {
                case '01':
                    return "mdi-email "
                case '02':
                    return "mdi-message-processing"
                case '03':
                    return "mdi-microphone "
                case '04':
                    return "mdi-mailbox-open-up"
                case '05':
                    return "mdi-file-edit"
                case '06':
                    return "mdi-monitor-cellphone-star"
                default:
                    break
            }

        },
        converterColorTiposNotificacoes(tipo) {
            switch (tipo) {
                case '01':
                    return "#8E24AA"
                case '02':
                    return "#ff0000"
                case '03':
                    return "#311b92"
                case '04':
                    return "#827717"
                case '06':
                    return "#42A5F5"
                default:
                    break
            }
        },
        converterColorReturnTiposNotificacoes(tipo) {

            switch (tipo) {
                case '0':
                    return "grey lighten-1"
                case '1':
                    return "green"
                case '2':
                    return "red"


            }

        },

        converterColorIconReturnTiposNotificacoes(tipo) {

            if (tipo == 0) {
                return 'black'
            } else {
                return 'white'
            }

        },

        convertColorExpiration(tipo) {
            switch (tipo) {
                case 0:
                    return "#eeeeee"
                case 1:
                    return "#EF5350"
                case 2:
                    return "orange"


            }
        },

        data_hoje() {

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();
            today = yyyy + '-' + mm + '-' + dd

            return today

        },
        verifica_vazio(value) {
            if (value == '' || value == null) {
                return true
            } else {
                return false
            }
        },
        notificarPopup(vm, msg, icon = 'success') {

            vm.$swal({title: msg, icon: icon, buttons: true});
        },
        notificarPopupCallback(vm, msg, icon = 'success', callback) {

            vm.$swal({title: msg, icon: icon, buttons: true})
                .then((willDelete) => {
                    if (willDelete) {
                        return callback()

                    }
                });
        },
        coresStatus(tipo) {
            switch (tipo) {
                case '01':
                    return "#0052A0"
                case '02':
                    return "#F27C00"
                case '03':
                    return "#0052A0"
                case '04':
                    return "#0086D4"
                case '05':
                    return "#0086D4"
                case '06':
                    return "#0086D4"
                case '07':
                    return "#0052A0"
                case '08':
                    return "#0052A0"
                case '09':
                    return "#F27C00"
                case '10':
                    return "#0052A0"
                case '11':
                    return "#FFD400"
                case '12':
                    return "#FFD400"
                case '13':
                    return "#F27C00"
                case '14':
                    return "#F27C00"
                case '15':
                    return "#0086D4"

                default:
                    break
            }
        },
        notificar(vm, title, msg, icon = 'error', position = 'center-end') {
            vm.$swal({
                toast: true,
                position: position,
                showConfirmButton: false,
                timer: 3000,
                icon: icon,
                title: title,
                text: msg,
            });
        },
        baixarModeloSolicitacaoBaixa(vm = '', dados = null) {
            var data = []
            if (dados == null) {
                data[0] = {'cnpjcredor': vm, protocolo: "", lote: "", cpf_cnpj_devedor: ""}
            } else {
                data = dados
            }


            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += [
                Object.keys(data[0]).join(";"),
                ...data.map(item => Object.values(item).join(";"))
            ]
                .join("\n")
                .replace(/(^\[)|(\]$)/gm, "");
            const dataw = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", dataw);
            link.setAttribute("download", "Baixa Lote.csv");
            link.click();

        }


    },

}

