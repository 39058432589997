<template>

  <v-footer app flat style="
        bottom: -50px;
        position: fixed;
        height: 25px;
        width: 100%;
        right: 0px;
        bottom: 0px;
        background-color: #fee7b5;
      ">
    <v-row justify="space-between" style="font-size: 12px; font-family: Montserrat; letter-spacing: 0.19px">
      <div cols="4" style="color: #f27c00">
        <strong>
          <v-icon small color="#F27C00">mdi-clock-outline</v-icon></strong>

        <span> {{ second }}</span>
      </div>
      <div cols="4" style="color: #f27c00; margin-right: 10px">
        <span>Ambiente:</span>

        <strong>{{ ambiente }}</strong>
      </div>
    </v-row>
  </v-footer>
</template>
<script>
export default {
  data: () => ({

    empresa: "",
    ambiente: "Teste",

    time: 0,
    second: 0,
    nome: "",
    type: null,
    degrade: "degrade",
    items_menu: [],
  }),
  created() {
    this.items = this.$store.state.auth.user.menu;

    this.degrade = "degrade_stone";
    let timerId = setInterval(() => {
      this.time += 1000;


      var date = new Date(null);
      date.setSeconds(this.time / 1000); // specify value for SECONDS here
      var result = date.toISOString().substr(11, 8);
      this.second = result;

      if (this.$store.state.idleVue.isIdle) {
        clearInterval(timerId);

        //await
        this.sair();
        alert("Seção Expirada");
      }
    }, 1000);
  },
}
</script>
