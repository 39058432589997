<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="90%"
    fullscreen
    persistent
    v-model="dialog_baixa"
  >
    <template v-slot:default="">
      <v-card>
        <v-toolbar color="white">
          <v-btn icon @click="$emit('close')">
            <v-icon class="black--text">mdi-close</v-icon>
          </v-btn>
          <h2 class="font-weight-bold black--text">
            Solicitação de baixa do Porotocolo   
                
          </h2>

          <v-spacer></v-spacer>
          <v-text-field
            prepend-icon="mdi-lock"
            label="Digite sua Senha"
            dense
            type="password"
            class="pt-6"
            outlined
            v-model="senha"
            color="black"
          >
          </v-text-field>
           <v-btn
            class="mx-2 white--text"
            color="blue"
            :disabled="valid"
            elevation="0"
            @click="validarRemessa"
          >
            Validar
          </v-btn>
          <v-btn
            class="mx-2 white--text"
            color="green"
            elevation="0"
            :disabled="habilitarEnvio"
            @click="solicitarBaixa"
            >Enviar
          </v-btn>
         
        </v-toolbar>

        <v-card-text>
          <h5 v-show="progresso_visible" class="text-h5 mt-2 font-weight-bold">
            Progresso de validação dos protocolos
          </h5>
          <v-progress-linear
            class="mt-2"
            :value="progresso"
            striped
            v-show="progresso_visible"
            height="25"
          >
            <strong class="white--text">{{ progresso }} %</strong>
          </v-progress-linear>
        </v-card-text>
        <!--        STATUS LINHAS-->
        <v-card-text v-show="istable">
          <v-layout row>
            <v-flex class="px-1" md4>
              <h4 class="font-weight-bold black--text">
                Devedores: {{ credores }}
              </h4>
            </v-flex>
            <v-flex class="px-1" md4>
              <h4 class="font-weight-bold black--text">
                Valor original: {{ converterDinherio(valor_original) }}
              </h4>
            </v-flex>
            <v-flex class="px-1" md4>
              <h4 class="font-weight-bold black--text">
                Valor Vencido: {{ converterDinherio(valor_vencido) }}
              </h4>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex class="px-1" md4>
              <h4 class="font-weight-bold">Total linhas</h4>
              <v-progress-linear :value="100" height="25" color="blue">
                <strong>{{ linhas_retorno_total }}</strong>
              </v-progress-linear>
            </v-flex>
            <v-flex class="px-1" md4>
              <h4 class="font-weight-bold">Linhas corretas</h4>
              <v-progress-linear
                :value="(linhas_retorno_sucesso * 100) / linhas_retorno_total"
                height="25"
                color="green"
              >
                <strong>{{ linhas_retorno_sucesso }}</strong>
              </v-progress-linear>
            </v-flex>
            <v-flex class="px-1" md4>
              <h4 class="font-weight-bold">Linhas com erro</h4>
              <v-progress-linear
                :value="(linhas_retorno_erro * 100) / linhas_retorno_total"
                height="25"
                color="red"
              >
                <strong>{{ linhas_retorno_erro }}</strong>
              </v-progress-linear>
            </v-flex>
          </v-layout>
        </v-card-text>
        <!--        tabela retonro-->
        <v-card-text class="mt-3" v-show="istable">

          <v-data-table hide-default-footer class="elevation-1">
            <template v-slot:header>
              <tr bgcolor="orange">
                <th>Linha</th>
                <th>CPF/CNPJ Credor</th>
                <th>protocolo</th>
                <th>Lote</th>
                <th>CPF/CNPJ (Devedor)</th>
                <th>Erros</th>
              </tr>
            </template>

            <template v-slot:body dense>
              <tr
                v-for="item in linhas_retorno"
                v-bind:key="item.linha"
                :bgcolor="item.status !== '' ? '#fd4c4c' : ''"
              >
                <td class="text-center">{{ item.linha }}</td>
                <td class="text-center">{{ item.cnpjcredor }}</td>
                <td class="text-center">{{ item.protocolo }}</td>
                <td class="text-center">{{ item.lote }}</td>
                <td class="text-center">{{ item.cpf_cnpj_devedor }}</td>
                <td class="text-center">{{ item.status }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-text class="mt-3" v-show="!istable">
          <v-data-table hide-default-footer class="elevation-1">
            <template v-slot:header>
              <tr bgcolor="orange">
                <th>Linha</th>
                <th>CPF/CNPJ Credor</th>
                <th>protocolo</th>
                <th>Lote</th>
                <th>CPF/CNPJ (Devedor)</th>
                <th>Verificação</th>
              </tr>
            </template>
            <template v-slot:body dense>
              <tr v-for="item in linhas" v-bind:key="item.linha">
                <td class="text-center">{{ item.linha }}</td>
                <td class="text-center">{{ item.cnpjcredor }}</td>
                <td class="text-center">{{ item.protocolo }}</td>
                <td class="text-center">{{ item.lote }}</td>
                <td class="text-center">{{ item.cpf_cnpj_devedor }}</td>
                <td
                  class="text-center"
                  :class="
                    item.verificacao === 'Erro' ? 'red--text' : 'green--text'
                  "
                >
                  {{ item.verificacao }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import helper from "../mixins/helper";

export default {
  name: "BaixaLoteComponent",
  mixins: [helper],
  props: {
    anexo: {
      default: null,
    },
    dialog_baixa: {
      default: false,
    },
  },

  data: () => ({
    senha: "",
    save: true,
    habilitarEnvio : true,
    istable: false,
    progresso: 0,
    progresso_visible: false,
    valid: true,
    motivo_observacao: null,
    motivo: null,
    protocolos: [],
    linhas: [],
    credores: 0,
    valor_vencido: 0,
    valor_original: 0,
    linhas_retorno_total: 0,
    linhas_retorno_sucesso: 0,
    linhas_retorno_erro: 0,
    linhas_retorno: [],
    dadosEnvio: {
      negativacoes: {},
    },
  }),
  mounted() {
    this.verificarArquivo();

    this.senha = "";
    this.istable = false;
    this.linhas_retorno_total = 0;
    this.linhas_retorno_sucesso = 0;
    this.linhas_retorno_erro = 0;
   this.linhas_retorno = [];
    this.progresso_visible = false;
    this.progresso = 0;
  },
  updated() {
    this.verificarArquivo();
    this.senha = "";
    this.istable = false;
    this.linhas_retorno_total = 0;
    this.linhas_retorno_sucesso = 0;
    this.linhas_retorno_erro = 0;
    this.linhas_retorno = [];
    this.progresso_visible = false;
    this.progresso = 0;
  },
  methods: {
    fecharAtualizar() {
      this.senha = "";
      this.$emit("atualizar", { dados: this.protocolos });
      this.$emit("close");
    },
    verificarArquivo() {
      var reader = new FileReader();
      this.linhas_total = 0;
      this.linhas_com_erros = 0;
      this.linhas_com_sucesso = 0;
      var csv = null;
      var cont = 1;
      if (this.anexo != null) {
        reader.readAsText(this.anexo);
        var vm = this;
        reader.onload = function () {
          csv = reader.result;

          var lines = csv.split("\n");
          var result = [];
          var headers = lines[0].split(";");
          headers[3] = headers[3].trim();
          vm.linhas_total = lines.length - 2;

          for (var i = 1; i < lines.length; i++) {
            var obj = {};
            var objEnviar = {};
            var currentline = lines[i].split(";");
            cont = 1;
            let linha_em_branco = 0;
            for (var j = 0; j < headers.length; j++) {
              if (
                typeof currentline[j] == "undefined" ||
                currentline[j] === ""
              ) {
                if (headers[j] == "cnpjcredor") {
                  obj[headers[j]] = "CAMPO EM BRANCO";
                  linha_em_branco++;
                  cont++;
                }
                if (headers[j] == "protocolo") {
                  obj[headers[j]] = "CAMPO EM BRANCO";
                  linha_em_branco++;
                  cont++;
                }
                if (headers[j] == "lote") {
                  obj[headers[j]] = "CAMPO EM BRANCO";
                  linha_em_branco++;
                  cont++;
                }
                if (headers[j] == "cpf_cnpj_devedor") {
                  obj[headers[j]] = "CAMPO EM BRANCO";
                  linha_em_branco++;
                  // console.log(obj[headers[j]])
                  cont++;
                }
              } else {
                if (headers[j] == "cnpjcredor") {
                  if (isNaN(currentline[j])) {
                    cont++;
                    obj[headers[j]] = "PERMITIDO SÓ NUMEROS";
                  } else {
                    objEnviar[headers[j]] = currentline[j];
                    obj[headers[j]] = currentline[j];
                  }
                }
                if (headers[j] == "protocolo") {
                  objEnviar[headers[j]] = currentline[j];
                  obj[headers[j]] = currentline[j];
                }
                if (headers[j] == "lote") {
                  objEnviar[headers[j]] = currentline[j];
                  obj[headers[j]] = currentline[j];
                }
                if (headers[j] == "cpf_cnpj_devedor") {
                  if (currentline[j].trim() == "") {
                    obj[headers[j]] = "CAMPO EM BRANCO";
                    linha_em_branco++;
                    cont++;
                  } else {
                    if (isNaN(currentline[j])) {
                      cont++;
                      obj[headers[j]] = "PERMITIDO SÓ NUMEROS";
                    } else {
                      objEnviar[headers[j]] = currentline[j];
                      obj[headers[j]] = currentline[j];
                    }
                  }
                }
              }
            }

            if (cont > 1) {
              if (linha_em_branco < 4) {
                obj["linha"] = i + 1;
                vm.linhas_com_erros++;
                result.push(obj);
              }
            } else {
              objEnviar["linha"] = i + 1;
              objEnviar["verificacao"] = "Sucesso";
              vm.linhas_com_sucesso++;
              result.push(objEnviar);
            }
            linha_em_branco = 0;
            //vm.linhas = obj

            obj["verificacao"] = "Erro";
          }

          if (vm.linhas_com_erros == 0) {
            vm.valid = false;
          } else {
            vm.valid = true;
          }

          if (cont > 1) {
            // eslint-disable-next-line no-unused-vars
            vm.linhas = result;

            vm.isTable = true;
            vm.isSend = false;
            vm.save = true;
          } else {
            vm.linhas = result; //JSON
            vm.isTable = false;
            vm.isSend = true;
            vm.save = false;
            vm.isArquivo = true;
             this.validarRemessa()
            vm.notificar(vm, "Sucesso", "Arquivo válido", "success");
          } // eslint-disable-next-line no-unused-vars
        };
      }

      console.log(this.linhas)
    },
    validarRemessa() {
      this.progresso_visible = true;
      let vm = this;
      this.progresso = 0;
      this.$http
        .post(
          "protocolos/verificarLote",
          { dados: this.linhas },
          {
            progress(e) {
              if (e.lengthComputable) {
                vm.progresso = (e.loaded / e.total) * 100;
              }
            },
          }
        )
        .then(
          (response) => {
            console.log(response.body.data);
            this.linhas_retorno = response.body.data.dados;
            this.linhas_retorno_total = response.body.data.linhas.linhas;
            this.linhas_retorno_sucesso =
              response.body.data.linhas.linhas_sucesso;
            this.linhas_retorno_erro = response.body.data.linhas.linhas_erro;
            this.credores = response.body.data.linhas.credores;
            this.valor_vencido = response.body.data.linhas.valor_vencido;
            this.valor_original = response.body.data.linhas.valor_original;
            this.istable = true;
            this.habilitarEnvio = false
            this.notificar(this, "Sucesso", response.body.message, "success");
          },
          (response) => {
            this.notificar(this, "Desculpe", response.body.message);

            // error callback
          }
        );
    },
    solicitarBaixa() {
    
      this.$http
        .post("protocolos/baixaLote", {
          dados: this.linhas_retorno,
          senha: this.senha,
        })
        .then(
          (response) => {
            console.log(response);
            this.protocolos = response.body.data;
             this.linhas_retorno =[];
            this.notificarPopupCallback(
              this,
              response.body.message,
              "success",
              this.fecharAtualizar
            );

          },
          (response) => {
            console.log(response);
            this.notificar(this, "Desculpe", response.body.message);

            // error callback
          }
        );
    },
  },
  watch: {
    
  },
};
</script>

<style scoped>
</style>