<template>
  <v-container class="mx_h">
    <v-layout row wrap justify-space-around fill-height>
      <v-flex xs12 md12 >
      <v-row class="mt-6" >
        <v-flex xs12 md12 class="pa-1 px-11">
          <v-text-field
            :value="PerfilName"
            label="Perfil"
            readonly
            dense
            rounded
            filled
            
          />
        </v-flex>
        <v-flex xs12 md12 class="pa-1 pl-11">
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify "
            label="Permissão"
            dense
         
            color="blue"
            rounded
            filled
            background-color="white"
          ></v-text-field>
        </v-flex>
      
        <v-flex xs12 md12 class="pt-1 pl-5 ml-5" style="margin-bottom:30px">
          <v-row>
            <v-btn
            elevation="2"
            class="success"
            color="white--text "
            @click="ativartudo"
          >
            
            Ativar todas
          </v-btn>
            <v-btn
            elevation="2"
            class="red"
            color="white--text "
            @click="desativartudo"
          >
           
            Desativar todas
          </v-btn>

          </v-row>
         
        </v-flex>
      
      </v-row>
      </v-flex> 

      <!-- TABELA -->
      <v-flex xs12 md12 >
        <v-card class="mx_h" elevation="0">
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="list"
              item-key="lote"
              :single-expand="true"
              :search="search"
              show-expand
              class="elevation-0"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td></td>
                  <td>{{ item.id }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.label }}</td>

                  <td>
                    <v-switch
                      v-model="item.status"
                      @click="changeStatus(item.id)"
                      :loading="carregando"
                      :value="item.status"
                      :disabled="carregando"
                    />
                  </td>
                </tr>
              </template>
              <v-alert slot="no-results" :value="true">
                Sua busca por "{{ search }}" não encontrou resultados.
              </v-alert>
              <v-alert slot="no-data" :value="true"> Sem dados </v-alert>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import helper from "../../../mixins/helper";

export default {
  components: {},
  mixins: [helper],
  data: () => ({
    roleId: "",
    PerfilName: '',
    dialog: false,
    carregando: false,
    modal: {
      title: null,
      type: null,
    },
    hand: {
      id: null,
      name: null,
      label: null,
    },
    list: [],
    search: "",
    headers: [
      {
        text: "id",
        value: "id",
        width: "12%",
        align: "start",
      },
      {
        text: "Nome",
        width: "25%",
        value: "name",
        align: "start",
      },
      {
        text: "Descrição",
        value: "label",
        align: "start",
      },
      {
        text: "Ações",
        width: "9%",
        align: "center",
        value: "",
      },
    ],
  }),
  mounted() {
    this.roleId = this.$route.params.id;
    this.PerfilName = this.$route.params.name;
    this.getPerfilPermission();
  },

  methods: {
    notificacao(title, msg, icon) {
      this.$swal({
        toast: true,
        position: "top-center",
        showConfirmButton: false,
        timer: 3000,
        icon: icon,
        title: title,
        text: msg,
      });
    },
    getPerfilPermission() {
      console.log('getPerfilPermission()')
      this.$http.get("perfilPermission/" + this.$route.params.id).then(
        (response) => {
          console.log(response)
          var i_list = [];
          //this.list = response.data;
          response.data.map((item)=>{
            if(item.status == "1"){
              item.status = true;
            }else{
              item.status = false
            }
            i_list.push(item)
          })
          this.list = i_list
        },
        (response) => {
          console.log(response);
        }
      );
    },
    changeStatus(permissionId) {
      this.carregando = true;
      this.$http
        .get("changePermission/" + this.$route.params.id + "/" + permissionId)
        .then(
          (response) => {
            this.carregando = false;
            this.notificacao("", response.body.message, "success");
          },
          (response) => {
            this.carregando = false;
            this.getPerfilPermission();
            this.notificacao("", response.body.message, "success");
          }
        );
    },
    async ativartudo() {

      //return alert(this.$route.params.id);
      await this.$http.get("activeAllPermissions/" + this.$route.params.id).then(
        (response) => {
          this.carregando = false;

          this.notificacao("", response.body.message, "success");
          this.getPerfilPermission();
        },
        (response) => {
          this.carregando = false;
          this.getPerfilPermission();
          this.notificacao("", response.body.message, "success");
        }
      );

    },
    desativartudo() {
      this.$http.get("desactiveAllPermissions/" + this.$route.params.id).then(
        (response) => {
          this.carregando = false;
          this.getPerfilPermission();
          this.notificacao("", response.body.message, "success");
        },
        (response) => {
          this.carregando = false;
          this.getPerfilPermission();
          this.notificacao("", response.body.message, "success");
        }
      );
    },
  },
};
</script>

<style>
.degrade {
  background: #f12711;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f5af19, #f12711);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f5af19, #f12711);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

td {
  font-weight: bold !important;
  color: #000 !important;
}

.mx_h {
  height: 24%;
  margin-left: 0 !important;
}

.degrade-titulo-02 {
  background: #00b7ff;
  background: linear-gradient(
    90deg,
    #ac0042 0%,
    rgba(255, 255, 255, 0.3477766106442577) 43%,
    rgba(255, 255, 255, 0) 100%
  );
}

.table-secundary {
  border-bottom: solid #00000021;
  border-left: solid #00000021;
  border-right: solid #00000021;
  background-color: #eeeeee;
}

.text-res {
  max-width: 40ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
