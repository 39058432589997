<template>
  <v-form>
    <v-row fluid>
      <v-col cols="12">
        <h5 v-if="id_empresa">Coninuar cadastro</h5>
        <v-stepper v-model="e1">
          <v-stepper-header class="card-stone-background">
            <v-stepper-step
              :complete="stepComplete(1)"
              :rules="[() => !!validPessoais]"
              :color="stepStatus(1)"
              step="1"
              >Dados cadastrais</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step
              step="2"
              :complete="stepComplete(2)"
              :rules="[() => !!validEndereco]"
              :color="stepStatus(2)"
              class="card-stone-background"
              >Dados do endereço</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step
              :complete="stepComplete(3)"
              :rules="[() => !!validDocumento]"
              :color="stepStatus(3)"
              step="3"
              >Documentos
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :complete="stepComplete(4)"
              :rules="[() => !!validPlanos]"
              :color="stepStatus(4)"
              step="4"
              >Planos
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :complete="stepComplete(5)"
              :rules="[() => !!validTermos]"
              :color="stepStatus(5)"
              step="5"
              >Finalização
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items class="card-stone-background">
            <v-stepper-content step="1">
              <v-card
                class="mb-12"
                color="white lighten-1"
                height="auto"
                elevation="0"
              >
                <v-row class="card-stone-background">
                  <v-col cols="4" md="6" lg="4" sm="12">
                    <v-text-field
                      class="bold"
                      background-color="white"
                      filled
                      rounded
                      dense
                      maxlength="18"
                      v-mask="'##.###.###/####-##'"
                      color="blue darken-4"
                      v-model="etapa_um.cpf_cnpj_input"
                      label="CNPJ"
                      :loading="buscarDadosReceitaLoading"
                      required
                      :rules="cpfRule"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4" md="6" lg="4" sm="12">
                    <v-text-field
                      class="bold"
                      background-color="white"
                      filled
                      rounded
                      dense
                      color="blue darken-4"
                      v-model="etapa_um.name"
                      label="Razão Social"
                      required
                      :rules="nameRule"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" md="6" lg="4" sm="12">
                    <v-text-field
                      class="bold"
                      background-color="white"
                      filled
                      rounded
                      dense
                      color="blue darken-4"
                      v-model="etapa_um.nameFantasy"
                      label="Nome Fantasia"
                      required
                      :rules="nameFantasyRule"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" md="6" lg="4" sm="12">
                    <v-text-field
                      class="bold"
                      background-color="white"
                      filled
                      rounded
                      dense
                      color="blue darken-4"
                      v-model="etapa_um.inscricao_estadual"
                      label="Inscrição Estadual"
                      placeholder="Caso não possua, informar 'ISENTO' "
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" md="6" lg="4" sm="12">
                    <v-text-field
                      class="bold"
                      background-color="white"
                      filled
                      rounded
                      dense
                      color="blue darken-4"
                      v-model="etapa_um.inscricao_municipal"
                      label="Inscrição Municipal"
                      placeholder="Caso não possua, informar 'ISENTO' "
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4" md="6" lg="4" sm="12">
                    <v-text-field
                      class="bold"
                      background-color="white"
                      filled
                      rounded
                      dense
                      color="blue darken-4"
                      v-model="etapa_um.email2"
                      label="E-mail "
                      required
                      :rules="email2Rule"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" md="6" lg="4" sm="12">
                    <v-text-field
                      class="bold"
                      background-color="white"
                      filled
                      rounded
                      dense
                      color="blue darken-4"
                      v-model="etapa_um.telefone"
                      label="Telefone"
                      v-phone:model="etapa_um.telefone"
                      required
                      :rules="telefoneRule"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" md="6" lg="4" sm="12">
                    <v-text-field
                      class="bold"
                      background-color="white"
                      filled
                      rounded
                      dense
                      color="blue darken-4"
                      v-model="etapa_um.whats"
                      label="WhatsApp"
                      required
                      :rules="whatsRule"
                      v-phone:model="etapa_um.whats"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>
              <v-row justify="center" style="margin-bottom: 15px">
                <v-btn
                  color="#0052a0 "
                  width="300px"
                  right
                  x-large
                  :loading="loading"
                  @click="validate(1)"
                >
                  <v-icon color="#FFD400">mdi-arrow-right-thick</v-icon>
                  <span style="color: #ffd400; font-weight: 600"> SEGUIR</span>
                </v-btn>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card
                class="mb-12 card-stone-background"
                color="white lighten-1"
                height="auto"
                elevation="0"
              >
                <v-row class="mt-1 card-stone-background">
                  <v-col lg="2" md="6" sm="6" xs="12">
                    <v-text-field
                      v-model="etapa_dois.cep"
                      label="CEP"
                      required
                      :rules="cepRule"
                      maxsength="9"
                      v-mask="'#####-###'"
                      class="bold"
                      filled
                      rounded
                      dense
                      color="blue darken-4"
                      background-color="white"
                    ></v-text-field>
                  </v-col>

                  <v-col lg="2" md="3" sm="4" xs="12">
                    <!--<v-text-field
                      v-model="etapa_dois.uf"
                      label="UF"
                      required
                      :rules="ufRule"
                      class="bold"
                      outlined
                      color="orange"
                      dense
                    ></v-text-field>-->

                    <v-select
                      v-bind:items="ufs"
                      v-model="etapa_dois.uf"
                      class="bold"
                      filled
                      rounded
                      dense
                      color="blue darken-4"
                      background-color="white"
                      label="UF"
                    >
                      <template slot="item" scope="data" class="bold">
                        {{ data.item }}
                      </template>
                      <template slot="selection" scope="data" class="bold">
                        {{ data.item }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col lg="3" md="6" sm="12" xs="12">
                    <!--<v-text-field
                      v-model="etapa_dois.municipio"
                      label="Município"
                      required
                      :rules="municipioRule"
                      class="bold"
                      outlined
                      color="orange"
                      dense
                    ></v-text-field>-->

                    <v-select
                      v-bind:items="municipios"
                      v-model="etapa_dois.municipio"
                      class="bold"
                      filled
                      rounded
                      dense
                      color="blue darken-4"
                      background-color="white"
                      item-value="municipio"
                      item-text="municipio"
                      label="Município"
                    >
                      <template slot="item" scope="data" class="bold">
                        {{ data.item.municipio }}
                      </template>
                      <template slot="selection" scope="data" class="bold">
                        {{ data.item.municipio }}
                      </template>
                    </v-select>
                  </v-col>

                  <v-col lg="5" md="8" sm="12" xs="12">
                    <v-text-field
                      v-model="etapa_dois.endereco"
                      label="Endereço "
                      required
                      :rules="enderecoRule"
                      class="bold"
                      filled
                      rounded
                      dense
                      color="blue darken-4"
                      background-color="white"
                    ></v-text-field>
                  </v-col>

                  <v-col lg="2" md="3" sm="5" xs="12">
                    <v-text-field
                      v-model="etapa_dois.numero"
                      label="Nº"
                      required
                      :rules="numeroRule"
                      class="bold"
                      filled
                      rounded
                      dense
                      color="blue darken-4"
                      background-color="white"
                    ></v-text-field>
                  </v-col>
                  <v-col lg="4" md="6" sm="7" xs="12">
                    <v-text-field
                      v-model="etapa_dois.complemento"
                      label="Complemento"
                      required
                      class="bold"
                      filled
                      rounded
                      dense
                      color="blue darken-4"
                      background-color="white"
                    ></v-text-field>
                  </v-col>
                  <v-col lg="6" md="6" sm="12" xs="12">
                    <v-text-field
                      v-model="etapa_dois.bairro"
                      label="Bairro"
                      required
                      :rules="bairroRule"
                      class="bold"
                      filled
                      rounded
                      dense
                      color="blue darken-4"
                      background-color="white"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>
              <v-row justify="center" style="margin-bottom: 15px">
                <!-- 

                      <v-btn
                    color="#0052a0 "
                    width="300px"
                    right
                    
                    x-large
                    :loading="loading"
                    @click="validate(1)"
                  >
                    <v-icon color="#FFD400">mdi-arrow-right-thick</v-icon>
                    <span style="color: #FFD400;font-weight: 600; "> SEGUIR</span>
                  </v-btn>
                -->

                <v-btn
                  elevation="2"
                  color="#FFDD00"
                  width="150px"
                  right
                  style="margin-right: 15px"
                  x-large
                  @click="e1 = 1"
                >
                  <v-icon color="#0052a0">mdi-undo</v-icon>
                  <span
                    style="color: #0052a0; font-weight: 600; margin-right: 15px"
                    >VOLTAR</span
                  >
                </v-btn>

                <v-btn
                  color="#0052a0 "
                  width="150px"
                  right
                  x-large
                  :loading="loading"
                  @click="validate(2)"
                >
                  <v-icon color="#FFD400">mdi-arrow-right-thick</v-icon>
                  <span style="color: #ffd400; font-weight: 600"> SEGUIR</span>
                </v-btn>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card
                class="mb-12 card-stone-background"
                color="white lighten-1"
                height="auto"
                elevation="0"
              >
                <v-row class="pa-3 card-stone-background">
                  <v-col
                    cols="1"
                    class="align-self-center doc-cnh"
                    style="margin-top: 15px"
                  >
                    <v-checkbox
                      v-model="rg"
                      label="RG"
                      required
                      filled
                      rounded
                      dense
                      color="blue darken-4"
                      @change="rgAction"
                      class="shrink mr-2 mt-0"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="cnh"
                      label="CNH"
                      required
                      filled
                      rounded
                      dense
                      color="blue darken-4"
                      @change="cnhAction"
                      class="shrink mr-2 mt-0"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="5">
                    <v-file-input
                      v-if="rg"
                      v-model="etapa_tres.rg_frente"
                      label="RG Frente"
                      class="bold"
                      required
                      filled
                      rounded
                      background-color="white"
                      dense
                      color="blue darken-4"
                      :disabled="!rg"
                      accept="image/*, application/pdf"
                      :rules="rgFrenteRule"
                    ></v-file-input>
                    <v-file-input
                      v-if="rg"
                      v-model="etapa_tres.rg_verso"
                      label="RG Verso"
                      required
                      filled
                      rounded
                      class="bold"
                      dense
                      color="blue darken-4"
                      background-color="white"
                      :disabled="!rg"
                      accept="image/*, application/pdf"
                      :rules="rgVersoRule"
                    ></v-file-input>
                    <v-file-input
                      v-if="cnh"
                      v-model="etapa_tres.cnh_documento"
                      label="CNH"
                      required
                      filled
                      rounded
                      dense
                      background-color="white"
                      color="blue darken-4"
                      :disabled="!cnh"
                      accept="image/*, application/pdf"
                      :rules="cnhRule"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-divider></v-divider>

                <v-row class="pt-5 card-stone-background">
                  <v-col cols="5" class="align-self-center">
                    <v-file-input
                      v-model="etapa_tres.comprovante_endereco"
                      label="Comprovante de endereço atualizado"
                      class="bold"
                      required
                      filled
                      rounded
                      dense
                      background-color="white"
                      color="blue darken-4"
                      accept="image/*, application/pdf"
                      :rules="comprovanteRule"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="5" class="align-self-center">
                    <v-file-input
                      v-model="etapa_tres.cartao_cnpj"
                      label="Cartão CNPJ"
                      required
                      filled
                      rounded
                      class="bold"
                      dense
                      background-color="white"
                      color="blue darken-4"
                      accept="image/*, application/pdf"
                      :rules="comprovanteRule"
                    ></v-file-input>
                  </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-row class="pt-5 card-stone-background">
                  <v-col cols="2" class="align-self-center">
                    <v-checkbox
                      v-model="contrato_s"
                      label="Contrato Social"
                      @change="contratoAction"
                      required
                      filled
                      rounded
                      dense
                      color="blue darken-4"
                      class="shrink mr-2 mt-0"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="ato_cons"
                      label="Ato Constitutivo"
                      @change="atoConsAction"
                      required
                      filled
                      rounded
                      dense
                      color="blue darken-4"
                      class="shrink mr-2 mt-0"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="4" class="align-self-center">
                    <v-file-input
                      v-if="contrato_s"
                      v-model="etapa_tres.doc_contrato_social"
                      label="Contrato Social"
                      :disabled="!contrato_s"
                      accept="image/*, application/pdf"
                      required
                      filled
                      rounded
                      class="bold"
                      dense
                      background-color="white"
                      color="blue darken-4"
                      :rules="rgFrenteRule"
                    ></v-file-input>

                    <v-file-input
                      v-if="ato_cons"
                      v-model="etapa_tres.doc_ato_constitutivo"
                      label="Ato Constitutivo"
                      rounded
                      class="bold"
                      dense
                      background-color="white"
                      color="blue darken-4"
                      :disabled="!ato_cons"
                      accept="image/*, application/pdf"
                      :rules="cnhRule"
                    ></v-file-input>
                  </v-col>

                  <v-col cols="4" class="align-self-center">
                    <v-file-input
                      v-model="etapa_tres.doc_inscricao_estadual"
                      label="Inscrição Estadual"
                      required
                      filled
                      rounded
                      class="bold"
                      dense
                      background-color="white"
                      color="blue darken-4"
                      :disabled="etapa_um.inscricao_estadual == 'ISENTO'"
                      accept="image/*, application/pdf"
                      :rules="rgFrenteRule"
                    ></v-file-input>

                    <v-file-input
                      v-model="etapa_tres.doc_inscricao_municipal"
                      label="Inscrição Municipal"
                      required
                      filled
                      rounded
                      class="bold"
                      dense
                      background-color="white"
                      color="blue darken-4"
                      :disabled="etapa_um.inscricao_municipal == 'ISENTO'"
                      accept="image/*, application/pdf"
                      :rules="cnhRule"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row v-if="e1 > 3" class="pt-5 card-stone-background">
                  <v-col lg="12" md="12" sm="12" class="align-self-center">
                    <p style="color: red; text-align: center">
                      Anexos de documentos já enviados anteriormente.
                    </p>
                  </v-col>
                </v-row>
              </v-card>
              <v-row class="pa-3" style="margin-bottom: 15px" justify="center">
                <v-btn
                  color="#FFDD00"
                  elevation="2"
                  right
                  width="150px"
                  x-large
                  style="margin-right: 15px"
                  @click="e1 = 2"
                >
                  <v-icon color="#0052a0">mdi-undo</v-icon>
                  <span style="color: #0052a0; font-weight: 600">VOLTAR</span>
                </v-btn>

                <v-btn
                  color="#0052a0 "
                  elevation="2"
                  right
                  width="150px"
                  x-large
                  :loading="loading"
                  @click="validate(3)"
                >
                  <v-icon color="#FFD400">mdi-arrow-right-thick</v-icon>
                  <span style="color: #ffd400; font-weight: 600"> SEGUIR</span>
                </v-btn>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-card
                class="mb-12 card-stone-background"
                height="auto"
                elevation="0"
              >
                <v-row class="card-stone-background">
                  <v-col
                    cols="4"
                    lg="4"
                    md="6"
                    sm="12"
                    v-for="item in listPlanos"
                    :key="item.id"
                  >
                    <v-card
                      max-height="500"
                      elevation="3"
                      style="border-radius: 25px"
                    >
                      <v-card-title
                        class="white--text font-weight-bold text-center"
                        style="
                          background-color: #ffa900;
                          font-family: Montserrat;
                        "
                        >{{ item.nome }}</v-card-title
                      >
                      <v-card-subtitle
                        class="white--text"
                        style="
                          background-color: #ffa900;
                          font-family: Montserrat;
                        "
                        >{{ item.descricao }}</v-card-subtitle
                      >

                      <v-card-text class="mt-3">
                        <v-chip
                          label
                          v-for="(serv, i) in item.services"
                          v-bind:key="serv.id"
                          v-show="i <= 10"
                          class="mr-1"
                          style="width: 125%; color: #0052a0"
                          color="transparent"
                          ><strong style=" color: #0052a0; margin-left: -12px;; "> {{ serv.descricao }}</strong>
                          <small style="color:#0052a0" v-if="serv.qt_max > 0"
                            >({{ serv.qt_max }} ao mês.)</small
                          >
                          <small v-else style="color:#0052a0"
                            >({{ converterDinherio(serv.valor_service) }} por
                            utilização)</small
                          >
                        </v-chip>

                        <v-chip
                          @click="servicesModal(item)"
                          label
                          class="
                            mr-1
                            font-weight-bold
                            text-decoration-underline
                          "
                          link
                          style="width: 100%;
                          margin-left: -12px ; color: #0052a0"
                          color="transparent"
                        >
                          Ver Detalhes</v-chip
                        >
                      </v-card-text>

                      <v-card-title
                        class="text-center"
                        style="background-color: white"
                        ><h4 class="text-center" style="color: #0052a0">
                          {{ converterDinherio(item.total) }} Mensal
                        </h4></v-card-title
                      >
                      <v-card-subtitle class="text--orange">
                        <h5
                          v-for="periodos in item.periodo"
                          :key="periodos.id"
                          class="orange--text"
                        >
                          {{ periodos.periodo }} {{ periodos.desconto }}%
                          Desconto
                        </h5>
                      </v-card-subtitle>
                      <v-card-actions>
                        <v-btn
                          block
                          color="#0052a0 "
                          @click="escolher(item)"
                          rounded
                          elevation="0"
                          class="text--white"
                        >
                          <span style="color: #ffd400; font-weight: 600">
                            ESCOLHER</span
                          ></v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>

              <v-row class="pa-3" justify="center" style="margin-bottom: 15px">
                <v-btn
                  elevation="2"
                  color="#FFDD00"
                  width="150px"
                  x-large
                  @click="e1 = 3"
                >
                  <v-icon color="#0052a0">mdi-undo</v-icon>
                  <span style="color: #0052a0; font-weight: 600">VOLTAR</span>
                </v-btn>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="5">
              <v-row>
                <v-col md="12" lg="12" sm="12">
                  <v-card
                    class="mb-12"
                    color="white lighten-1"
                    height="auto"
                    elevation="0"
                  >
                    <v-row>
                      <v-col cols="12">
                        <v-card
                          max-height="500"
                          v-if="etapa_cinco.plano"
                          elevation="3"
                        >
                          <v-row>
                            <v-col md="12" lg="12" sm="12">
                              <v-card-title
                                class="white--text font-weight-bold text-center"
                                style="background-color: #ffa900"
                                >{{ etapa_cinco.plano.nome }}</v-card-title
                              >
                              <v-card-subtitle
                                class="white--text"
                                style="background-color: #ffa900"
                                >{{
                                  etapa_cinco.plano.descricao
                                }}</v-card-subtitle
                              >
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="pa-0" md="7" lg="7" sm="7">
                              <v-card-text class="mt-3">
                                <v-chip
                                  label
                                  v-for="(serv, i) in etapa_cinco.plano
                                    .services"
                                  v-bind:key="serv.id"
                                  v-show="i <= 10"
                                  class="mr-1 black--text"
                                  style="width: 100%"
                                  color="transparent"
                                  ><strong style=" color: #0052a0">{{ serv.descricao }}</strong>
                                  <small style="color:#0052a0" v-if="serv.qt_max > 0"
                                    >({{ serv.qt_max }} ao mês)</small
                                  ><small v-else style="color:#0052a0"
                                    >({{
                                      converterDinherio(serv.valor_service)
                                    }}
                                    por utilização)</small
                                  ></v-chip
                                >

                                <v-chip
                                  @click="servicesModal(etapa_cinco.plano)"
                                  label
                                  class="mr-1 text-decoration-underline"
                                  link
                                  
                                  style="width: 100%; background-color: #ffa900"
                                  color="transparent"
                                >
                                  Ver Detalhes</v-chip
                                >
                              </v-card-text>
                            </v-col>
                            <v-col class="pa-0" md="6" lg="5" sm="5">
                              <v-card-title class="text-center" style=" background-color:white; color:blue">
                                <!--  <v-text-field       prefix="Código do Cumpom:"></v-text-field>-->
                                <!--  <v-text-field   color="orange"     label="Código do Cumpom:" v-model="etapa_cinco.cupomDesconto "></v-text-field> -->

                                <v-btn
                                  style="height: 55px"
                                  class="white--text"
                                  color="#008B8B"
                                  @click="dialogInformarCupom = true"
                                  ><v-icon small color="white">mdi-sale</v-icon
                                  >Inserir cupom de desconto</v-btn
                                >

                                <div v-if="cupom_desconto.id != null">
                                  <p>
                                    <small style="text-decoration: line-through"
                                      >de:
                                      {{
                                        converterDinherio(
                                          cupom_desconto.valor_original
                                        )
                                      }}
                                    </small>
                                    <v-chip
                                      class="ma-2"
                                      color="success"
                                      text-color="white"
                                    >
                                      -
                                      {{
                                        cupom_desconto.type == 1
                                          ? converterDinherio(
                                              cupom_desconto.valor
                                            )
                                          : cupom_desconto.valor + "%"
                                      }}
                                    </v-chip>
                                  </p>

                                  <p style="text-align: initial">
                                    <small>por: </small
                                    ><b class="blue--text darken-4">{{
                                      converterDinherio(
                                        cupom_desconto.valor_final
                                      )
                                    }}</b>
                                  </p>
                                </div>

                                <div v-else>
                                  <h4
                                    class="text-center"
                                    style="margin-top: 45px"
                                  >
                                    {{
                                      converterDinherio(etapa_cinco.plano.total)
                                    }}
                                    Mensal
                                  </h4>
                                </div>
                              </v-card-title>
                              <v-card-subtitle class="text--orange">
                                <h5
                                  v-for="periodos in etapa_cinco.plano.periodo"
                                  :key="periodos.id"
                                  class="orange--text"
                                >
                                  {{ periodos.periodo }}
                                  {{ periodos.desconto }}% Desconto
                                </h5>
                              </v-card-subtitle>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12" lg="12" sm="12">
                  <v-card
                    class="mb-12"
                    color="white lighten-1"
                    height="auto"
                    elevation="0"
                  >
                    <v-row>
                      <v-col cols="12">
                        <v-responsive
                          class="overflow-y-auto"
                          style="margin-left: 20px; margin-right: 20px"
                          max-height="500"
                        >
                          <div v-html="text_termo" />

                          <v-responsive height="auto" class="text-center pa-2">
                            <v-lazy
                              v-model="isActive"
                              :options="{
                                threshold: 1,
                              }"
                              min-height="200"
                              transition="fade-transition"
                            >
                              <v-checkbox
                                v-model="etapa_cinco.termos"
                                label="ACEITO OS TERMOS DE USO E CONTRATO"
                              ></v-checkbox>
                            </v-lazy>
                          </v-responsive>
                        </v-responsive>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>

              <v-row class="pa-3" justify="center" style="margin-bottom: 10px;" >
                <v-btn color="#FFDD00" width="150px" x-large @click="e1 = 4">
                  <v-icon color="#0052a0">mdi-undo</v-icon>
                  <span style="color: #0052a0;  font-weight: 600">VOLTAR</span>
                </v-btn>

                <v-btn
                  color="#0052a0 "
                  width="150px"
                  right
                  x-large
                  :disabled="!etapa_cinco.termos"
                  @click="validate(5)"
                >
                  <v-icon color="#FFD400">mdi-check-circle</v-icon>
                  <span style="color: #ffd400; font-weight: 600">
                    FINALIZAR</span
                  >
                </v-btn>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
    <v-row>
      <v-dialog v-model="dialogInformarCupom" width="700">
        <v-toolbar color="orange dense">
          <h3 class="white--text">Informar cupom de desconto</h3>
        </v-toolbar>
        <v-card-text class="white">
          <v-row>
            <v-col cols="8">
              <v-text-field
                color="orange"
                dense
                filled
                rounded
                label="Código do Cupom:"
                v-model="srcCupom"
                @keypress.enter="aplicarCupom"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-btn
                x-large
                text
                class="orange--text"
                :loading="ldnValidar"
                @click="validarCupom"
              >
                <v-icon>mdi-check</v-icon> Validar Cupom</v-btn
              >
            </v-col>

            <v-col v-if="cupom_desconto.id != null" cols="12">
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <h3>
                  <p class="blue--text dark">
                    Valor Original :<b>
                      {{ converterDinherio(cupom_desconto.valor_original) }}</b
                    >
                  </p>
                  <p class="blue--text dark">
                    Desconto :<b>
                      {{
                        cupom_desconto.type == 1
                          ? converterDinherio(cupom_desconto.valor)
                          : cupom_desconto.valor + "%"
                      }}</b
                    >
                  </p>
                  <p class="blue--text dark">
                    Valor final :<b>
                      {{ converterDinherio(cupom_desconto.valor_final) }}</b
                    >
                  </p>
                </h3>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="white justify-center">
          <v-btn
            class="blue darken-4 amber--text darken-4--text"
            large
            :disabled="cupom_desconto.id == null"
            @click="aplicarCupom"
          >
            <v-icon>mdi-check-circle-outline</v-icon> Aplicar</v-btn
          >
          <v-btn
            class="amber blue--text darken-4--text"
            large
            @click="dialogInformarCupom = false"
            ><v-icon>mdi-close-circle</v-icon> Cancelar</v-btn
          >
        </v-card-actions>
      </v-dialog>
      <modal-services-component
        :dialog_log="modal"
        :registros="listServices"
        @close="modal = false"
      ></modal-services-component>
    </v-row>
  </v-form>
</template>

<script>
import { isCNPJ } from "vuetify-masks-br";
import ModalServicesComponent from "../../components/ModalServicesComponent";

import axios from "axios";
import { mapActions } from "vuex";
import helper from "../../mixins/helper";
export default {
  mounted() {
    this.listaMunicipios();
    this.planos();

    this.id_empresa = this.$route.params.empresa_id ?? null;

    if (this.id_empresa && this.$route.params.tipo_fj == "1") {
      this.continuarCadastro();
    }
  },
  mixins: [helper],
  components: { ModalServicesComponent },
  data: () => ({
    e1: 1,
    id_empresa: null,
    dialogInformarCupom: false,
    ldnValidar: false,
    rg: true,
    municipios: [],
    ufs: [
      "RO",
      "AC",
      "AM",
      "RR",
      "PA",
      "AP",
      "TO",
      "MA",
      "PI",
      "CE",
      "RN",
      "PB",
      "PE",
      "AL",
      "SE",
      "BA",
      "MG",
      "ES",
      "RJ",
      "SP",
      "PR",
      "SC",
      "RS",
      "MS",
      "MT",
      "GO",
      "DF",
    ].sort(),
    loading: false,

    contErro: false,
    srcCupom: null,

    contrato_s: true,
    ato_cons: false,
    insc_estadual: true,
    insc_municipal: false,
    buscarDadosReceitaLoading: false,
    cnh: false,
    isActive: false,
    modal: false,
    listPlanos: [],

    cupom_desconto: {
      id: null,
      cupom: null,
      valor: 0.0,
      valor_original: 0.0,
      valor_final: 0.0,
      type: 0,
    },
    listServices: [],
    text_termo: "",
    etapa_um: {
      name: null,
      email2: null,
      nameFantasy: null,
      cpf_cnpj: null,

      cpf_cnpj_input: null,
      telefone: null,
      whats: null,
      user_juridica: true,
      inscricao_estadual: null,
      inscricao_municipal: null,
      etapa_um: 1,
    },
    etapa_dois: {
      cpf_cnpj: null,
      endereco: null,
      complemento: null,
      uf: null,
      numero: null,
      bairro: null,
      municipio: null,
      cep: null,
      cepConvertido: null,
      user_juridica: true,
      etapa_dois: 2,
    },
    etapa_tres: {
      cpf_cnpj: "",
      cnh: false,
      rg: true,
      user_juridica: true,
      etapa_tres: 3,
      comprovante_endereco: null,
      rg_frente: null,
      rg_verso: null,
      cnh_documento: null,

      cartao_cnpj: null,
      contrato_social: true,
      ato_constitutivo: false,

      doc_contrato_social: null,
      doc_ato_constitutivo: null,

      inscricao_estadual: true,
      inscricao_municipal: false,

      doc_inscricao_estadual: null,
      doc_inscricao_municipal: null,
    },
    etapa_quatro: {
      plano: null,
      etapa_quatro: 4,
    } /* etapa_cinco.cupomDesconto  */,
    etapa_cinco: {
      cupom_id: null,
      termos: false,
      cpf_cnpj: null,
      etapa_cinco: 5,
      plano: null,
    },
    validPessoais: true,
    validEndereco: true,
    validTermos: true,
    validPlanos: true,
    validDocumento: true,

    cpfRule: [
      (v) => !!v || "CNPJ é obrigatório",
      (v) => isCNPJ(v) || "CNPJ não é válido.",
    ],
    nameRule: [
      (v) => !!v || "Nome é obrigatório",
      (v) => v.length >= 5 || "Mínimo de 5 caracteres",
    ],
    nameFantasyRule: [
      (v) => !!v || "Nome é obrigatório",
      (v) => v.length >= 5 || "Mínimo de 5 caracteres",
    ],
    email2Rule: [
      (v) => !!v || "E-mail é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "Insira um E-mail válido",
    ],
    telefoneRule: [
      (v) => !!v || "Telefone é obrigatório",
      (v) => v.length >= 14 || "Preencha todo campo",
    ],
    whatsRule: [
      (v) => !!v || "WhatsApp é obrigatório",
      (v) => v.length >= 14 || "Preencha todo campo",
    ],
    cepRule: [(v) => !!v || "CEP é obrigatório"],
    municipioRule: [(v) => !!v || "Municipio é obrigatório"],
    ufRule: [(v) => !!v || "UF é obrigatório"],
    enderecoRule: [(v) => !!v || "Endereço é obrigatório"],
    complementoRule: [(v) => !!v || "Complemento é obrigatório"],
    numeroRule: [(v) => !!v || "Nº é obrigatório"],
    bairroRule: [(v) => !!v || "Bairro é obrigatório"],

    comprovanteRule: [
      (value) => !value || value.size < 2000000 || "Tamanho máximo é 2MB!",
      (v) => !!v || "Comprovante é obrigatório",
    ],
    rgFrenteRule: [
      (value) => !value || value.size < 2000000 || "Tamanho máximo é 2MB!",
    ],
    rgVersoRule: [
      (value) => !value || value.size < 2000000 || "Tamanho máximo é 2MB!",
    ],
    cnhRule: [
      (value) => !value || value.size < 2000000 || "Tamanho máximo é 2MB!",
    ],
  }),

  methods: {
    ...mapActions("auth", ["registerEmpresaFile", "ActionEmpresaComplete"]),

    Cadastar() {
      this.$router.push({ name: "Cadastro de Empresas" });
    },

    continuarCadastro() {
      this.$swal({
        toast: true,
        position: "bottom",
        showConfirmButton: false,
        background: "#4BB543",
        timer: 3000,
        icon: "success",
        title: '<span style="color:#FFF">Continuando cadastro..</span>',
      });
      this.loading = true;
      this.$http
        .get("continuarCadastroEmpresa/" + this.id_empresa)
        .then((response) => {
          console.log(response.data);
          this.e1 = response.data.etapa;
          this.etapa_um.name = response.data.name;

          this.etapa_um.email2 = response.data.email2;
          this.etapa_um.nameFantasy = response.data.nameFantasy;
          this.etapa_um.cpf_cnpj = response.data.cpf_cnpj;
          this.etapa_um.telefone = response.data.telefone;
          this.etapa_um.whats = response.data.whats;
          this.etapa_um.user_juridica = response.data.user_juridica;

          this.etapa_dois.cpf_cnpj = response.data.cpf_cnpj;

          this.etapa_dois.endereco = response.data.endereco;
          this.etapa_dois.complemento = response.data.complemento;
          this.etapa_dois.uf = response.data.uf;
          this.etapa_dois.numero = response.data.numero;
          this.etapa_dois.bairro = response.data.bairro;
          this.etapa_dois.municipio = response.data.municipio;
          this.etapa_dois.cep = response.data.cep;
          response.cep.this.etapa_dois.user_juridica = true;
          this.loading = false;
        });
      //this.e1 = 2;
    },
    stepComplete(step) {
      return this.e1 > step;
    },
    stepStatus(n) {
      return this.e1 > n ? "blue" : "orange";
    },

    async listaMunicipios() {
      await this.$http.get("listamunicipios").then((response) => {
        this.municipios = response.body;
      });
    },

    escolher(item) {
      this.etapa_quatro.plano = item;
      this.etapa_cinco.plano = item;
      this.validate(4);

      this.termo();
    },
    async validate(n) {
      this.loading = true;
      if (n == 1) {
        if (
          this.etapa_um.name == "" ||
          this.etapa_um.cpf_cnpj == "" ||
          this.etapa_um.whats == "" ||
          this.etapa_um.email2 == "" ||
          this.etapa_um.telefone == ""
        ) {
          this.validPessoais = false;
        } else {
          this.etapa_um.cpf_cnpj = this.etapa_um.cpf_cnpj_input.replace(
            /([^\d])+/gim,
            ""
          );
          this.etapa_um.telefone = this.etapa_um.telefone.replace(
            /([^\d])+/gim,
            ""
          );

          this.$http
            .get("empresaExiste/" + this.etapa_um.cpf_cnpj)
            .then(async function (response) {
              const value = parseInt(response.data.validacao);
              this.loading = false;
              if (value !== 0) {
                this.$swal({
                  toast: true,
                  position: "bottom",
                  showConfirmButton: false,
                  background: "#ff0000",
                  timer: 3000,
                  icon: "error",
                  title: '<span style="color:#FFF">Desculpe</span>',
                  html: '<span style="color:#FFF">CPF/CNPJ já está cadastrado</span>',
                });
                this.validPessoais = false;
                n = 1;
                return 0;
              } else {
                this.etapa_um.whats = this.etapa_um.whats.replace(
                  /([^\d])+/gim,
                  ""
                );

                this.etapa_um.whats = this.etapa_um.whats.replace(
                  /([^\d])+/gim,
                  ""
                );
                this.validPessoais = true;
                try {
                  const data = await this.ActionEmpresaComplete(this.etapa_um);
                  this.etapa_dois.cpf_cnpj = this.etapa_um.cpf_cnpj;
                  this.e1 = n + 1;
                  console.log(data);
                } catch (erro) {
                  console.log(erro);
                  this.$swal({
                    toast: true,
                    position: "bottom",
                    showConfirmButton: false,
                    background: "#ff0000",
                    timer: 3000,
                    icon: "error",
                    title: '<span style="color:#FFF">Desculpe</span>',
                    html: '<span style="color:#FFF">CPF/CNPJ já existe</span>',
                  });
                }
              }
            });
        }
      }
      if (n == 2) {
        if (
          this.etapa_dois.cep == "" ||
          this.etapa_dois.municipio == "" ||
          this.etapa_dois.uf == "" ||
          this.etapa_dois.endereco == "" ||
          this.etapa_dois.numero == "" ||
          this.etapa_dois.bairro == ""
        ) {
          this.validEndereco = false;
        } else {
          this.etapa_dois.cepConvertido = this.etapa_dois.cep.replace(
            /([^\d])+/gim,
            ""
          );
          this.etapa_dois.cpf_cnpj = this.etapa_dois.cpf_cnpj.replace(
            /([^\d])+/gim,
            ""
          );

          try {
            console.log(this.etapa_dois);
            const data = await this.ActionEmpresaComplete(this.etapa_dois);
            this.loading = false;
            data;
            this.etapa_tres.cpf_cnpj = this.etapa_dois.cpf_cnpj;
            this.e1 = n + 1;
          } catch (erro) {
            erro;
          }
        }
      }
      if (n == 3) {
        if (
          (this.etapa_tres.comprovante_endereco == null ||
          (this.etapa_tres.cartao_cnpj == null) && this.e1 < 3)
        ) {
          this.validDocumento = false;
          this.notificacao("Por favor", "Preencha todos os camposaaa");
          this.loading = false;
        } else {
          this.etapa_tres.cpf_cnpj = this.etapa_dois.cpf_cnpj.replace(
            /([^\d])+/gim,
            ""
          );
          let formData = new FormData();
          formData.append("etapa_tres", this.etapa_tres.etapa_tres);
          formData.append("cpf_cnpj", this.etapa_tres.cpf_cnpj);
          formData.append("user_juridica", this.etapa_tres.user_juridica);
          formData.append(
            "comprovante_endereco",
            this.etapa_tres.comprovante_endereco
          );
          formData.append("cartao_cnpj", this.etapa_tres.cartao_cnpj);

          formData.append("cnh", this.etapa_tres.cnh);
          formData.append("rg", this.etapa_tres.rg);
          formData.append("rg_frente", this.etapa_tres.rg_frente);
          formData.append("rg_verso", this.etapa_tres.rg_verso);
          formData.append("cnh_documento", this.etapa_tres.cnh_documento);

          formData.append("contrato_social", this.etapa_tres.contrato_social);
          formData.append("ato_constitutivo", this.etapa_tres.ato_constitutivo);
          formData.append(
            "doc_contrato_social",
            this.etapa_tres.doc_contrato_social
          );
          formData.append(
            "doc_ato_constitutivo",
            this.etapa_tres.doc_ato_constitutivo
          );

          formData.append(
            "inscricao_estadual",
            this.etapa_tres.inscricao_estadual
          );
          formData.append(
            "inscricao_municipal",
            this.etapa_tres.inscricao_municipal
          );
          formData.append(
            "doc_inscricao_estadual",
            this.etapa_tres.doc_inscricao_estadual
          );
          formData.append(
            "doc_inscricao_municipal",
            this.etapa_tres.doc_inscricao_municipal
          );

          if (this.etapa_tres.rg) {
            if (
              this.etapa_tres.rg_frente == null ||
              this.etapa_tres.rg_verso == null
            ) {
              this.contErro = true;
            }
          }
          if (this.etapa_tres.cnh) {
            if (this.etapa_tres.cnh_documento == null) {
              this.contErro = true;
            }
          }

          if (this.etapa_tres.contrato_social) {
            if (this.etapa_tres.doc_contrato_social == null) {
              this.contErro = true;
            }
          }

          if (this.etapa_tres.ato_constitutivo) {
            if (this.etapa_tres.doc_ato_constitutivo == null) {
              this.contErro = true;
            }
          }

          if (this.etapa_tres.inscricao_estadual) {
            if (this.etapa_tres.doc_inscricao_estadual == null) {
              // this.contErro = true;
            }
          }

          if (this.etapa_tres.inscricao_municipal) {
            if (this.etapa_tres.doc_inscricao_municipal == null) {
              // this.contErro = true;
            }
          }

          if (this.contErro ) {
            this.contErro = false;
            this.validDocumento = false;
            this.notificacao("Por favor", "Preencha todos os campos");
          } else if (!this.contErro ) {
            this.validDocumento = true;

            this.sendArquivos(formData);
          } else {
            this.e1 = 4;
            this.loading = false;
          }
        }
      }
      if (n == 4) {
        if (this.etapa_quatro.plano != null) {
          this.validPlanos = true;
          this.e1 = n + 1;
        } else {
          this.validPlanos = false;
        }
      }
      if (n == 5) {
        if (this.etapa_cinco.termos) {
          this.etapa_cinco.cpf_cnpj = this.etapa_um.cpf_cnpj;
          this.$http.post("registerEmpresa", this.etapa_cinco).then(
            (response) => {
              // get body data
              console.log(response);
              this.loading = false;
              this.notificacao(
                "Sucesso",
                "Cadastro feito com sucesso",
                "success"
              );
              this.e1 = n + 1;
              this.loading = false;
              this.$router.push({ name: "Home" });
            },
            (response) => {
              console.log(response);
              this.validTermos = false;
              this.loading = false;
            }
          );
        } else {
          this.validTermos = false;
        }
      }
    },
    async sendArquivos(formdata) {
      if (formdata != null) {
        try {
          const data = await this.registerEmpresaFile(formdata);
          if (data.data.erro) {
            this.notificacao("Erro", data.data.message);
            this.validDocumento = false;
            this.loading = false;
          } else {
            this.validDocumento = true;
            this.etapa_quatro.cpf_cnpj = this.etapa_tres.cpf_cnpj;
            this.e1 = 4;
            this.loading = false;
          }
        } catch (erro) {
          this.loading = false;
          erro;
        }
      }
    },
    aplicarCupom() {
      this.etapa_cinco.cupom = this.cupom_desconto;
      this.dialogInformarCupom = false;
    },
    validarCupom() {
     // alert( this.etapa_quatro.plano.id)
      //carrega as informações para verificar se é válido

      this.ldnValidar = true;
      this.$http
        .post("prevalidacaocupom", {
          cupom: this.srcCupom,
          id_plano:  this.etapa_quatro.plano.id,
        })
        .then(
          (response) => {
            console.log(response);
            this.cupom_desconto.id = response.body[0].id;
            this.cupom_desconto.cupom = response.body[0].cupom;
            this.cupom_desconto.valor = response.body[0].valor;
            this.cupom_desconto.type = response.body[0].type;
            this.cupom_desconto.valor_original = this.etapa_cinco.plano.total;
            this.cupom_desconto.valor_final =
              response.body[0].type == 1
                ? parseFloat(this.etapa_cinco.plano.total) -
                  parseFloat(response.body[0].valor)
                : parseFloat(this.etapa_cinco.plano.total) *
                  (100 - parseFloat(response.body[0].valor) / 100);

            console.log(this.cupom_desconto);
            this.ldnValidar = false;

            this.$swal({
              toast: false,
              showConfirmButton: true,
              icon: "success",
              title: "Cupom de desconto válido para aplicação",
            });
          },
          (response) => {
            console.log(response);
            this.ldnValidar = false;
            this.$swal({
              toast: false,
              showConfirmButton: true,
              icon: "error",
              title: "Não foi possível utilizar o cupom",
              text: "Cupom não existe ou expirado",
            });
          }
        );
    },
    planos() {
      this.loading = true;
      this.$http.get("Planos/1").then(
        (response) => {
          // get body data
          this.loading = false;
          this.listPlanos = response.data;
        },
        (response) => {
          console.log(response);
          this.loading = false;
          // error callback
        }
      );
    },
    modal() {
      this.modal = false;
    },
    servicesModal(registros) {
      this.modal = true;
      this.listServices = registros;
    },
    termo() {
      this.loading = true;
      this.$http.get("termo/showVigencia").then(
        (response) => {
          // get body data
          this.loading = false;
          this.text_termo = response.data.texto;
        },
        (response) => {
          console.log(response);
          this.loading = false;
          // error callback
        }
      );
    },

    rgAction(newValue) {
      this.etapa_tres.rg = newValue;
      this.etapa_tres.cnh = !newValue;
      this.cnh = !newValue;
      this.etapa_tres.cnh_documento = null;
    },
    cnhAction(newValue) {
      this.etapa_tres.cnh = newValue;
      this.etapa_tres.rg = !newValue;
      this.rg = !newValue;
      this.etapa_tres.rg_verso = null;
      this.etapa_tres.rg_frente = null;
    },

    contratoAction(newValue) {
      this.etapa_tres.contrato_social = newValue;
      this.etapa_tres.ato_constitutivo = !newValue;
      this.ato_cons = !newValue;
      this.etapa_tres.doc_ato_constitutivo = null;
    },
    atoConsAction(newValue) {
      this.etapa_tres.ato_constitutivo = newValue;
      this.etapa_tres.contrato_social = !newValue;
      this.contrato_s = !newValue;
      this.etapa_tres.doc_contrato_social = null;
    },
    estadualAction() {
      if (this.insc_estadual) {
        this.informar_depois_municipal = false;
        this.insc_municipal = false;
      }
    },
    municipalAction() {
      if (this.insc_municipal) {
        //  this.etapa_tres.inscricao_municipal = null;
        // this.etapa_tres.inscricao_estadual = null;

        this.informar_depois_municipal = false;
        this.insc_estadual = false;
      }
    },
    informarDepoisAction(newValue) {
      if (this.informar_depois_municipal) {
        this.insc_municipal = false;
        this.insc_estadual = false;
        this.etapa_tres.doc_inscricao_estadual = null;
        this.etapa_tres.inscricao_municipal = null;
        this.etapa_tres.inscricao_municipal = !newValue;
        this.etapa_tres.inscricao_estadual = !newValue;
      } else {
        this.etapa_tres.inscricao_estadual = true;
      }
    },

    notificacao(title, msg, icon = "error") {
      this.$swal({
        toast: true,
        position: "center-end",
        showConfirmButton: false,
        timer: 3000,
        icon: icon,
        title: title,
        text: msg,
      });
    },
  },

  watch: {
    "etapa_dois.uf": async function (ufchange) {
      await this.$http
        .get("listamunicipios?uf=" + ufchange)
        .then((response) => {
          console.log(response);
          this.municipios = [];
          this.municipios = response.body;
        });
    },

    "etapa_um.cpf_cnpj_input": async function (cnpj) {


      cnpj = cnpj.replace(/[^\d]+/g, "");

      if (cnpj.length == 14 && this.e1==1) {
        this.buscarDadosReceitaLoading = true;
        this.$http.get(`buscarEmpresaReceita/${cnpj}`).then(
          (response) => {
            this.etapa_um.name = response.data.nome;
            this.etapa_um.nameFantasy = response.data.fantasia;
            this.etapa_um.email2 = response.data.email;
            this.etapa_dois.cep = response.data.cep;
            this.etapa_dois.endereco = response.data.logradouro;
            this.etapa_dois.bairro = response.data.bairro;
            this.etapa_dois.uf = response.data.uf;
            this.etapa_dois.municipio = response.data.municipio;
            this.etapa_dois.numero = response.data.numero;

            console.log(response.data);

            this.buscarDadosReceitaLoading = false;
          },
          (response) => {
            console.log(response.data);
            this.buscarDadosReceitaLoading = false;
          }
        );
      }
    },
    "etapa_dois.cep": function (newValue) {
      var vm = this.etapa_dois;
      var values = this;

      var local = newValue.replace(/([^\d])+/gim, "");
      if (local.length == 8) {
        axios
          .get("https://viacep.com.br/ws/" + local + "/json/")
          .then(function (res) {
            if (!res.data.erro) {
              ///lert(res.data.localidade)
              vm.municipio = res.data.localidade;
              vm.uf = res.data.uf;
              vm.bairro = res.data.bairro;
              vm.endereco = res.data.logradouro;

              //  alert(vm.municipio);
            } else {
              values.$swal({
                toast: true,
                position: "center-end",
                showConfirmButton: false,
                background: "#ff0000",
                timer: 3000,
                icon: "error",
                title: '<span style="color:#FFF">Desculpe</span>',
                html: '<span style="color:#FFF">CEP não encontrado</span>',
              });
            }
          })
          .catch(function (err) {
            err;
          });
      }
    },
  },
};
</script>

