<template>
  <v-app-bar app flat class="degradebar">
    <v-app-bar-nav-icon @click.stop="emitclick(drawer)"></v-app-bar-nav-icon>
    <v-toolbar-title class="pl-4">
      <span style="font-size: 12px; font-weight: 600; color: #0052a0">
        {{ empresa }}</span
      >

    </v-toolbar-title>

    <v-spacer></v-spacer>
    <v-toolbar-title class="mr-4 font-weight-bold">       <v-btn 
      @click="routeFaturaGo()"
      v-if="isInadimplente=='INADIMPLENTE'" density="compact" small class="pa-5 v-btn--small" color="error">Regularizar assinatura do meu plano</v-btn></v-toolbar-title>
    <v-avatar color="#0052a0" class="border" size="25">
      <v-img :src="avatar" v-if="avatar != null"></v-img>
      <v-img v-else  src="../../assets/default_user.png"></v-img>
    </v-avatar>
    <span style="font-size: 12px; margin-left: 15px"> {{ nome }}</span>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          width="20"
          height="20"
          color="white"
          v-bind="attrs"
          v-on="on"
          fab
          text
        >
          <v-icon color="white">mdi-menu-down </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="callPerfil" link>
          <v-list-item-title>
            <v-icon color="#F27C00">mdi-account </v-icon>
            Perfil</v-list-item-title
          >
        </v-list-item>
        <v-list-item @click="sair" link>
          <v-list-item-title link>
            <v-icon color="#F27C00">mdi-logout-variant </v-icon>
            Sair</v-list-item-title
          >
        </v-list-item>
        <v-list-item
          v-for="item in items_menu"
          :to="item.to"
          color="#F27C00"
          :key="item.icon"
        >
          <v-list-item-title>
            <v-icon color="#F27C00">{{ item.icon }} </v-icon>
            {{ item.title }}</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>

import { mapActions } from "vuex";
export default {
  props: {
    items_menu: {},
    isInadimplente: {
      type: String,
      default: "",
    },
    empresa: {
      type: String,
      default: "",
    },
    nome: {
      type: String,
      default: "",
    },
   
    drawer: {
      type: Boolean,
    },

  
  },
  data: () => ({
    avatar: "../../assets/user.svg",
    time: 0,
  }),
  created() {},
  mounted() {
    this.buscarDados();
  },
  methods: {
    ...mapActions("auth", ["ActionSignOut"]),
    async sair() {
      try {
        await this.ActionSignOut();
        this.$router.push({ name: "login" });
      } catch (error) {
        alert(
          error.data ? error.data.message : "Não foi possivel sair do sistema"
        );
      }
    },
    callPerfil() {
      this.$router.push({ name: "Perfil de usuário" });
    },

    emitclick(drawer) {
      this.$emit("emit-drawer",drawer);
    
    },
    routeFaturaGo() {
      this.$router.push({ name: "Faturas" });
    },
    buscarDados() {
      this.$http
        .get("meuperfil", {
          cpf_cnpj: this.cpf_cnpj,
        })
        .then((response) => {
          this.user = response.data;
          this.avatarView();
        });
    },
    avatarView() {
      if (this.user.picture != null) {
        this.$http
          .get("verPdfUser/" + this.user.picture, {
            responseType: "blob",
          })
          .then(
            (response) => {
              const blob = new Blob([response.data]);
              const objectUrl = URL.createObjectURL(blob, {
                type: response.headers.map["content-type"],
              });

              this.avatar = objectUrl;
            },
            (response) => {
              console.log(response);
            }
          );
      }
    },

    perfilAcesso() {},
  },
};
</script>

<style scoped>
.color_active_blue {
  color: #f27c00 !important;
  font-size: 0.875rem !important;
}
.span {
  font-family: "Monserrat";
}
.degradebar {
  color: white;
  background: linear-gradient(90deg, #ffd400 0%, #f27c00 100%) 0% 0% no-repeat
    padding-box;
}
.colorblue {
  color: #f27c00 !important;
  font-size: 1.275rem !important;
}
</style>