

import {mapActions} from "vuex";
import {isCNPJ, isCPF} from "vuetify-masks-br";
import axios from "axios";
import paginationComponent from "../../components/paginationComponent";

export default {
  components: {paginationComponent},
  data: () => ({
    alertar:false,
    dialog: false,
    lote: false,
    isArquivo: false,
    column: null,
    isDisabled: false,
    loading: false,
    pagination: [],
    list: [],
    csv:[],
    linhas: [],
    isTable: false,
    isSend: false,
    row: "cpf",
    items: ["Foo", "Bar", "Fizz", "Buzz"],
    menu: false,
    isDownload:false,
    anexo_lote: null,
    arquivo_lote: null,
    form: {
      devedor: {
        name: "Lucas silva",
        data_nascimento: null,
        cpf_cnpj: "438.129.938-80",
        rg: "78.424.951/0001-85",
        endereco: "fsdfsfsdfsdf",
        bairro: "dasdas",
        numero: "1",
        municipio: "adasd",
        uf: "asdas",
        cep: "46430-000",
        cepConvertido: "",
        telefone: "(11) 9 1523-1235",
        telefone2: "",
        telefone3: "",
        email: "sssss@hotmail.com",
        email2: "",
        email3: "",
        observacao: "ok",
        user_juridica: false,
      },
      divida: {
        numero_titulo: 11,
        nosso_numero: 1,
        especie: 1,
        valor_original: 25.1,
        valor_vencido: 30.0,
        data_emissao: "2021-10-01",
        data_vencimento: "2021-10-01",
        observacao: "ola",
        file: null,
      },
    },
    emailRule: [
      (v) => !!v || "E-mail é obrigatória",
      (v) => /.+@.+\..+/.test(v) || "insira um E-mail válido",
    ],
    cpfRule: [
      (v) => !!v || "CPF é obrigatória",
      (v) => isCPF(v) || "CPF não é válido.",
    ],
    cnpjRule: [
      (v) => !!v || "CNPJ é obrigatória",
      (v) => isCNPJ(v) || "CNPJ não é válido.",
    ],
  }),
  mounted() {
    this.getNegativar();
    if(this.$store.state.auth.user.empresas.length <= 0){
     this.alertar = true
    }
  },
  methods: {
    ...mapActions("auth", ["enviarDividaFile", "ActionSendDividaLote"]),
    diaglogOpen() {
      this.dialog = true;
    },
    CadastrarEmpresa(){
      this.$router.push({ name: "empresas" });
    },
    home(){
      this.$router.push({ name: "home" });
    },
    loteOpen() {
      this.lote = true;
    },
    arquivo: function () {
      var reader = new FileReader();

      var csv = null;
      var cont = 1
      if (this.anexo_lote != null) {
        reader.readAsText(this.anexo_lote);
        var vm = this;
        reader.onload = function () {
          csv = reader.result;
          var lines = csv.split("\n");
          var result = [];


          var headers = lines[0].split(";");
          for (var i = 1; i < lines.length - 1 ; i++) {
            var obj = {};
            var objEnviar = {};
            var currentline = lines[i].split(";");
            cont = 1;


            for (var j = 0; j < headers.length; j++) {

              if (typeof currentline[j] == "undefined" || currentline[j] == "") {
                //obj[headers[j]] = currentline[j];

                if (headers[j] == "name") {
                  obj[headers[j]] = "CAMPO EM BRANCO";3
                  cont++;
                }
                if (headers[j] == "pessoa_juridica") {
                  obj[headers[j]] = "CAMPO EM BRANCO";
                  cont++;
                }
                if (headers[j] == "cpf_cnpj") {
                  obj[headers[j]] = "CAMPO EM BRANCO";
                  cont++;
                }
                if (headers[j] == "endereco") {
                  obj[headers[j]] = "CAMPO EM BRANCO";
                  cont++;
                }
                if (headers[j] == "bairro") {
                  obj[headers[j]] = "CAMPO EM BRANCO";
                  cont++;
                }
                if (headers[j] == "numero") {
                  obj[headers[j]] = "CAMPO EM BRANCO";
                  cont++;
                }
                if (headers[j] == "municipio") {
                  obj[headers[j]] = "CAMPO EM BRANCO";
                  cont++;
                }
                if (headers[j] == "uf") {
                  obj[headers[j]] = "CAMPO EM BRANCO";
                  cont++;
                }
                if (headers[j] == "cep") {
                  obj[headers[j]] = "CAMPO EM BRANCO";
                  cont++;
                }
                if (headers[j] == "rg") {
                  obj[headers[j]] = "OK";
                  objEnviar[headers[j]] = currentline[j];
                }
                if (headers[j] == "telefone") {
                  obj[headers[j]] = "OK";
                  objEnviar[headers[j]] = currentline[j];
                }
                if (headers[j] == "email") {
                  obj[headers[j]] = "OK";
                  objEnviar[headers[j]] = currentline[j];
                }
                if (headers[j] == "cliente_observacao") {
                  obj[headers[j]] = "OK";
                  objEnviar[headers[j]] = currentline[j];

                }
// DADOS DA DIVIDA
                if (headers[j] == "numero_titulo") {
                  obj[headers[j]] = "CAMPO EM BRANCO";
                  cont++;
                }
                if (headers[j] == "nosso_numero") {
                  obj[headers[j]] = "CAMPO EM BRANCO";
                  cont++;
                }
                if (headers[j] == "especie") {
                  obj[headers[j]] = "CAMPO EM BRANCO";
                  cont++;
                }
                if (headers[j] == "valor_original") {
                  obj[headers[j]] = "CAMPO EM BRANCO";
                  cont++;
                }
                if (headers[j] == "valor_vencido") {
                  obj[headers[j]] = "CAMPO EM BRANCO";
                  cont++;
                }
                if (headers[j] == "data_emissao") {
                  obj[headers[j]] = "CAMPO EM BRANCO";
                  cont++;
                }
                if (headers[j] == "data_vencimento") {
                  obj[headers[j]] = "CAMPO EM BRANCO";
                  cont++;
                }
                if (headers[j] == "divida_observacao") {
                  obj[headers[j]] = "OK";

                }


              }
            else {

                if (headers[j] == "name") {
                  if (currentline[j].length <= 4) {
                    cont++;
                    obj[headers[j]] = "MÍNIMO 4 LETRAS";
                  } else {
                    objEnviar[headers[j]] = currentline[j];
                    obj[headers[j]] = "OK";
                  }
                }
                if (headers[j] == "data_nascimento") {
                  var formatoValido = /^(\d{2})[-/](\d{2})[-/](\d{4})$/;

                  if (!formatoValido.test(currentline[j])) {
                    cont++;
                    obj[headers[j]] = "FORMATO ERRADO";
                  } else {
                    var dia = currentline[j].split("/")[0];
                    var mes = currentline[j].split("/")[1];
                    var ano = currentline[j].split("/")[2];
                    var MyData = new Date(ano, mes - 1, dia);

                    if ((MyData.getMonth() + 1 != mes)
                        || (MyData.getDate() != dia)
                        || (MyData.getFullYear() != ano)) {
                      cont++;
                      obj[headers[j]] = "VALORES INVÁLIDOS";
                    } else {
                      objEnviar[headers[j]] = currentline[j];
                      obj[headers[j]] = "OK";
                    }

                  }
                }
                if (headers[j] == "pessoa_juridica") {
                  if (isNaN(currentline[j])) {
                    cont++;
                    obj[headers[j]] = "PERMITIDO SOMENTE NÚMEROS";
                  } else if ((currentline[j] == 1) || (currentline[j] == 0)) {
                    objEnviar[headers[j]] = currentline[j];
                    obj[headers[j]] = "OK";
                  } else {

                    obj[headers[j]] = "INVÁLIDO";
                  }
                }
                if (headers[j] == "cpf_cnpj") {
                  if (isNaN(currentline[j])) {
                    cont++;
                    obj[headers[j]] = "PERMITIDO SOMENTE NÚMEROS";
                  } else if (currentline[j - 1] == 0) {
                    if (!isCPF(currentline[j])) {
                      cont++;
                      obj[headers[j]] = "CPF INVÁLIDO";
                    } else {
                      objEnviar[headers[j]] = currentline[j];
                      obj[headers[j]] = "OK";
                    }
                  } else if (currentline[j - 1] == 1) {
                    if (!isCNPJ(currentline[j])) {
                      cont++;
                      obj[headers[j]] = "CNPJ INVÁLIDO";
                    } else {
                      objEnviar[headers[j]] = currentline[j];
                      obj[headers[j]] = "OK";
                    }
                  } else {
                    cont++;
                    obj[headers[j]] = "PESSOA JURIDICA INVÁLIDO";
                  }
                }
                if (headers[j] == "rg") {
                  if (isNaN(currentline[j])) {
                    cont++;
                    obj[headers[j]] = "PERMITIDO SOMENTE NÚMEROS";
                  } else if (currentline[j].length < 10) {
                    cont++;
                    obj[headers[j]] = "NO MÍNIMO 10 DÍGITOS";
                  } else {
                    objEnviar[headers[j]] = currentline[j];
                    obj[headers[j]] = "OK";
                  }
                }
                if (headers[j] == "endereco") {
                  obj[headers[j]] = "OK";
                    objEnviar[headers[j]] = currentline[j];
                }
                if (headers[j] == "bairro") {
                  obj[headers[j]] = "OK";
                    objEnviar[headers[j]] = currentline[j];
                }
                if (headers[j] == "numero") {
                  obj[headers[j]] = "OK";
                    objEnviar[headers[j]] = currentline[j];
                }
                if (headers[j] == "municipio") {
                  obj[headers[j]] = "OK";
                    objEnviar[headers[j]] = currentline[j];
                }
                if (headers[j] == "uf") {
                  if (currentline[j].length != 2) {
                    cont++;
                    obj[headers[j]] = "EXATAMENTE 2 CARACTERES";
                  } else {
                    objEnviar[headers[j]] = currentline[j];
                    obj[headers[j]] = "OK";
                  }
                }
                if (headers[j] == "cep") {
                  if (isNaN(currentline[j])) {
                    cont++;
                    obj[headers[j]] = "PERMITIDO SOMENTE NÚMEROS";
                  } else if (currentline[j].length < 8) {
                    cont++;
                    obj[headers[j]] = "NO MÍNIMO 8 DÍGITOS";
                  } else {
                    objEnviar[headers[j]] = currentline[j];
                    obj[headers[j]] = "OK";
                  }
                }
                if (headers[j] == "telefone") {
                  if (isNaN(currentline[j])) {
                    cont++;
                    obj[headers[j]] = "PERMITIDO SOMENTE NÚMEROS";
                  } else if (currentline[j].length < 11) {
                    cont++;
                    obj[headers[j]] = "NO MÍNIMO 11 DÍGITOS";
                  } else {
                    objEnviar[headers[j]] = currentline[j];
                    obj[headers[j]] = "OK";
                  }
                }
                if (headers[j] == "email") {
                  var email = /.+@.+\..+/;
                  if (!email.test(currentline[j])) {
                    cont++;
                    obj[headers[j]] = "FORMATO INVÁLIDO";
                  } else {
                    objEnviar[headers[j]] = currentline[j];
                    obj[headers[j]] = "OK";
                  }

                }
                if (headers[j] == "numero_titulo") {
                  if (isNaN(currentline[j])) {
                    cont++;
                    obj[headers[j]] = "PERMITIDO SOMENTE NÚMEROS";
                  } else {
                    objEnviar[headers[j]] = currentline[j];
                    obj[headers[j]] = "OK";
                  }
                }
                if (headers[j] == "nosso_numero") {
                  if (isNaN(currentline[j])) {
                    cont++;
                    obj[headers[j]] = "PERMITIDO SOMENTE NÚMEROS";
                  } else {
                    objEnviar[headers[j]] = currentline[j];
                    obj[headers[j]] = "OK";
                  }
                }
                if (headers[j] == "especie") {
                  if (isNaN(currentline[j])) {
                    cont++;
                    obj[headers[j]] = "PERMITIDO SOMENTE NÚMEROS";
                  } else {
                    objEnviar[headers[j]] = currentline[j];
                    obj[headers[j]] = "OK";
                  }
                }
                if (headers[j] == "valor_original") {
                  currentline[j] = currentline[j].replace(",", ".")
                  if (isNaN(currentline[j])) {
                    cont++;
                    obj[headers[j]] = "PERMITIDO SOMENTE NÚMEROS";
                  } else {
                    objEnviar[headers[j]] = currentline[j];
                    obj[headers[j]] = "OK";
                  }
                }
                if (headers[j] == "valor_vencido") {
                  currentline[j] = currentline[j].replace(",", ".")
                  if (isNaN(currentline[j])) {
                    cont++;
                    obj[headers[j]] = "PERMITIDO SOMENTE NÚMEROS";
                  } else {
                    objEnviar[headers[j]] = currentline[j];
                    obj[headers[j]] = "OK";
                  }
                }
                if (headers[j] == "data_emissao") {
                  var formatoEmissaoValido = /^(\d{2})[-/](\d{2})[-/](\d{4})$/;

                  if (!formatoEmissaoValido.test(currentline[j])) {
                    cont++;
                    obj[headers[j]] = "FORMATO ERRADO";
                  } else {
                    var diaE = currentline[j].split("/")[0];
                    var mesE = currentline[j].split("/")[1];
                    var anoE = currentline[j].split("/")[2];
                    var MyDataE = new Date(anoE, mesE - 1, diaE);

                    if ((MyDataE.getMonth() + 1 != mesE)
                        || (MyDataE.getDate() != diaE)
                        || (MyDataE.getFullYear() != anoE)) {
                      cont++;
                      obj[headers[j]] = "VALORES INVÁLIDOS";
                    } else {
                      objEnviar[headers[j]] = currentline[j];
                      obj[headers[j]] = "OK";
                    }

                  }
                }
                if (headers[j] == "data_vencimento") {
                  var formatoVencimentoValido = /^(\d{2})[-/](\d{2})[-/](\d{4})$/;

                  if (!formatoVencimentoValido.test(currentline[j])) {
                    cont++;
                    obj[headers[j]] = "FORMATO ERRADO";
                  } else {
                    var diaV = currentline[j].split("/")[0];
                    var mesV = currentline[j].split("/")[1];
                    var anoV = currentline[j].split("/")[2];
                    var MyDataV = new Date(anoV, mesV - 1, diaV);

                    if ((MyDataV.getMonth() + 1 != mesV)
                        || (MyDataV.getDate() != diaV)
                        || (MyDataV.getFullYear() != anoV)) {
                      cont++;
                      obj[headers[j]] = "VALORES INVÁLIDOS";
                    } else {
                      obj[headers[j]] = "OK";
                      objEnviar[headers[j]] = currentline[j];
                    }

                  }
                }
                if (headers[j] == "divida_observacao") {
                  obj[headers[j]] = "OK";
                    objEnviar[headers[j]] = currentline[j];
                }
                if (headers[j] == "cliente_observacao") {

                  obj[headers[j]] = "OK";
                    objEnviar[headers[j]] = currentline[j];
                }

              }
            }
            if (cont > 1) {
              obj["linha"] = i + 1;
              result.push(obj);
            }else{
              result.push(objEnviar);
            }
            //vm.linhas = obj

          }
          if (cont > 1) {
           // eslint-disable-next-line no-unused-vars
           vm.linhas = result;

            vm.isTable = true
            vm.isSend = false
          } else {
            vm.arquivo_lote = result; //JSON
            vm.isTable = false
            vm.isSend = true
            vm.isArquivo = true
            vm.notificacao("Sucesso", "Arquivo válido", "success")
          }// eslint-disable-next-line no-unused-vars


        }

      }
    },
    EnviarDividaLote() {
      this.isArquivo = false;
      this.$http.post("sendLoteNegativar", this.arquivo_lote).then(
          (response) => {
            console.log(response.body.negativados)
            this.csv = response.body.negativados
            this.isDownload = true
            this.isArquivo = false
          },
          (response) => {
            console.log(response);
            // error callback
          }
      );
    },
    baixar(){

      var negativados = this.csv

      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(negativados[0]).join(";"),
        ...negativados.map(item => Object.values(item).join(";"))
      ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();

    },

    EnviarDivida() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("devedor['name']", this.form.devedor.name);
        formData.append("devedor['cpf_cnpj']", this.form.devedor.cpf_cnpj);
        formData.append("devedor['rg']", this.form.devedor.rg);
        formData.append("devedor['endereco']", this.form.devedor.endereco);
        formData.append("devedor['bairro']", this.form.devedor.bairro);
        formData.append("devedor['numero']", this.form.devedor.numero);
        formData.append("devedor['municipio']", this.form.devedor.municipio);
        formData.append("devedor['uf']", this.form.devedor.uf);
        formData.append(
            "devedor['cepConvertido']",
            this.form.devedor.cepConvertido
        );
        formData.append("devedor['telefone']", this.form.devedor.telefone);
        formData.append("devedor['telefone2']", this.form.devedor.telefone2);
        formData.append("devedor['telefone3']", this.form.devedor.telefone3);
        formData.append("devedor['email']", this.form.devedor.email);
        formData.append("devedor['email2']", this.form.devedor.email2);
        formData.append("devedor['email3']", this.form.devedor.email3);
        formData.append("devedor['observacao']", this.form.devedor.observacao);
        formData.append(
            "devedor['user_juridica']",
            this.form.devedor.user_juridica
        );

        formData.append(
            "divida['numero_titulo']",
            this.form.divida.numero_titulo
        );
        formData.append(
            "divida['nosso_numero']",
            this.form.divida.nosso_numero
        );
        formData.append("divida['especie']", this.form.divida.especie);
        formData.append(
            "divida['valor_original']",
            this.form.divida.valor_original
        );
        formData.append(
            "divida['valor_vencido']",
            this.form.divida.valor_vencido
        );
        formData.append(
            "divida['data_emissao']",
            this.form.divida.data_emissao
        );
        formData.append(
            "divida['data_vencimento']",
            this.form.divida.data_vencimento
        );
        formData.append("divida['observacao']", this.form.divida.observacao);
        formData.append("divida['file']", this.form.divida.file);

        this.sendArquivos(formData);
      } else {
        this.notificacao(
            "Atenção",
            "preencha todos os campos obrigatórios!",
            "error"
        );
      }
    },
    async sendArquivos(formdata) {
      try {
        const data = await this.enviarDividaFile(formdata);
        if (data.data.erro) {
          this.notificacao("Erro", data.data.message, "error");
          this.dialog = true;
        } else {
          this.notificacao("Sucesso", data.data.message, "success");
          this.getNegativar();
          this.dialog = false;
        }
      } catch (erro) {
        erro;
      }
    },
    notificacao(title, msg, icon) {
      this.$swal({
        toast: true,
        position: "center-end",
        showConfirmButton: false,
        timer: 3000,
        icon: icon,
        title: title,
        text: msg,
      });
    },
    getNegativar() {
      this.$http.get("getNegativar").then(
          (response) => {
            this.list = response.data.data;
            this.pagination = response.data;
          },
          (response) => {
            console.log(response);
            // error callback
          }
      );
    },

    navigate(page) {
      this.$http.get("getNegativar?page=" + page).then(
          (response) => {
            this.list = response.data.data;
            this.pagination = response.data;
          },
          (response) => {
            console.log(response);
            // error callback
          }
      );
    },
    save(date) {
      this.$refs.menu.save(date);
    },
  },

  watch: {
    "form.devedor.cep": function (newValue) {
      var vm = this.form.devedor;
      var values = this;

      var local = newValue.replace(/([^\d])+/gim, "");
      if (local.length == 8) {
        axios
            .get("https://viacep.com.br/ws/" + local + "/json/")
            .then(function (res) {
              if (!res.data.erro) {
                vm.municipio = res.data.localidade;
                vm.uf = res.data.uf;
                vm.bairro = res.data.bairro;
                vm.endereco = res.data.logradouro;
              } else {
                values.$swal({
                  toast: true,
                  position: "center-end",
                  showConfirmButton: false,
                  background: "#ff0000",
                  timer: 3000,
                  icon: "error",
                  title: '<span style="color:#FFF">Desculpe</span>',
                  html: '<span style="color:#FFF">CEP não encontrado</span>',
                });
              }
            })
            .catch(function (err) {
              err;
            });
      }
    },
    row: function (newValue) {
      if (newValue == "cpf") {
        this.form.devedor.user_juridica = false;
      } else {
        this.form.devedor.user_juridica = true;
      }
      this.form.devedor.cpf_cnpj = "";
      this.form.devedor.rg = "";
      this.form.devedor.data_nascimento = null;
      this.isDisabled = false;
      this.form.devedor.name = "";
      this.form.devedor.rg = "";
      this.form.devedor.endereco = "";
      this.form.devedor.bairro = "";
      this.form.devedor.numero = "";
      this.form.devedor.municipio = "";
      this.form.devedor.uf = "";
      this.form.devedor.cep = "";
      this.form.devedor.telefone = "";
      this.form.devedor.telefone2 = "";
      this.form.devedor.telefone3 = "";
      this.form.devedor.email = "";
      this.form.devedor.email2 = "";
      this.form.devedor.email3 = "";
      this.form.devedor.observacao = "";
    },
    "form.devedor.cpf_cnpj": function (newValue) {
      var local = newValue.replace(/([^\d])+/gim, "");
      var vm = this;
      if (this.row == "cpf") {
        if (local.length == 11) {
          this.loading = true;
          this.$http.get("getUsuario" + local).then(
              (response) => {
                // get body data
                vm.form.devedor.cpf_cnpj;
                vm.form.devedor.name = response.data.name;
                vm.form.devedor.rg = response.data.rg;
                vm.form.devedor.endereco = response.data.endereco;
                vm.form.devedor.bairro = response.data.bairro;
                vm.form.devedor.numero = response.data.numero;
                vm.form.devedor.municipio = response.data.municipio;
                vm.form.devedor.uf = response.data.uf;
                vm.form.devedor.cep = response.data.cep;
                vm.form.devedor.telefone = response.data.telefone;
                vm.form.devedor.telefone2 = response.data.telefone2;
                vm.form.devedor.telefone3 = response.data.telefone3;
                vm.form.devedor.email = response.data.email;
                vm.form.devedor.email2 = response.data.email2;
                vm.form.devedor.email3 = response.data.email3;
                vm.form.devedor.observacao = response.data.observacao;
                vm.isDisabled = true;
                this.loading = false;
                // vm.form.devedor.data_nascimento = response.data.data_nascimento
              },
              (response) => {
                console.log(response);
                // error callback
              }
          );
        }
      } else {
        if (local.length == 14) {
          this.loading = true;
          this.$http.get("getUsuario" + local).then(
              (response) => {
                vm.form.devedor.cpf_cnpj;
                vm.form.devedor.name = response.data.name;
                vm.form.devedor.endereco = response.data.endereco;
                vm.form.devedor.bairro = response.data.bairro;
                vm.form.devedor.numero = response.data.numero;
                vm.form.devedor.municipio = response.data.municipio;
                vm.form.devedor.uf = response.data.uf;
                vm.form.devedor.cep = response.data.cep;
                vm.form.devedor.telefone = response.data.telefone;
                vm.form.devedor.telefone2 = response.data.telefone2;
                vm.form.devedor.telefone3 = response.data.telefone3;
                vm.form.devedor.email = response.data.email;
                vm.form.devedor.email2 = response.data.email2;
                vm.form.devedor.email3 = response.data.email3;
                vm.form.devedor.observacao = response.data.observacao;
                vm.isDisabled = true;
                this.loading = true;
              },
              (response) => {
                console.log(response);
                // error callback
              }
          );
        }
      }
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    }

  }
}