<template>
  <v-container fluid fill-height class="mx-0 my-0 d-flex center">
    <v-img
      style="position: absolute; bottom: 0px"
      width="1300px"
      src="../../assets/PNG/bg_negativa.png"
    >
    </v-img>
    <v-card style="position: absolute; width: 75%">
      <v-card-text>
        <v-row >
          <v-col cols="12">
            <h1>Seja bem vindo!</h1>
          </v-col>
          <v-col cols="8">
            <p>
              Saudaçoes, estamos muito felizes e telo-lo conosco.
            </p>
            <p>
              Plataforma de negativação de dívidas que estimula o recebimento do crédito para qualquer segmento. Credores em geral, empresários, lojistas, condomínios com títulos certos, líquidos e exigíveis ora têm um serviço de negativação acessível, democrático, com segurança jurídica e sem burocracia. Recuperação de crédito com eficiência e tecnologia.
            </p>
            <p>
              Para usufruir das funcionalidades do sistema, é nescessário está
              efeuando o cadastro dos dados do credor
            </p>
            <p>
              <v-btn
                width="30%"
                large
                class="amber--text"
                to="/empresas"
                color="#0052A0"
              >
                <!-- <v-icon dark>mdi-arrow-right-circle </v-icon>-->

                COMEÇAR AGORA!
              </v-btn>
            </p>
          </v-col>
          <v-col cols="3"> 
              <p><v-img width="350px" src="../../assets/PNG/monitora_logo_color.png"></v-img></p>
              <p style="width:100%; "> <v-icon>mdi-facebook</v-icon>  <v-icon>mdi-instagram</v-icon>  <v-icon>mdi-linkedin</v-icon>  </p>
              <p>Suporte: 0800-000-000</p>
               </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  components: {
    /* paginationComponent */
  },

  data: () => ({}),
};
</script>
<style>
.back {
  background-color: #f27c00;
  height: 100%;

  top: 0px;
  left: 0px;
  width: 100% !important;
  z-index: 0;
}
p{
   color: #0052a0;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 600;
  font-size: 14px;
  font-family: "Montserrat";
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: inset 0px 3px 6px #00000029;
  border: 1px solid #00000000;
  height: 50px;
  color: #f27c00;
}

.rounded-card {
  border-radius: 50px;
}

.v-input__icon--append .v-icon {
  color: #0052a0;
}
.v-card__title {
  background-color: transparent;
}
.v-input__icon--prepend-inner .v-icon {
  color: #0052a0;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 600;
  font-size: 18px;
  font-family: "Montserrat";
}
.theme--light.v-input input,
.theme--light.v-input textarea {
  font-size: 13px;
  color: #0052a0;
  font-family: "Montserrat";
  font-weight: 600;
  letter-spacing: 0.19px;
}
.blue-btn-text {
  color: #0052a0;
  font-weight: bold;
}
.ind {
  height: 100% !important;
}
.color-yellow {
  background: #ffd400;
}
h1 {
  color: #0066ff !important;
  font-family: "Montserrat";
}
.color-yellow-light {
  background: #ffdd00;
}
.title {
  color: #656565;
}
.v-btn {
  border-radius: 30px;
  font-family: "Montserrat";
}

.color-yellow-orange {
  background: #f27c00;
  background: -moz-linear-gradient(top, #f27c00 0%, #ffa900 100%);
  background: -webkit-linear-gradient(top, #f27c00 0%, #ffa900 100%);
  background: linear-gradient(to bottom, #f27c00 0%, #ffa900 100%);
}
.no-padding {
  padding: inherit !important;
}
</style>