<template>

  <v-dialog
      transition="dialog-top-transition"
      max-width="80%"
      
      v-model="dialog_log"
  >
    <template v-slot:default="">
      <v-card>
        <v-card-title>
          <h2 class="font-weight-light">
            Detalhes do plano: {{ registros.nome }}
          </h2>
        </v-card-title>
         <v-card-text>
          <v-row>
            <v-col md="6" sm="12" lg="6">
              <v-simple-table>
                <thead>
                <tr style="border: 1px solid #ccc; background-color: #e2e2e2">
                  <th colspan="3" class="text-center black--text">
                    Todos Serviços
                  </th>

                </tr>
                <tr>
                  <th class="text-center">
                    Serviço
                  </th>
                  <th class="text-center">
                    Limite transações
                  </th>
                  <th class="text-center">
                    Valor Uni./Excedente
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item, index) in registros.services"
                    :key="index"
                >
                  <td class="text-center">{{ item.descricao }}</td>
                  <td class="text-center">{{ item.qt_max == null ? 'Indeterminado':item.qt_max }}</td>
                  <td class="text-center">{{converterDinherio(item.valor_service) }}</td>
                  
                </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col md="6" sm="12" lg="6">
              <v-simple-table>
                <thead>
                <tr style="border: 1px solid #ccc; background-color: #e2e2e2">
                  <th colspan="3" class="text-center black--text">
                   Periodos Descontos
                  </th>

                </tr>
                <tr>
                  <th class="text-center">
                    Periodo
                  </th>
                  <th class="text-center">
                    Desconto
                  </th>

                </tr>
                </thead>
                <tbody>
             
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>


        </v-card-text>

       
        <v-card-actions class="justify-end">
          <!--<v-btn text  color="blue"> Valor Total do Plano {{converterDinherio(registros.total)}}</v-btn>-->
          <v-btn text @click="$emit('close')">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>

</template>

<script>
import helper from "../mixins/helper";

export default {
  name: "ModalServicesComponent",
  mixins: [helper],
  props: {
    registros: {
      default: []
    },
    protocolo: {
      default: ''
    },
    dialog_log: {
      default: false
    }

  },
  mounted() {
    console.log(this.registros)
  },


  data: () => ({
    headers_log: [

      // { text: '#'},
      {text: 'Data/Hora', value: 'data', align: 'start'},
      {text: 'Usuario', value: 'name', align: 'center'},
      {text: 'IP', value: 'user_ip', align: 'center'},
      {text: 'Transação', value: 'transacao', align: 'center'},
      {text: 'Ação', value: 'acao', align: 'center'},
      {text: 'Motivo', value: 'motivo', align: 'center'},
      {text: 'Observação', value: 'observacao', width: "15%", align: 'center'},
      {text: 'Status', value: 'status_nome', width: "15%", align: 'center'},

    ],
  }),
  methods: {
    close() {
      this.dialog_log = false;
    },
    emitclick() {
      this.dialog_log = !this.dialog_log
      this.$emit("emit-dialog_log", !this.dialog_log);

    },
  }
}
</script>

<style scoped>

</style>