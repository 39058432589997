<template>
  <v-container class="mx_h">
    <v-layout row wrap justify-space-around fill-height class="mr-3">
      <v-row class="my-6" v-if="fatura">
        <v-flex xs12 md5 class="pa-1 pl-11">
          <v-text-field
            outlined
            dense
            label="Empresa"
            hide-details="auto"
            v-model="empresaName"
            readonly
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md4 class="pa-1">
          <v-text-field
            outlined
            dense
            label="Referencia da fatura"
            hide-details="auto"
            readonly
            :value="fatura.referencia"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md3 class="pa-1">
          <v-text-field
            outlined
            dense
            readonly
            label="Status"
            hide-details="auto"
            :value="fatura.status ? 'Pago' : 'Em aberto'"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md2 class="pa-1 pl-11">
          <v-text-field
            outlined
            dense
            readonly
            label="Data inicial"
            hide-details="auto"
            :value="converterDataTimestamp(fatura.data_inicial)"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md2 class="pa-1">
          <v-text-field
            outlined
            readonly
            dense
            label="Data final"
            hide-details="auto"
            :value="converterDataTimestamp(fatura.data_final)"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md2 class="pa-1">
          <v-text-field
            outlined
            dense
            readonly
            label="Data de Faturamento"
            hide-details="auto"
            :value="converterDataTimestamp(fatura.data_faturamento)"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md2 class="pa-1">
          <v-text-field
            outlined
            dense
            readonly
            label="Data de vencimento"
            hide-details="auto"
            :value="converterDataTimestamp(fatura.data_vencimento)"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md4 class="pa-1">
          <v-text-field
            :value="converterDinherio(fatura.valor_total)"
            dense
            outlined
            label="Valor Total da fatura"
            readonly
            hide-details
          ></v-text-field>
        </v-flex>
         <v-flex  xs12 md12 class="pa-1 pl-11">
          <v-btn
            elevation="2"
            class="primary mr-1"
            color="white--text "
            @click="emitirFatura()"    
          >
          <v-icon>mdi-printer</v-icon>
            Emitir Fatura
          </v-btn>
          <a v-bind:href = fatura.url_danfnfse  target="_blank">
            <v-btn
              elevation="2"
              class="primary mr-1"
              color="white--text "
            
            >
            <v-icon>mdi-printer</v-icon>
              Emitir Nota Fiscal
            </v-btn>
          </a>  
         </v-flex>
      </v-row>
      <!-- TABELA -->
      <v-flex xs12 md12 class="pl-11">
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify "
          label="Serviço"
          dense
          single-line
          color="blue"
          outlined
        ></v-text-field>
      </v-flex>
      <v-flex xs12 md12 class="pa-1">
        <v-card class="mx_h" elevation="0">
          <v-card-text>
            <v-data-table
              dense
              :headers="headers"
              :items="list"
              item-key="lote"
              :single-expand="true"
              :search="search"
              show-expand
              class="elevation-0"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td></td>
                  <td class="text-center">{{ item.id }}</td>
                  <td>{{ item.nome }}</td>
                  <td>{{ converterDinherio(item.preco_venda) }}</td>
                  <td>{{ converterDataTime(item.created_at) }}</td>
                  <td>{{ item.qtd_utilizada }}</td>
                  <td class="text-center">
                    <v-icon
                      :class="item.faturado ? 'green--text' : 'red--text'"
                      >{{
                        item.faturado ? "mdi-check-bold" : "mdi-close-box"
                      }}</v-icon
                    >
                  </td>

                  <td>
                    {{
                      converterDinherio(item.preco_venda * item.qtd_utilizada)
                    }}
                  </td>
                </tr>
              </template>
              <v-alert slot="no-results" :value="true">
                Sua busca por "{{ search }}" não encontrou resultados.
              </v-alert>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
        <v-flex xs12 md12 class="pa-1" v-if="fatura.status == 0">
          
          <iframe
              v-show="false"
              :src="pagamentosGerados[0].juno_checkout"
              width="100%"
              height="1000"
              frameborder="0" >
          </iframe>  
        <v-expansion-panels flat >
          <v-expansion-panel>
            <v-expansion-panel-header>
              Pagar Agora!
            </v-expansion-panel-header>
            <v-expansion-panel-content>
            <!--  <vue-friendly-iframe :src="`https://www.npmjs.com/package/vue-frame`"></vue-friendly-iframe>-->
             <iframe
          
             :src="pagamentosGerados[0].juno_checkout"
              width="100%"
              height="1000"
              frameborder="0" >
          </iframe>  
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </v-flex>
    </v-layout>
     <img id='itf' style="    visibility: collapse;"/>
  </v-container>
  
</template>

<script>
import helper from "../../mixins/helper";

//import faturaImpressao from "/pages/faturamento/modelos_fatura/fatura_modelo_01.js";
import modelos from "@/pages/faturamento/fatura_modelo_01.js";




export default {
  components: {
   
  },
  mixins: [helper],
  data: () => ({
    faturaId: "",    
    formaPagamento : null,
    observacao_pgto : null,
    dataHoje: null,
    formasPagamento: [],
    faturasItems: [],
    dialog: false,
    empresaDados: [],
    pagamentosGerados: [],
    list: [],
    fatura: [],
    fatura_completa : null,
    search: "",
    dtPag: "",
    headers: [
      {
        text: "id",
        value: "id",
        align: "center",
      },
      {
        text: "Serviço",
        value: "nome",
        align: "start",
      },
      {
        text: "Preço de custo",
        value: "preco_custo",
        align: "start",
      },
      {
        text: "Preço mínimo",
        value: "preco_minimo",
        align: "start",
      },
      {
        text: "Preço de Venda",
        value: "preco_venda",
        align: "start",
      },
      {
        text: "Data e Hora",
        value: "created_at",
        align: "start",
      },
      {
        text: "Quantidade",
        value: "qtd_utilizada",
        align: "start",
      },
      {
        text: "Faturado",
        value: "status",
        align: "center",
      },
      {
        text: "Valor Total",
        value: "faturado",
        align: "start",
      },
    ],
  }),
  mounted() {
    this.faturaId = this.$route.params.id;
    this.dataHoje = new Date().toISOString();
    this.dataHoje = this.dataHoje.split("T");
    this.dataHoje = this.dataHoje[0];
    this.dtPag = this.dataHoje;

 
    this.getFatura();
  },

  methods: {
    getDtPag(data) {
      this.dtPag = data;
    },
    copyTestingCode ( obj) {
      navigator.clipboard.writeText(obj)
        .then(() => {

          this.notificacao('', 'Código de barras copiado para área de transferencia', 'sucess') 
         
        })
        .catch(() => {
          alert('Falha ao copiar codigo de barras ');
      });

    },

    isSelectFPagamento(val){
      this.formaPagamento = val;

    },
    
    notificacao(title, msg, icon) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
        icon: icon,
        title: title,
        text: msg,
      });
    },
    baixarFatura(){
      this.dialog =true;
    },
    async baixarFaturaSbt(){
      const { data: result } = await this.$http.post(
        "baixarfatura",
        {
          fatura_id: this.fatura.id,
          dtPag: this.dtPag,
          formaPagamento: this.formaPagamento  ,
          observacao_pgto: this.observacao_pgto       
             
        }
      );
      console.log(result);
      this.getFatura();

    },
    emitirFatura(){
      
    modelos.modelo_1(this.fatura_completa);

    },

    async getFatura() {
      try {
        const { data: result } = await this.$http.get(
          "detalhesFatura/" + this.faturaId
        );
        this.list = result.servicos;
        this.fatura = result.fatura;
        this.empresaDados = result.empresa
        this.faturasItems = result.items;
        this.pagamentosGerados = result.documentos_cobranca;
        this.formasPagamento = result.formasPagamento;
        console.log(this.pagamentosGerados);
        this.fatura_completa = result;
      } catch (error) {
        //
      } finally {
        this.loadingBusca = false;
        this.buscaRealizada = true;
      }
    },
  },
};
</script>