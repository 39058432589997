<template>
  <v-container class="mx_h">
    <v-row class="pa-1 pl-6">
      <v-col lg="3" md="4">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-avatar size="250px" v-ripple class="mb-3" @click="triggerFileInput">
              <v-img :src="avatar" v-if="avatar != null"></v-img>
              <v-img v-else  src="../../assets/default_user.png"></v-img>
              
            </v-avatar>
          </v-col>

          <v-col cols="8" sm="8" md="8">
            <v-file-input ref="fileInput" label="Anexar ou Substituir anexo." class="bold" filled accept="image/*"
              rounded background-color="blue lighten-5" @change="alterarFoto" v-model="fotoUpload"
              style="display: none"></v-file-input>
          </v-col>
          <v-col cols="12">
            <v-btn @click="preDeletePerfilImage" round color="error" text> <v-icon>mdi-delete-forever</v-icon> Remover
              imagem de perfil</v-btn>

          </v-col>
        </v-row>
      </v-col>
      <v-col cols="10" md="8">
        <v-row>
          <v-col cols="12">
            <h3 v-text="user.name"></h3>
          </v-col>
          <v-col cols="12">
            <subtitle-1>Dados do usuário:</subtitle-1>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="user.email" filled rounded dense disabled label="E-mail"
              color="orange darken-4"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="user.perfilacesso" filled rounded dense disabled label="Perfil de Acesso"
              color="orange darken-4"></v-text-field>
          </v-col>

        </v-row>
      </v-col>

      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12">
        <v-switch v-model="trocarSenha" :value="trocarSenha" label="Alterar senha de acesso" />
      </v-col>

      <v-col v-if="trocarSenha" cols="12" sm="12" md="12">
        <v-form ref="form" v-model="validCad" lazy-validation>
          <v-row class="d-flex justify-center">
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="6" sm="12" md="6">
              <v-text-field v-model="passwordCurrent" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'" label="Senha Atual" filled rounded dense required
                @click:append="show1 = !show1" color="blue"></v-text-field>
            </v-col>
            <v-col cols="12" style="height: 1px; margin-top: -50px"></v-col>

            <v-col cols="6" sm="12" md="6">
              <v-text-field v-model="passwordNew" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'" label="Nova senha" filled rounded dense required
                :rules="senhaRules1" @click:append="show2 = !show2" color="blue"></v-text-field>
              <div v-html="forcaSenha" />
            </v-col>
            <v-col cols="12" style="height: 1px; margin-top: -50px"></v-col>

            <v-col cols="6" sm="12" md="6">
              <v-text-field v-model="passwordConfirm" :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show3 ? 'text' : 'password'" label="Confirmar Senha" filled rounded dense required
                :rules="senhaRules1" @click:append="show3 = !show3" color="blue"></v-text-field>
            </v-col>

            <v-col cols="12" style="height: 1px; margin-top: -50px; margin-bottom: -50px"></v-col>
            <v-col cols="2">
              <v-btn color="#0052A0" x-large elevation="0" :disabled="!validCad" @click="alterarSenha">
                <v-icon style="
                    color: #ffd400;
                    font-weight: 600;
                    letter-spacing: 0.19px;
                  " class="mr-1">mdi-arrow-right-bold</v-icon>
                <span style="
                    color: #ffd400;
                    font-weight: 600;
                    letter-spacing: 0.19px;
                  ">ALTERAR</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="12">
        <v-row justify="center">
          <v-btn color="#ffa900" x-large elevation="0" :loading="ldg_enviar" :to="{
            name: 'Home',
          }">
            <v-icon style="color: #0052a0; font-weight: 600; letter-spacing: 0.19px"
              class="mr-1">mdi-arrow-left-bold</v-icon>
            <span style="color: #0052a0; font-weight: 600; letter-spacing: 0.19px">VOLTAR</span>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import DataComponent from "../../components/DataComponent";
//import { VueRecaptcha } from "vue-recaptcha";

export default {
  components: {
    //ImageUpload
  },
  mixins: [],
  data: () => ({
    user: {},
    avatar: null,
    fotoUpload: null,
    trocarSenha: false,
    forcaSenha: "",
    show1: false,
    show2: false,
    show3: false,
    profilePic: null,
    passwordCurrent: null,
    passwordNew: null,
    passwordConfirm: null,
    password: null,
    validCad: false,
    file: null,
    senhaRules1: [
      (v) => !!v || "Senha é obrigatória",
      (v) =>
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(v) ||
        "A senha deve conter letras maiúsculas, letras minúsculas, números e caracteres especiais(@,$,&...)",
    ],
  }),
  created() { },
  mounted() {
    this.buscarDados();
  },

  methods: {
    triggerFileInput() {
      this.$refs.fileInput.$el.querySelector('input').click();
    },
    buscarDados() {
      this.$http
        .get("meuperfil", {
          cpf_cnpj: this.cpf_cnpj,
        })
        .then((response) => {
          console.log(response.data);
          this.user = response.data;
          this.avatarView();
        });
    },

    preDeletePerfilImage() {
      this.$swal({
        title: "Remover imagem de perfil?",
        text: "Você não poderá reverter isso!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {

          this.$http.post("removerImagePerfilWeb").then((response) => {
            console.log(response);
            this.$swal({
              toast: false,
              position: "top-center",
              showConfirmButton: true,

              icon: "success",
              title: "Perfil atualizado com sucesso",
            }).then(() => {
              window.location.reload();
            });


          });
        }
      });
    },
    avatarView() {
      this.$http
        .get("verPdfUser/" + this.user.picture, {
          responseType: "blob",
        })
        .then(
          (response) => {
            const blob = new Blob([response.data]);
            const objectUrl = URL.createObjectURL(blob, {
              type: response.headers.map["content-type"],
            });

            this.avatar = objectUrl;
            this.profilePic.push(objectUrl);
          },
          (response) => {
            console.log(response);
            // const objectUrl = `<h1>Não possui anexo</h1> `;
            // this.pdfsrc = objectUrl;
          }
        );
    },
    alterarFoto() {
      // try to log $refs object for deep understanding
      let fileToUpload = this.fotoUpload;
      console.log(this.fotoUpload);

      let formData = new FormData();

      formData.append("doc", fileToUpload);
      formData.append("teste", "teste");
      console.log(formData);
      this.$http.post("alterarImagePerfilWeb", formData).then((response) => {
        console.log(response);
        this.$swal({
          toast: false,
          position: "top-center",
          showConfirmButton: true,

          icon: "success",
          title: "Perfil atualizado com sucesso",
        }).then(() => {
          window.location.reload();
        });


      });
    },
    onFileChange(event) {
      this.file = event.target.files[0];
    },

    alterarSenha() {
      this.$http
        .post("alterarsenha", {
          passwordCurrent: this.passwordCurrent,
          passwordNew: this.passwordNew,
          passwordConfirm: this.passwordConfirm,
        })
        .then(
          (response) => {
            console.log(response);
            this.$swal({
              toast: false,
              position: "top-center",
              showConfirmButton: true,

              icon: "success",
              title: "Senha alterada com sucesso",
            });
          },
          (response) => {
            console.log(response);
            this.$swal({
              toast: false,
              position: "top-center",
              showConfirmButton: true,

              icon: "error",
              title: "Não foi possível efetuar a operação",
              text: response.data.message,
            });
          }
        );
    },
    persist(avatar) {
      let data = new FormData();

      data.append("avatar", avatar);

      /*axios.post(`/api/users/${this.user.name}/avatar`, data)
                    .then(() => flash('Avatar uploaded!'));*/
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
};
</script>

<style>
.degrade {
  background: #f12711;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f5af19, #f12711);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f5af19, #f12711);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

td {
  font-weight: bold !important;
  color: #000 !important;
}

.mx_h {
  height: 24%;
  margin-left: 0 !important;
}

.degrade-titulo-02 {
  background: #00b7ff;
  background: linear-gradient(90deg,
      #ac0042 0%,
      rgba(255, 255, 255, 0.3477766106442577) 43%,
      rgba(255, 255, 255, 0) 100%);
}

.swal2-html-container {
  font-family: "Montserrat";

  font-weight: 600;
}

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 45px;
  width: 150px;
  font-family: "Montserrat";

  font-weight: 600;
  background-color: #5e6164;
  color: #ffd400;
  font-size: 1.0625em;
}


.table-secundary {
  border-bottom: solid #00000021;
  border-left: solid #00000021;
  border-right: solid #00000021;
  background-color: #eeeeee;
}

.text-res {
  max-width: 40ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
