<template>
  <v-container fluid class="mx-0 my-0 d-flex ind">
    <v-img
      style="position: absolute; right: 5px; bottom: 0px"
      width="1300px"
      src="../../assets/PNG/bg_negativa.png"
    >
    </v-img>

    <v-layout row class="align-content-center">
      <v-flex xs12 md8 sm8 lg6>
        <v-card style="border-radius: 0px 50px 50px 0px" elevation="4">
          <v-card-title class="" style="background-color:white">
            <div style="left: 0px; margin-bottom: -50px">
              <v-img
                src="../../assets/SVG/monitora_logo_color.svg"
                contain
                max-height="100"
                max-width="auto"
              ></v-img>
            </div>
          </v-card-title>
          <v-card-title style="background-color:white" class="font-weight-bold title pt-14 pb-10 px-8">
            <h3>Escolha o credor para se conectar</h3></v-card-title
          >
          <v-card-actions class="px-1">
            <v-col class="d-flex" cols="12" sm="12">
              <v-select
                v-bind:items="items"
                v-model="isSelectEmpresa"
                label="Escolha a empresa"
                solo
                filled
                rounded
                dense
                color="blue"
                background-color="#dcf1ff"
                class="text--blue"
                item-value="id"
              >
                <template slot="item" scope="data">
                  <v-icon smal color="#0052a0"> mdi-domain </v-icon
                  >{{ data.item.id }} - {{ data.item.name }} -
                  {{ data.item.cpf_cnpj }}
                </template>
                <template slot="selection" scope="data" class="blue-btn-text">
                  <v-icon smal color="#0052a0"> mdi-domain </v-icon>
                  {{ data.item.id }} - {{ data.item.name }} -
                  {{ data.item.cpf_cnpj }}
                </template>
              </v-select>
            </v-col>
          </v-card-actions>

          <v-card-actions class="pa-0">
            <v-row class="pa-0" style="margin-bottom: 15px" justify="center">
              <!--
             <v-btn
                color="#ffd400"
                block
                elevation="0"
                large
                class="pa-8 rounded-0"
                @click="sair"
                >Sair <v-icon dark> mdi-logout</v-icon>
              </v-btn>
             
            </v-col>

            <v-col
              class="d-flex pa-0"
              cols="6"
              sm="6"
              align-self="center"
              align="center"
            >
              <v-btn
                color="#ffd400 "
                block
                :disabled="!isSelectEmpresa"
                large
                class="pa-8 rounded-0"
                @click="Entrar"
                >Entrar <v-icon dark> mdi-login</v-icon>
              </v-btn>
            </v-col>
            <v-col
              class="d-flex pa-0"
              cols="6"
              sm="6"
              align-self="center"
              align="center"
            >
            -->
              <v-col cols="3" sm="8" md="8">
                <v-btn
                  @click="Entrar"
                  :loading="loading"
                  :disabled="!isSelectEmpresa"
                  width="100%"
                  large
                  class="amber--text"
                  color="#0052A0"
                >
                  <!-- <v-icon dark>mdi-arrow-right-circle </v-icon>-->
                  <img src="../../assets/PNG/login_black_24dp.png" />
                  LOGIN
                </v-btn>
              </v-col>

              <v-col cols="3" sm="8" md="8">
                <v-btn
                  @click="cadastrarEmpresa"
                  width="100%"
                  large
                  color="#0090DF"
                >
                  <!-- <v-icon dark>mdi-arrow-right-circle </v-icon>-->
                  <img src="../../assets/PNG/business_black_24dp.png" />
                  <span style="color: white"> Cadastrar</span>
                </v-btn>
              </v-col>

              <v-col cols="3" sm="8" md="8">
                <v-btn @click="sair" large color="#FFDD00" width="100%">
                  <img src="../../assets/PNG/reply_black_24dp.png" />
                  <span class="blue-btn-text"> Voltar</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>


<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    tabsForm: null,
    isSelectEmpresa: false,
    loading: false,
    items: [],
  }),
  mounted() {
    this.items = this.$store.state.auth.user.empresas;
  },
  methods: {
    ...mapActions("auth", [
      "ActionSignOut",
      "ActionDomainLogin",
      "ActionSelectDomain",
    ]),
    async sair() {
      try {
        await this.ActionSignOut();
        this.$router.push({ name: "login" });
      } catch (error) {
        alert(
          error.data ? error.data.message : "Não foi possivel sair do sistema"
        );
      }
    },

    cadastrarEmpresa() {
      this.$router.push({ name: "Cadastro de Credor" });
    },
    async Entrar() {
      try {
        this.loading = true;
        await this.ActionSelectDomain(this.isSelectEmpresa);
        this.$router.push({ name: "Home" });
        this.loading = false;
      } catch (error) {
        alert(
          error.data ? error.data.message : "Não foi possivel sair do sistema"
        );
      }

      //  this.ActionDomainLogin(this.isSelectEmpresa);

      //this.$router.push({ name: "home" });
    },
  },
};
</script>

<style>
.back {
  background-color: #f27c00;
  height: 100%;
  position: absolute !important;
  top: 0px;
  left: 0px;
  width: 100% !important;
  z-index: 0;
}
h3 {
  font-family: "Montserrat";
  color: #0052a0;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: inset 0px 3px 6px #00000029;
  border: 1px solid #00000000;
  height: 50px;
  color: #f27c00;
}

.rounded-card {
  border-radius: 50px;
}

.v-input__icon--append .v-icon {
  color: #0052a0;
}
.v-input__icon--prepend-inner .v-icon {
  color: #0052a0;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 18px;
  font-family: "Montserrat";
}
.theme--light.v-input input,
.theme--light.v-input textarea {
  font-size: 13px;
  color: #0052a0;
  font-family: "Montserrat";
}

.v-select__selections {
  font-size: 15px;
  letter-spacing: 0.19px;
  color: #0052a0;
  font-family: "Montserrat";
}
.blue-btn-text {
  color: #0052a0;
  font-weight: bold;
}
.blue-btn-text {
  color: #0052a0;
  font-weight: bold;
}
.ind {
  height: 100% !important;
}
.color-yellow {
  background: #ffd400;
}

.color-yellow-light {
  background: #ffdd00;
}
.title {
  color: #656565;
}
.v-card__title {
   
    background-color: white;
    font-family: "Montserrat";

}
.v-btn {
  border-radius: 30px;
  font-family: "Montserrat";
}

.color-yellow-orange {
  background: #f27c00;
  background: -moz-linear-gradient(top, #f27c00 0%, #ffa900 100%);
  background: -webkit-linear-gradient(top, #f27c00 0%, #ffa900 100%);
  background: linear-gradient(to bottom, #f27c00 0%, #ffa900 100%);
}
.no-padding {
  padding: inherit !important;
}
</style>