<template>
  <v-form >
    <v-row fluid>
      <v-col cols="12">
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step
              :complete="stepComplete(1)"
              :rules="[() => !!validPessoais]"
              :color="stepStatus(1)"
              step="1"
              >Dados Pessoais</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step
              step="2"
              :complete="stepComplete(2)"
              :rules="[() => !!validEndereco]"
              :color="stepStatus(2)"
              >Dados do endereço</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step
              :complete="stepComplete(3)"
              :rules="[() => !!validDocumento]"
              :color="stepStatus(3)"
              step="3"
              >Documentos
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :complete="stepComplete(4)"
              :rules="[() => !!validTermos]"
              :color="stepStatus(4)"
              step="4"
              >Termos
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card
                class="mb-12"
                color="white lighten-1"
                height="auto"
                elevation="0"
              >
                <v-row>
                  <v-col cols="4" elevation="10">
                    <v-text-field
                      class="bold"
                      filled
                      rounded
                      background-color="blue lighten-5"
                      v-model="etapa_um.name"
                      label="Razão Social"
                      required
                      :rules="nameRule"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      class="bold"
                      filled
                      rounded
                      v-model="etapa_um.cpf_cnpj"
                      label="CPF"
                      v-cpf:model="etapa_um.cpf_cnpj"
                      required
                      :rules="cpfRule"
                      background-color="orange lighten-5"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      class="bold"
                      filled
                      rounded
                      v-model="etapa_um.email"
                      label="E-mail "
                      required
                      disabled
                      background-color="blue lighten-5"
                      :rules="emailRule"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      class="bold"
                      filled
                      rounded
                      v-model="etapa_um.telefone"
                      label="Telefone"
                      v-phone:model="etapa_um.telefone"
                      required
                      background-color="blue lighten-5"
                      :rules="telefoneRule"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      class="bold"
                      filled
                      rounded
                      v-model="etapa_um.whats"
                      label="WhatsApp"
                      required
                      :rules="whatsRule"
                      v-phone:model="etapa_um.whats"
                      background-color="blue lighten-5"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>
              <v-row class="pa-3">
                <v-col cols="12" align="right">
                  <v-btn
                    color="primary"
                    elevation="2"
                    right
                    fab
                    large
                    @click="validate(1)"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card
                class="mb-12"
                color="white lighten-1"
                height="auto"
                elevation="0"
              >
                <v-row>
                  <v-col cols="2">
                    <v-text-field
                      v-model="etapa_dois.cep"
                      label="CEP"
                      required
                      :rules="cepRule"
                      v-cep:model="etapa_dois.cep"
                      class="bold"
                      filled
                      rounded
                      background-color="blue lighten-5"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="etapa_dois.municipio"
                      label="Município"
                      required
                      :rules="municipioRule"
                      class="bold"
                      filled
                      rounded
                      background-color="blue lighten-5"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="etapa_dois.uf"
                      label="UF"
                      required
                      :rules="ufRule"
                      class="bold"
                      filled
                      rounded
                      background-color="blue lighten-5"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="etapa_dois.endereco"
                      label="Endereço "
                      required
                      :rules="enderecoRule"
                      class="bold"
                      filled
                      rounded
                      background-color="blue lighten-5"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="etapa_dois.complemento"
                      label="Complemento"
                      required
                      :rules="complementoRule"
                      class="bold"
                      filled
                      rounded
                      background-color="blue lighten-5"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="etapa_dois.numero"
                      label="Nº"
                      required
                      :rules="numeroRule"
                      class="bold"
                      filled
                      rounded
                      background-color="blue lighten-5"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="etapa_dois.bairro"
                      label="Bairro"
                      required
                      :rules="bairroRule"
                      class="bold"
                      filled
                      rounded
                      background-color="blue lighten-5"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>
              <v-row class="pa-3">
                <v-col cols="6" align="left">
                  <v-btn
                    color="primary"
                    elevation="2"
                    right
                    fab
                    large
                    @click="e1 = 1"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="6" align="right">
                  <v-btn
                    color="primary"
                    elevation="2"
                    right
                    fab
                    large
                    @click="validate(2)"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card
                class="mb-12"
                color="white lighten-1"
                height="auto"
                elevation="0"
              >
                <v-row>
                  <v-col cols="1" class="align-self-center">
                    <v-checkbox
                      v-model="rg"
                      label="RG"
                      @change="rgAction"
                      class="shrink mr-2 mt-0"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="cnh"
                      label="CNH"
                      @change="cnhAction"
                      class="shrink mr-2 mt-0"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="5">
                    <v-file-input
                      v-if="rg"
                      v-model="etapa_tres.rg_frente"
                      label="RG Frente"
                      class="bold"
                      filled
                      :disabled="!rg"
                      accept="image/*, application/pdf"
                      rounded
                      background-color="blue lighten-5"
                      :rules="rgFrenteRule"
                    ></v-file-input>
                    <v-file-input
                      v-if="rg"
                      v-model="etapa_tres.rg_verso"
                      label="RG Verso"
                      class="bold"
                      filled
                      :disabled="!rg"
                      accept="image/*, application/pdf"
                      rounded
                      background-color="blue lighten-5"
                      :rules="rgVersoRule"
                    ></v-file-input>
                    <v-file-input
                      v-if="cnh"
                      v-model="etapa_tres.cnh_documento"
                      label="CNH"
                      class="bold"
                      filled
                      :disabled="!cnh"
                      accept="image/*, application/pdf"
                      rounded
                      background-color="blue lighten-5"
                      :rules="cnhRule"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="6" class="align-self-center">
                    <v-file-input
                      v-model="etapa_tres.comprovante_endereco"
                      label="Comprovante de endereço atualizado"
                      class="bold"
                      filled
                      rounded
                      accept="image/*, application/pdf"
                      background-color="blue lighten-5"
                      :rules="comprovanteRule"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-card>
              <v-row class="pa-3">
                <v-col cols="6" align="left">
                  <v-btn
                    color="primary"
                    elevation="2"
                    right
                    fab
                    large
                    @click="e1 = 2"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </v-col>

                <v-col cols="6" align="right">
                  <v-btn
                    color="primary"
                    elevation="2"
                    right
                    fab
                    large
                    @click="validate(3)"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-card
                class="mb-12"
                color="white lighten-1"
                height="auto"
                elevation="0"
              >
                <v-row>
                  <v-col cols="12">
                    <v-responsive class="overflow-y-auto" max-height="400">
                      <div class="pa-6 text-center">
                         <p class="text-left font-weight-bold">
                          Termos de Uso
                        </p>
                        <p class="text-justify font-weight-bold" >
                            {{ text_termo }}
                          </p>
                       
                      </div>

                      <v-responsive height="auto" class="text-center pa-2">
                        <v-lazy
                          v-model="isActive"
                          :options="{
                            threshold: 1,
                          }"
                          min-height="200"
                          transition="fade-transition"
                        >
                          <v-checkbox
                            v-model="etapa_quatro.termos"
                            label="ACEITO OS TERMOS DE USO"
                          ></v-checkbox>
                        </v-lazy>
                      </v-responsive>
                    </v-responsive>
                  </v-col>
                </v-row>
              </v-card>

              <v-row class="pa-3">
                <v-col cols="6" align="left">
                  <v-btn
                    color="primary"
                    elevation="2"
                    right
                    fab
                    large
                    @click="e1 = 3"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="6" align="right">
                  <v-btn
                    color="primary"
                    elevation="2"
                    right
                    :disabled="!etapa_quatro.termos"
                    fab
                    large
                    @click="validate(4)"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { isCPF } from "vuetify-masks-br";

import axios from "axios";
import { mapActions } from "vuex";
export default {
  mounted() {
    this.etapa_um.name = this.$store.state.auth.user.name;
    this.etapa_um.email = this.$store.state.auth.user.email;
    this.termo()
     if(!this.$store.state.auth.user.user_juridica){
      this.e1 = (this.$store.state.auth.user.etapa == null)?1:this.$store.state.auth.user.etapa + 1
    }
  },

  data: () => ({
    e1: 1,
    rg: true,
    cnh: false,
    isActive: false,
    text_termo: '',
    etapa_um: {
      name: "",
      email: "",
      cpf_cnpj: "43812993880",
      telefone: "(11) 9 1523-1235",
      whats: "(11) 9 1523-1235",
      etapa_um: 1,
    },
    etapa_dois: {
      endereco: "fsdfsfsdfsdf",
      complemento: "asdas",
      uf: "asdas",
      numero: "1",
      bairro: "dasdas",
      municipio: "adasd",
      cep: "46430-000",
      cepConvertido: "",
      etapa_dois: 2,
    },
    etapa_tres: {
      cnh: false,
      rg: true,
      user_juridica: false,
      etapa_tres: 3,
      comprovante_endereco: null,
      rg_frente: null,
      rg_verso: null,
      cnh_documento: null,
    },
    etapa_quatro: {
      termos: false,
      etapa_quatro: 4,
      
    },
    validPessoais: true,
    validEndereco: true,
    validTermos: true,
    validDocumento: true,

    cpfRule: [
      (v) => !!v || "CPF é obrigatório",
      (v) => isCPF(v) || "CPF não é válido.",
    ],
    nameRule: [
      (v) => !!v || "Nome é obrigatória",
      (v) => v.length >= 5 || "Mínimo de 5 caracteres",
    ],
    emailRule: [
      (v) => !!v || "E-mail é obrigatória",
      (v) => /.+@.+\..+/.test(v) || "insira um e-mail E-mail ",
    ],
    telefoneRule: [
      (v) => !!v || "Telefone é obrigatória",
      (v) => v.length >= 14 || "Preencha todo campo",
    ],
    whatsRule: [
      (v) => !!v || "WhatsApp é obrigatória",
      (v) => v.length >= 14 || "Preencha todo campo",
    ],
    cepRule: [(v) => !!v || "CEP é obrigatória"],
    municipioRule: [(v) => !!v || "Município é obrigatória"],
    ufRule: [(v) => !!v || "UF é obrigatória"],
    enderecoRule: [(v) => !!v || "Endereço é obrigatória"],
    complementoRule: [(v) => !!v || "Complemento é obrigatória"],
    numeroRule: [(v) => !!v || "Nº é obrigatória"],
    bairroRule: [(v) => !!v || "Bairro é obrigatória"],

    comprovanteRule: [
      (value) => !value || value.size < 2000000 || "Tamanho máximo é 2MB!",
      (v) => !!v || "Comprovante é obrigatória",
    ],
    rgFrenteRule: [
      (value) => !value || value.size < 2000000 || "Tamanho máximo é 2MB!",
    ],
    rgVersoRule: [
      (value) => !value || value.size < 2000000 || "Tamanho máximo é 2MB!",
    ],
    cnhRule: [
      (value) => !value || value.size < 2000000 || "Tamanho máximo é 2MB!",
    ],
  }),

  methods: {
    ...mapActions("auth", ["registerCompleteFile", "ActionRegisterComplete"]),
    async submit() {
      this.form.cpf_cnpj = this.form.cpf_cnpj.replace(/([^\d])+/gim, "");
      this.form.telefone = this.form.telefone.replace(/([^\d])+/gim, "");
      this.form.whats = this.form.whats.replace(/([^\d])+/gim, "");
      this.form.cep = this.form.cep.replace(/([^\d])+/gim, "");

      try {
        const data = await this.ActionRegisterComplete(this.form);
        console.log(data);
        this.$router.push({ name: "home" });
      } catch (error) {
        console.log(error);
      }
    },
    Cadastar() {
      this.$router.push({ name: "home" });
    },
    stepComplete(step) {
      return this.e1 > step;
    },
    stepStatus(n) {
      return this.e1 > n ? "green" : "blue";
    },
    async validate(n) {
      if (n == 1) {
        if (
          this.etapa_um.name == "" ||
          this.etapa_um.cpf_cnpj == "" ||
          this.etapa_um.whats == "" ||
          this.etapa_um.email == "" ||
          this.etapa_um.telefone == ""
        ) {
          this.validPessoais = false;
        } else {
          this.etapa_um.cpf_cnpj = this.etapa_um.cpf_cnpj.replace(
            /([^\d])+/gim,
            ""
          );
          this.etapa_um.telefone = this.etapa_um.telefone.replace(
            /([^\d])+/gim,
            ""
          );
          this.etapa_um.whats = this.etapa_um.whats.replace(/([^\d])+/gim, "");
          this.validPessoais = true;
          try {
            const data = await this.ActionRegisterComplete(this.etapa_um);
            console.log(data);
            this.e1 = n + 1;
          } catch (erro) {
            this.$swal({
              toast: true,
              position: "bottom",
              showConfirmButton: false,
              background: "#ff0000",
              timer: 3000,
              icon: "error",
              title: '<span style="color:#FFF">Desculpe</span>',
              html: '<span style="color:#FFF">CPF/CNPJ já existe</span>',
            });
          }
        }
      }
      if (n == 2) {
        if (
          this.etapa_dois.cep == "" ||
          this.etapa_dois.municipio == "" ||
          this.etapa_dois.uf == "" ||
          this.etapa_dois.endereco == "" ||
          this.etapa_dois.numero == "" ||
          this.etapa_dois.bairro == ""
        ) {
          this.validEndereco = false;
        } else {
          this.etapa_dois.cepConvertido = this.etapa_dois.cep.replace(
            /([^\d])+/gim,
            ""
          );
          this.validEndereco = true;
          try {
            const data = await this.ActionRegisterComplete(this.etapa_dois);
            data;
            this.e1 = n + 1;
          } catch (erro) {
            erro;
          }
        }
      }
      if (n == 3) {
        if (this.etapa_tres.comprovante_endereco == null) {
          this.validDocumento = false;
          this.notificacao("Por favor", "Preencha todos os campos");
        } else {
          let formData = new FormData();
          formData.append("etapa_tres", this.etapa_tres.etapa_tres);
          formData.append("user_juridica", this.etapa_tres.user_juridica);
          formData.append(
            "comprovante_endereco",
            this.etapa_tres.comprovante_endereco
          );

          if (this.etapa_tres.rg) {
            if (
              this.etapa_tres.rg_frente != null &&
              this.etapa_tres.rg_verso != null
            ) {
              formData.append("cnh", this.etapa_tres.cnh);
              formData.append("rg", this.etapa_tres.rg);

              formData.append("rg_frente", this.etapa_tres.rg_frente);
              formData.append("rg_verso", this.etapa_tres.rg_verso);
              formData.append("cnh_documento", this.etapa_tres.cnh_documento);
              this.sendArquivos(formData);
            } else {
              this.validDocumento = false;
              this.notificacao("Por favor", "Preencha todos os campos");
            }
          } else if (this.etapa_tres.cnh) {
            if (this.etapa_tres.cnh_documento != null) {
              formData.append("cnh", this.etapa_tres.cnh);
              formData.append("rg", this.etapa_tres.rg);

              formData.append("rg_frente", this.etapa_tres.rg_frente);
              formData.append("rg_verso", this.etapa_tres.rg_verso);
              formData.append("cnh_documento", this.etapa_tres.cnh_documento);
              this.sendArquivos(formData);
            } else {
              this.validDocumento = false;
              this.notificacao("Por favor", "Preencha todos os campos");
            }
          } else {
            this.notificacao("Por favor", "Preencha todos os campos");
            this.validDocumento = false;
          }
        }
      }
      if (n == 4) {

         if (this.etapa_quatro.termos) {
           try {
            const data = await this.ActionRegisterComplete(this.etapa_quatro);
            data;
            this.e1 = n + 1;
          } catch (erro) {
            erro;
          }
         }else{
            this.validTermos = false
         }
        
      }
    },
    async sendArquivos(formdata) {
      try {
        const data = await this.registerCompleteFile(formdata);
        if (data.data.erro) {
          this.notificacao("Erro", data.data.message);
          this.validDocumento = false;
        } else {
          this.validDocumento = true;
          this.e1 = 4;
           this.$router.push({ name: "home" });
        }
      } catch (erro) {
        erro;
      }
    },
     termo(){
     this.$http.get('https://api.stoneup.com.br/api/auth/termo').then(response => { 
          this.text_termo =  response.data.texto
        }, response => {
          console.log(response)
          // error callback
        });
    },

    rgAction(newValue) {
      this.etapa_tres.rg = newValue;
      this.etapa_tres.cnh = !newValue;
      this.cnh = !newValue;
      this.etapa_tres.cnh_documento = null;
    },
    cnhAction(newValue) {
      this.etapa_tres.cnh = newValue;
      this.etapa_tres.rg = !newValue;
      this.rg = !newValue;
      this.etapa_tres.rg_verso = null;
      this.etapa_tres.rg_frente = null;
    },

    notificacao(title, msg) {
      this.$swal({
        toast: true,
        position: "center-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: title,
        text: msg,
      });
    },
  },

  watch: {
    "etapa_dois.cep": function (newValue) {
      var vm = this.etapa_dois;
      var values = this;

      var local = newValue.replace(/([^\d])+/gim, "");
      if (local.length == 8) {
        axios
          .get("https://viacep.com.br/ws/" + local + "/json/")
          .then(function (res) {
            if (!res.data.erro) {
              vm.municipio = res.data.localidade;
              vm.uf = res.data.uf;
              vm.bairro = res.data.bairro;
              vm.endereco = res.data.logradouro;
            } else {
              values.$swal({
                toast: true,
                position: "center-end",
                showConfirmButton: false,
                background: "#ff0000",
                timer: 3000,
                icon: "error",
                title: '<span style="color:#FFF">Desculpe</span>',
                html: '<span style="color:#FFF">CEP não encontrado</span>',
              });
            }
          })
          .catch(function (err) {
            err;
          });
      }
    },
  },
};
</script>
