<template>

    <v-dialog
        transition="dialog-top-transition"
        max-width="90%"
        persistent
        v-model="dialog_log"
    >
      <template v-slot:default="">
        <v-card>
          <v-toolbar style="  background-color: #026ea7; margin-bottom:15px;  font-family: Montserrat;" dark flat
          ><h2 class="font-weight-bold">
            Porotocolo - {{ protocolo }}
          </h2>

            <v-spacer></v-spacer>
            <!-- <h2 class="font-weight-bold">Status:</h2> -->

            <!-- <v-chip
              label
              class="ma-2"
              x-large
              color="blue darken-4"
              text-color="white"
              @click:close="chip2 = false"
            >
              {{ log.status }}
            </v-chip> -->
          </v-toolbar>
          <v-card-text>
            <v-data-table
                :headers="headers_log"
                :items="registros"
                item-key="protocolo"
                :items-per-page="5"
                  :footer-props="{
                'items-per-page-text': 'Registros por página: ',
                'page-text': '{0}-{1} de {2}',
                'items-per-page-all-text': 'Listar todos',
              }"
                class="elevation-1"
            >
              <template v-slot:item="{ item }" dense>
                <tr>
                  <td>{{ converterDataTime(item.data) }}</td>
                  <td class="text-center">{{ item.name }}</td>
                  <td class="text-center">{{ item.user_ip }}</td>
                  <td class="text-center">{{ item.transacao }}</td>
                  <td class="text-center">{{ item.acao }}</td>
                  <td class="text-center">{{ item.motivo }}</td>
                  <td class="text-center">{{ item.observacao }}</td>
                  <td class="text-center">{{ item.status }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="$emit('close')">Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

</template>

<script>
import helper from "../mixins/helper";

export default {
  name: "LogComponent",
  mixins: [helper],
  props:{
    registros:{
      default:[]
    },
    protocolo:{
      default: ''
    },
    dialog_log:{
      default:false
    }

  },

  data: () => ({
    headers_log: [

      // { text: '#'},
      { text: 'Data/Hora', value: 'data', align: 'start' },
      { text: 'Usuario', value: 'name', align: 'center' },
      { text: 'IP', value: 'user_ip', align: 'center' },
      { text: 'Transação', value: 'transacao', align: 'center' },
      { text: 'Ação', value: 'acao', align: 'center' },
      { text: 'Motivo', value: 'motivo', align: 'center' },
      { text: 'Observação', value: 'observacao', width: "15%", align: 'center' },
      { text: 'Status', value: 'status_nome', width: "15%", align: 'center' },

    ],
  }),
  methods:{
      close(){
        this.dialog_log = false;
      },
    emitclick() {
      this.dialog_log = !this.dialog_log
      this.$emit("emit-dialog_log", !this.dialog_log);

    },
  }
}
</script>

<style scoped>

</style>