<template>
  <v-container class="mx_h">
    <v-layout row wrap justify-space-around fill-height>
      <v-expansion-panels flat  :value="0">
        <v-expansion-panel>
          <v-expansion-panel-header>
            Filtros
          </v-expansion-panel-header>
          <v-expansion-panel-content>
          <v-row class="my-6">
            <v-flex xs12 md3 class="pa-1 pl-6">
              <data-component
                v-model="dataInicial"
                labelDate="Emissão Inicial"
                rounded
                only-date
                color="blue"
                
          
              ></data-component>
            </v-flex>
            <v-flex xs12 md3 class="pa-1">
              <data-component
                v-model="dataFinal"
                labelDate="Emissão Final"
                rounded
                only-date
                color="blue"
               

              
              >
              </data-component>
            </v-flex>

            <v-flex xs12 md3 class="pa-1 pl-6">
              <data-component
                v-model="dataInicial"
                labelDate="Vencimento Inicial"
                rounded
                only-date
                color="blue"
          
              ></data-component>
            </v-flex>
            <v-flex xs12 md3 class="pa-1">
              <data-component
                v-model="dataFinal"
                labelDate="Vencimento Final"
                  rounded
                only-date
                color="blue"
              
              >
              </data-component>
            </v-flex>
           
            <v-flex xs12 md3 class="pa-1 pl-6">
              <v-overflow-btn
                  :items="dropdown_edit"
                  label="Status Pagamento"
                  dense
                  rounded
                 
                  class="ma-0"
                  item-text="text"
                  item-value="value"
                  v-model="status_fatura"
              >
              </v-overflow-btn>
            </v-flex>
            <v-flex  xs12 md12 class="pa-1 pl-11">
              <v-btn
                  elevation="2"
                  class="primary mr-1"
                  color="white--text "
                  @click="getFaturas"    
              >
                <v-icon>mdi-magnify</v-icon>
                buscar
              </v-btn>
              <v-btn
                elevation="2"
               color="#FFD400"
                @click="limpar"                   
              >
              <v-icon small color="#0052A0">mdi-close-circle</v-icon>
       <span  style= "color:#0052A0; font-weight: 600;"> Limpar</span>
              </v-btn>
            </v-flex>
          </v-row>
           </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- TABELA -->
      <v-flex xs12 md12 class="pa-1" >
        <v-card class="mx_h" elevation="0">
          <v-card-text>
            <v-data-table
              dense
              :headers="headers"
              :items="list"
              item-key="lote"
              :single-expand="true"
              :search="search"
              show-expand
              class="elevation-0"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td></td>
                  <td>{{ item.id }}</td>
               
                  <td>{{ item.referencia }}</td>
                  <td>{{ moment( item.data_faturamento).format("DD/MM/YYYY")   }}</td>
                   <td>{{ moment( item.data_vencimento).format("DD/MM/YYYY")  }}</td>
                   <td>
                    <v-icon
                      :class="item.status ? 'green--text' : 'red--text'"
                      >{{
                        item.status ? "mdi-check-bold" : "mdi-close-box"
                      }}</v-icon
                    >
                  </td>
            
                  <td>
                    {{
                      converterDinherio(item.valor_total)
                    }}
                  </td>
                

                  
                  <td class="text-center">
                    <v-btn
                      elevation="0"
                      small
                      icon
                      :to="{ name: 'Detalhes da Fatura', params: { id: item.id, name: empresaName } }"
                    >
                      <v-icon dark> mdi-magnify</v-icon>
                    </v-btn>
                    <v-btn
                      elevation="0"
                      small
                      icon
                      @click="emitirFatura(item.id)"
                    >
                      <v-icon dark> mdi-printer</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
              <v-alert slot="no-results" :value="true">
                Sua busca por "{{ search }}" não encontrou resultados.
              </v-alert>
              <v-alert slot="no-data" :value="true">
                <div class="display-1">Nenhum fatura encontrada!</div>
              </v-alert>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
       <img id='itf' style="    visibility: collapse;"/>
      
    </v-layout>
  </v-container>
</template>
<script>
import DataComponent from "../../components/DataComponent";
import helper from "../../mixins/helper";

export default {
   components: {
      DataComponent,

  },
  mixins: [helper],
  data: () => ({
    list: [],
    search: "",
     dropdown_edit: [{text: 'Todos', value: 0}, {text: 'Abertos', value: 1}, {text: 'Pagos', value: 2}],
    headers: [
      {
        text: "Nº Fatura",
        value: "id",
        align: "center",
      },
      {
        text: "Referencia",
        value: "referencia",
        align: "start",
      },
      {
        text: "Data Emissão",
        value: "data_inicial",
        align: "start",
      },
      {
        text: "Data Vencimento",
        value: "data_final",
        align: "start",
      },
    
      {
        text: "Pago",
        value: "status",
        align: "start",
      },
      {
        text: "Valor da Fatura",
        value: "valor_total",
        align: "start",
      },
      {
        text: "Ações",
        align: "center",
        value: "",
      },
    ],
  }),
  mounted() {
    this.getFaturas();
  },

  methods: {
      getDataInicial(data) {
      this.dataInicial = data;
    },
    getDataFinal(data) {
      this.dataFinal = data;
    },
    getVencInicial(data) {
      this.vencInicial = data;
    },
    getVencFinal(data) {
      this.vencFinal = data;
    },
    notificacao(title, msg, icon) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
        icon: icon,
        title: title,
        text: msg,
      });
    },
    async getFaturas() {
      try {
        const { data: result } = await this.$http.get(
          "faturas");
        this.list = result;
      } catch (error) {
        //
      } finally {
        //
      }
    },
  },
};
</script>

<style>
.degrade {
  background: #f12711;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f5af19, #f12711);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f5af19, #f12711);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

td {
  font-weight: bold !important;
  color: #000 !important;
}

.mx_h {
  height: 24%;
  margin-left: 0 !important;
}

.degrade-titulo-02 {
  background: #00b7ff;
  background: linear-gradient(
    90deg,
    #ac0042 0%,
    rgba(255, 255, 255, 0.3477766106442577) 43%,
    rgba(255, 255, 255, 0) 100%
  );
}

.table-secundary {
  border-bottom: solid #00000021;
  border-left: solid #00000021;
  border-right: solid #00000021;
  background-color: #eeeeee;
}

.text-res {
  max-width: 40ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
