<template>

    <v-dialog
        transition="dialog-top-transition"
        max-width="80%"
        persistent
        v-model="dialog_history"
    >
      <template v-slot:default="">
        <v-card>
          <v-toolbar style="  background-color: #026ea7; margin-bottom:15px;  font-family: Montserrat;"  dark flat
          ><h2 class="font-weight-bold">
            Porotocolo - {{ visualizacao.protocolo }}
          </h2>

            <v-spacer></v-spacer>
            <h2 style="#FFD400"> Status:</h2>

            <v-chip
                label
                class="ma-2"
                x-large
                color="#FFD400"
            
                @click:close="chip2 = false"
            >
              <span style="color :#0052A0;  font-weight: 700;">  {{ visualizacao.status }}</span>
            </v-chip>
          </v-toolbar>
          <v-card-actions>
            <v-text-field
                v-model="visualizacao.empresa"
                label="Empresa"
                placeholder="Empresa"
                rounded filled
                color="blue "
                readonly
                dense
            ></v-text-field>
          </v-card-actions>
          <v-card-actions>
            <v-flex xs12 md4 class="pa-1">
              <v-text-field
                  v-model="visualizacao.cpf_cnpj"
                  label="CNPJ/CPF"
                  rounded filled
                  color="blue "
                  dense
                  readonly
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4 class="pa-1">
              <v-text-field
                  v-model="visualizacao.email"
                  label="E-mail"
                  rounded filled
                  readonly
                  color="blue "
                  dense
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4 class="pa-1">
              <v-text-field
                  :value ="moment(visualizacao.data_insercao).format('DD/MM/YYYY')"
                  label="Data de Inserção"
                  rounded filled
                  readonly
                  color="blue "
                  dense
              ></v-text-field>
            </v-flex>
          </v-card-actions>
          <v-card-text>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-actions>
            <v-text-field
                v-model="visualizacao.devedor"
                label="Devedor"
                rounded filled
                readonly
                color="blue "
                dense
            ></v-text-field>
          </v-card-actions>
          <v-card-actions>
            <v-flex xs12 md4 class="pa-1">
              <v-text-field
                  v-model="visualizacao.dev_cpfCnpj"
                  label="CNPJ/CPF"
                  rounded filled
                  readonly
                  color="blue "
                  dense
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4 class="pa-1">
              <v-text-field
                  v-model="visualizacao.valor_original"
                  label="Valor Original"
                  rounded filled
                  color="blue "
                  dense
                  readonly
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4 class="pa-1">
              <v-text-field
                  v-model="visualizacao.valor_vencido"
                  label="Valor Vencido"
                  rounded filled
                  color="blue "
                  dense
                  readonly
              ></v-text-field>
            </v-flex>
          </v-card-actions>
          <v-card-actions>
            <v-flex xs12 md4 class="pa-1">
              <v-text-field
                  v-model="visualizacao.especie"
                  label="Espécie"
                  rounded filled
                  color="blue "
                  dense
                  readonly
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4 class="pa-1">
              <v-text-field
                  :value ="visualizacao.data_emissao"
                  label="Data de Emissão"
                  rounded filled
                  color="blue "
                  dense
                  readonly
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4 class="pa-1">
              <v-text-field
                  v-model="visualizacao.data_vencimento"
                  label="Data de Vencimento"
                  rounded filled
                  color="blue "
                  dense
                  readonly
              ></v-text-field>
            </v-flex>
          </v-card-actions>
          <v-card-actions class="justify-end">
            <v-flex xs12 md12 class="pa-1">
              <v-text-field
                  v-model="visualizacao.motivo"
                  label="Motivo"
                  rounded filled
                  color="blue"
                  dense
                  readonly
              ></v-text-field>
            </v-flex>
          </v-card-actions>
          <v-card-actions class="justify-end">
            <v-flex xs12 md12 class="pa-1">
              <v-textarea
                  rounded filled
                  v-model="visualizacao.motivo_observacao"
                  clearable
                  readonly
                  color="blue"
                  clear-icon="mdi-close-circle"
                  rows="3"
                  label="Observação"
                  value=""
              ></v-textarea>
            </v-flex>
          </v-card-actions>
          <v-divider></v-divider>
          
          <v-card-actions class="justify-end">
            <v-flex xs12 md12 class="pa-1">
            <h3>ANEXO</h3>
            <v-btn
              color="orange"
             
              :disabled = !visualizacao.path_anexo 
     
              style="color: #fff"
              @click="downloadDocumento()"
            >
            Documento
            <v-icon>mdi-download</v-icon>
          </v-btn>
            </v-flex>
          </v-card-actions>
          <v-divider></v-divider>
          
          <v-card-actions class="justify-end">
            <v-flex xs12 md12 class="pa-1">
            <h3>Coautores</h3>
             <v-data-table
           :items="visualizacao.coautores"
            :footer-props="{
                'items-per-page-text': 'Registros por página: ',
                'page-text': '{0}-{1} de {2}',
                'items-per-page-all-text': 'Listar todos',
              }"
           dense
           hide-default-footer
           >
             <template v-slot:header>
               <tr>
                 <th>Código</th>
                 <th>Nome/Razão Social</th>
                 <th>CPF/CNPJ</th>
                 <th>Observação</th>
                 <th>Ações</th>
                 
               </tr>
             </template>
             <template v-slot:item="{ item }">
               <tr>
                 <td class="text-center"> {{ item.co_autor_id }}</td>
                 <td class="text-center"> {{ item.name }}</td>
                 <td class="text-center">{{ item.cpf_cnpj}}</td>
                 <td class="text-center">{{ item.observacao}}</td>
                 <td class="text-center"><v-btn @click="detalhesCoautor(item.co_autor_id)"><v-icon>mdi-eye</v-icon></v-btn></td>
                 

               </tr>
             </template>
           </v-data-table>
            </v-flex>
          </v-card-actions>
          <v-card-actions class="justify-end">
            <v-btn color="red" text @click="$emit('close')"
            >Fechar</v-btn>
          </v-card-actions>
        </v-card>
        <v-dialog v-model="dialogCoautor" >
          <v-card>

           <v-card-title>Dados do Coobrigado</v-card-title>
            <v-card-actions>
              <v-row>
              <v-flex xs6 md6 class="pa-1">
                <v-text-field
                    v-model="coautor.name"
                    label="Nome"
                    placeholder="Nome"
                    rounded filled
                    color="blue "
                    readonly
                    dense
                ></v-text-field>
              </v-flex>
              <v-flex xs4 md4 class="pa-1">
                <v-text-field
                    v-model="coautor.cpf_cnpj"
                    label="CNPJ/CPF"
                    placeholder="cpf_cnpj"
                    rounded filled
                    color="blue "
                    readonly
                    dense
                ></v-text-field>
              </v-flex>
              <v-flex xs2 md2 class="pa-1">
                <v-text-field
                    v-model="coautor.data_nascimento"
                    label="Data de Nascimento/Abertura Empresa"
                    placeholder="data_nascimento"
                    rounded filled
                    color="blue "
                    readonly
                    dense
                ></v-text-field>
              </v-flex>
              <v-flex xs2 md2 class="pa-1">
                <v-text-field
                    v-model="coautor.data_nascimento"
                    label="RG"
                    placeholder="rg"
                    rounded filled
                    color="blue "
                    readonly
                    dense
                ></v-text-field>
              </v-flex>
              <v-flex xs4 md4 class="pa-1">
                <v-text-field
                    v-model="coautor.email"
                    label="E-mail"
                    placeholder="rg"
                    rounded filled
                    color="blue "
                    readonly
                    dense
                ></v-text-field>
              </v-flex>
              <v-flex xs4 md4 class="pa-1">
                <v-text-field
                    v-model="coautor.observacao"
                    label="Observação"
                    placeholder="rg"
                    rounded filled
                    color="blue "
                    readonly
                    dense
                ></v-text-field>
              </v-flex>
            </v-row>
          </v-card-actions>
           <v-divider></v-divider>
          <v-card-actions>
       
            <v-card-text>
                <h3>Endereços:</h3>
              <v-data-table
           :items="coautor.enderecos"
           dense
           hide-default-footer
           >
             <template v-slot:header>
               <tr>
                 <th>Endereço</th>
                 <th>Número</th>
                 <th>Bairro</th>
                 <th>Cep</th>
                 <th>Município</th>
                 <th>UF</th>
                 
               </tr>
             </template>
             <template v-slot:item="{ item }">
               <tr>
                 <td class="text-center"> {{ item.endereco }}</td>
                 <td class="text-center"> {{ item.numero }}</td>
                 <td class="text-center"> {{ item.bairro }}</td>
                 <td class="text-center"> {{ item.cep }}</td>
                 <td class="text-center"> {{ item.municipio }}</td>
                 <td class="text-center"> {{ item.uf }}</td>               
               </tr>
             </template>
           </v-data-table>
            </v-card-text>
          </v-card-actions>
           <v-divider></v-divider>
           <v-card-actions>
       
            <v-card-text>
                <h3>Telefones:</h3>
              <v-data-table
           :items="coautor.telefones"
           dense
           hide-default-footer
           >
             <template v-slot:item="{ item }">
               <tr>
                 <td class="text-left"> {{ item.telefone }}</td>
                
               </tr>
             </template>
           </v-data-table>
            </v-card-text>
          </v-card-actions>
          <v-card-actions class="justify-end">
            <v-btn color="red" text @click="  dialogCoautor =false"
            >Fechar</v-btn>
            
          </v-card-actions>
          </v-card>
        </v-dialog>
        
      </template>
      
    </v-dialog>
  

</template>

<script>
import helper from "../mixins/helper";

export default {
  name: "HistoryComponent",
  mixins: [helper],
  props:{
    visualizacao:{
      default:{}
    },
    dialog_history:{
      default:false
    }

  },

  data: () => ({
    dialogCoautor :false,
    coautor:{},
  }),
  methods:{
    close(){
      this.dialog_history = false;
    },

    emitclick() {
      this.dialog_history = !this.dialog_history
      this.$emit("emit-dialog_history", !this.dialog_history);

    },
  
  downloadDocumento(){
    //path_anexo
         const file = this.visualizacao.path_anexo;
     
            if(file){
            this.$http.get("anexoprotocolo?documento="+file, {
                responseType: "blob"

            }).then(
                (response) => {
                   console.log(response.headers.map['content-type'])
                let blob = new Blob([response.data],  {type: response.headers.map['content-type']}),
                url = window.URL.createObjectURL(blob)

                window.open(url) // 
                },
                (response) => {
                    console.log(response);
                }
            );
            }else{
              alert('Documento inexistente')
               // this.notificacao("","O documento não existe em nossa base de dados");
            }
  },

   async detalhesCoautor(id){
      const { data: result } = await this.$http.get(
          `devedor/${id}`,  
        
        );
      this.dialogCoautor=true;
      this.coautor = result;
      console.log(result)
    }
  }
}
</script>

<style scoped>
.v-text-field.v-text-field:not(.v-text-field-flat, .v-data-footer__select * ) > .v-input__control > .v-input__slot {
    box-shadow: inset 0px 3px 6px #00000029;
    height: 50px;
    color: #2778c4;
    background-color: #CEE2EF;
}
</style>