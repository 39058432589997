<template>
  <v-container class="mx_h">
    <v-layout row wrap justify-space-around fill-height>
      <v-row class="mt-6">
        <v-flex xs12 md9 class="pa-1 pl-11">
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify "
            label="Acordo"
            dense
            single-line
            rounded
            filled
            background-color="white"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md3 class="d-flex justify-end pr-5 pt-1">
          <v-btn
            elevation="2"
            class="orange darken-1 mr-1"
            color="white--text "
            @click="abrirModalNovo"
          >
            <v-icon>mdi-plus</v-icon>
            Novo
          </v-btn>
        </v-flex>
      </v-row>

      <!-- TABELA -->
      <v-flex xs12 md12 class="pa-1">
        <v-card class="mx_h" elevation="0">
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="list"
              item-key="lote"
              :single-expand="true"
              :search="search"
              show-expand
              class="elevation-0"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td></td>
                  <td>{{ item.nome_regra }}</td>
                  <td class="text-res">{{ item.descricao }}</td>
                  <td>{{ converterDataTimestamp(item.data_vigiencia) }}</td>
                  <td>
                    {{ converterDataTimestamp(item.data_final_vigiencia) }}
                  </td>

                  <td class="text-center">
                    <v-btn
                      elevation="0"
                      small
                      icon
                      @click="abrirModalVer(item)"
                    >
                      <v-icon dark> mdi-card-bulleted-settings-outline</v-icon>
                    </v-btn>
                    <v-btn
                      elevation="0"
                      small
                      icon
                      @click="abrirModalEditar(item)"
                    >
                      <v-icon dark> mdi-square-edit-outline</v-icon>
                    </v-btn>

                    <v-btn
                      elevation="0"
                      small
                      icon
                      @click="abrirModalInativar(item)"
                    >
                      <v-icon dark> mdi-delete-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
              <v-alert slot="no-results" :value="true">
                Sua busca por "{{ search }}" não encontrou resultados.
              </v-alert>
              <v-alert slot="no-data" :value="true"> Sem dados </v-alert>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="900px">
        <v-card>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-card-title>
              {{ modal.title }}
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="7" sm="7" md="7">
                    <v-text-field
                      label="Nome da campanha"
                      required
                      :rules="vazio"
                      v-bind:readonly="isReadOnly"
                      rounded
                      filled
                      background-color="white"
                      dense
                      color="orange darken-1"
                      v-model="hand.nome_regra"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4" sm="4" md="4">
                    <data-component
                      v-model="hand.data_vigiencia"
                      labelDate="Início Vigência"
                      :minimodata="dataHoje"
                      only-date
                      color="orange darken-1"
                      :datas="hand.data_vigiencia"
                      rounded
                      filled
                      background-color="white"
                    ></data-component>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      label="Descrição da campanha"
                      v-bind:readonly="isReadOnly"
                      rounded
                      filled
                      background-color="white"
                      color="orange darken-1"
                      auto-grow
                      v-model="hand.descricao"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" sm="3" md="3" lg="3">
                    <v-checkbox
                      v-model="hand.pagamento_parcelado"
                      v-bind:readonly="isReadOnly"
                      rounded
                      filled
                      background-color="white"
                      color="orange darken-1"
                      label="Permite Parcelar"
                    ></v-checkbox>
                  </v-col>
                  <v-col sm="4" md="4" v-if="hand.pagamento_parcelado">
                    <v-text-field
                      type="number"
                      full-width
                      v-model="hand.qtd_parcelas"
                      v-bind:readonly="isReadOnly"
                      rounded
                      filled
                      dense
                      color="orange darken-1"
                      :min="0"
                      :rules="[(v) => v >= 1 || 'Numero inválido']"
                      label="Qtd. de parcelas"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" sm="2" md="3">
                    <v-text-field
                      type="number"
                      v-model="hand.desconto_avista"
                      v-bind:readonly="isReadOnly"
                      rounded
                      :min="0"
                      filled
                      dense
                      color="orange darken-1"
                      :rules="[
                        (v) => (v >= 0 && v <= 100 && !!v) || 'Número inválido',
                      ]"
                      suffix="%"
                      label="Desc.  à vista"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="4" md="3">
                    <v-text-field
                      type="number"
                      v-model="hand.porc_entrada"
                      v-bind:readonly="isReadOnly"
                      rounded
                      filled
                      dense
                      :min="0"
                      color="orange darken-1"
                      :rules="[
                        (v) => (v >= 0 && v <= 100 && !!v) || 'Valor inválido',
                      ]"
                      suffix="%"
                      label="Perc. entrada"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" sm="4" md="3">
                    <v-text-field
                      type="number"
                      v-model="hand.qtd_dias"
                      v-bind:readonly="isReadOnly"
                      rounded
                      filled
                      :min="0"
                      dense
                      color="orange darken-1"
                      :rules="[(v) => v >= 0 || 'Número inválido']"
                      label="Qtd. dias"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4" md="4">
                    <v-checkbox
                      v-model="hand.tipo_baixa"
                      v-bind:readonly="isReadOnly"
                      rounded
                      filled
                      dense
                      color="orange darken-1"
                      label="Baixa na primeira parcela"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2" sm="2" md="2">
                    <v-text-field
                      type="number"
                      v-model="hand.juros"
                      v-bind:readonly="isReadOnly"
                      rounded
                      :min="0"
                      filled
                      dense
                      color="orange darken-1"
                      :rules="[
                        (v) => (v >= 0 && v <= 100 && !!v) || 'Número inválido',
                      ]"
                      label="Juros"
                      suffix="%"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" sm="2" md="2">
                    <v-text-field
                      type="number"
                      v-model="hand.multa"
                      v-bind:readonly="isReadOnly"
                      rounded
                      filled
                      dense
                      :min="0"
                      color="orange darken-1"
                      :rules="[
                        (v) => (v >= 0 && v <= 100 && !!v) || 'Número inválido',
                      ]"
                      label="Multa"
                      suffix="%"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="2" sm="2" md="4">
                    <v-text-field
                      type="number"
                      v-model="hand.indice_monetaria"
                      v-bind:readonly="isReadOnly"
                      rounded
                      filled
                         :min="0"
                      dense
                      color="orange darken-1"
                      :rules="[
                        (v) => (v >= 0 && v <= 100 && !!v) || 'Número inválido',
                      ]"
                      label="Indice Monetatio"
                      suffix="%"
                    ></v-text-field>
                  </v-col> -->
                  <v-col cols="2" sm="2" md="4">
                    <v-select
                      v-bind:items="indices_monetarios"
                      v-model="hand.indicemonetario_id"
                      label="Índice Monetário"
                      rounded
                      filled
                      dense
                      item-value="id"
                    >
                      <template slot="item" scope="data">
                        {{ data.item.descricao }}
                      </template>
                      <template
                        slot="selection"
                        scope="data"
                        class="color-yellow-blue"
                      >
                        {{ data.item.descricao }}
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="2" sm="2" md="4">
                    <!--<v-text-field
                      type="number"
                      v-model="hand.outros"
                      :rules="[(v) => (v >= 0 && !!v) || 'Número inválido']"
                      v-bind:readonly="isReadOnly"
                      rounded
                      filled
                      :min="0"
                      dense
                      color="orange darken-1"
                      label="Valor outros"
                    ></v-text-field>-->
                    <v-text-field-money
                       v-bind:readonly="isReadOnly"
                  
                      v-model="hand.outros"
                            label="Valor Outros "
                            v-bind:properties="{
                              prefix: 'R$',

                              filled: true,
                              rounded: true,
                              dense: true,

                              clearable: true,
                              placeholder: ' ',
                              rules: [(v) => !!v || 'Este item é obrigatório'],

                              dense: true,
                              color: 'blue darken-4',
                            }"
                            v-bind:options="{
                              locale: 'pt-BR',
                              length: 11,
                              precision: 2,
                              empty: null,
                            }"
                          ></v-text-field-money>
                  </v-col>
                  <v-col cols="8" sm="8" md="8">
                    <v-text-field
                      label="Descrição outros"
                      v-bind:readonly="isReadOnly"
                      rounded
                      filled
                      dense
                      color="orange darken-1"
                      auto-grow
                      v-model="hand.descricao_outros"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4" sm="4" md="4">
                    <data-component
                      v-model="hand.data_final_vigiencia"
                      labelDate="Data final Vigência"
                      :minimodata="dataHoje"
                      only-date
                      rounded
                      filled
                      :datas="hand.data_final_vigiencia"
                      @emit-data="getDataFinal"
                    ></data-component>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="orange darken-1" text loading v-if="carregando">
                Salvar
              </v-btn>
              <v-btn
                color="orange darken-1"
                text
                v-if="!carregando && !isReadOnly"
                :disabled="!valid"
                @click="validate"
              >
                Salvar
              </v-btn>
              <v-btn
                color="orange darken-1 "
                text
                v-if="isReadOnly"
                :disabled="!valid"
                @click="isReadOnly = false"
              >
                Editar
              </v-btn>
              <v-btn color="orange darken-1 " text @click="dialog = false">
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="modalDelete" persistent max-width="400px">
        <v-card>
          <v-form v-on:submit.prevent="inativarConf">
            <v-card-title>
              <span class="text-h5"
                >Excluir configuração: {{ hand.nome_regra }}</span
              >
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text loading v-if="carregando">
                Excluir
              </v-btn>
              <v-btn color="red darken-1" text v-if="!carregando" type="submit">
                Excluir
              </v-btn>
              <v-btn color=" darken-1" text @click="modalDelete = false">
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import helper from "../../../mixins/helper";
import DataComponent from "../../../components/DataComponent";

export default {
  components: { DataComponent },
  mixins: [helper],
  data: () => ({
    dataHoje: null,
    dialog: false,
    modalDelete: false,
    carregando: false,
    valid: false,
    modal: {
      title: null,
      type: null,
    },
    isReadOnly: false,
    menu: false,
    vazio: [(v) => !!v || "O campo não pode estar vazio"],
    hand: {
      nome_regra: null,
      empresa_id: null,
      data_vigiencia: null,
      descricao: null,
      pagamento_parcelado: null,
      qtd_parcelas: null,
      desconto_avista: null,
      porc_entrada: null,
      qtd_dias: null,
      tipo_baixa: null,
      juros: null,
      indicemonetario_id: null,
      multa: null,
      indice_monetaria: null,
      type_valor_honorario: null,
      valor_honorario: null,
      type_outros: null,
      outros: null,
      descricao_outros: null,
      data_final_vigiencia: null,
    },
    list: [],
    indices_monetarios: [],
    search: "",
    headers: [
      { text: "Nome", value: "nome_regra", align: "start" },
      { text: "Descrição", value: "descricao", align: "start" },
      {
        text: "Data Inicial",
        width: "12%",
        value: "data_vigiencia",
        align: "start",
      },
      {
        text: "Data Final",
        width: "12%",
        value: "data_final_vigiencia",
        align: "start",
      },
      { text: "Ações", width: "15%", align: "center", value: "" },
    ],
    type: [
      { name: "Porcentagem", value: 0 },
      { name: "Dinheiro", value: 1 },
    ],
  }),
  created() {},
  mounted() {
    this.dataHoje = new Date().toISOString();
    this.dataHoje = this.dataHoje.split("T");
    this.dataHoje = this.dataHoje[0];
    this.getIndicesMonetarios()
    this.getAcordos();
  },

  methods: {
    notificacao(title, msg, icon) {
      this.$swal({
        toast: true,
        position: "top-center",
        showConfirmButton: false,
        timer: 3000,
        icon: icon,
        title: title,
        text: msg,
      });
    },

    limparForm(){
      this.hand= {
      nome_regra: null,
      empresa_id: null,
      data_vigiencia: null,
      descricao: null,
      pagamento_parcelado: null,
      qtd_parcelas: null,
      desconto_avista: null,
      porc_entrada: null,
      qtd_dias: null,
      tipo_baixa: null,
      juros: null,
      indicemonetario_id: null,
      multa: null,
      indice_monetaria: null,
      type_valor_honorario: null,
      valor_honorario: null,
      type_outros: null,
      outros: null,
      descricao_outros: null,
      data_final_vigiencia: null,
    }
    },
    getIndicesMonetarios() {
      this.$http.get("indicess_monetarios").then(
        (response) => {
          console.log(response);
          this.indices_monetarios = response.data;
        },
        (response) => {
          console.log(response);
        }
      );
    },

    getAcordos() {
      this.$http.get("AcordoCrud").then(
        (response) => {
          this.list = response.data;
        },
        (response) => {
          console.log(response);
        }
      );
    },

    validate() {
      if (this.$refs.form.validate()) {
        if (this.modal.type === "novo") {
          this.carregando = true;
          this.criarAcordo();
        } else if (this.modal.type === "editar") {
          this.carregando = true;
          this.editarAcordo();
        }
      }
    },
    abrirModalVer(item) {
      console.log(item)
      this.limparForm();
      this.modal.title = "Configuração do acordo";
      this.modal.type = "editar";
      (this.hand.id = item.id),
        (this.hand.nome_regra = item.nome_regra),
        (this.hand.data_vigiencia = item.data_vigiencia),
        (this.hand.descricao = item.descricao),
        (this.hand.pagamento_parcelado = item.pagamento_parcelado),
        (this.hand.qtd_parcelas = item.qtd_parcelas),
        (this.hand.desconto_avista = item.desconto_avista),
        (this.hand.porc_entrada = item.porc_entrada),
        (this.hand.qtd_dias = item.qtd_dias),
        (this.hand.tipo_baixa = item.tipo_baixa),
        (this.hand.juros = item.juros),
        (this.hand.multa = item.multa),
        (this.hand.indicemonetario_id = item.indicemonetario_id),//indicemonetario_id: null,
        
        (this.hand.indice_monetaria = item.indice_monetaria),
        (this.hand.type_valor_honorario = item.type_valor_honorario),
        (this.hand.valor_honorario = item.valor_honorario),
        (this.hand.type_outros = item.type_outros),
        (this.hand.outros = item.outros),
        (this.hand.descricao_outros = item.descricao_outros),
        (this.hand.data_final_vigiencia = item.data_final_vigiencia);
      this.carregando = false;
      this.isReadOnly = true;
      this.dialog = true;
    },
    abrirModalNovo() {
      this.limparForm();
      this.modal.title = "Nova Configuração de acordo";
      this.modal.type = "novo";
      this.carregando = false;
      this.isReadOnly = false;
      this.dialog = true;
     // this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    abrirModalEditar(item) {
      this.limparForm();
      this.modal.title = "Configuração do acordo";
      this.modal.type = "editar";
      (this.hand.id = item.id),
        (this.hand.nome_regra = item.nome_regra),
        (this.hand.data_vigiencia = item.data_vigiencia),
        (this.hand.descricao = item.descricao),
        (this.hand.pagamento_parcelado = item.pagamento_parcelado),
        (this.hand.qtd_parcelas = item.qtd_parcelas),
        (this.hand.desconto_avista = item.desconto_avista),
        (this.hand.porc_entrada = item.porc_entrada),
        (this.hand.qtd_dias = item.qtd_dias),
        (this.hand.tipo_baixa = item.tipo_baixa),
        (this.hand.juros = item.juros),
        (this.hand.multa = item.multa),
        (this.hand.indice_monetaria = item.indice_monetaria),
        (this.hand.indicemonetario_id = item.indicemonetario_id),
        (this.hand.type_valor_honorario = item.type_valor_honorario),
        (this.hand.valor_honorario = item.valor_honorario),
        (this.hand.type_outros = item.type_outros),
        (this.hand.outros = item.outros),
        (this.hand.descricao_outros = item.descricao_outros),
        (this.hand.data_final_vigiencia = item.data_final_vigiencia);

      this.carregando = false;
      this.isReadOnly = false;
      this.dialog = true;
    },
    abrirModalInativar(data) {
      this.hand.id = data.id;
      this.hand.nome_regra = data.nome_regra;
      this.carregando = false;
      this.modalDelete = true;
    },
    criarAcordo() {
      this.$http
        .post("AcordoCrud", {
          nome_regra: this.hand.nome_regra,
          data_vigiencia: this.hand.data_vigiencia,
          descricao: this.hand.descricao,
          pagamento_parcelado: this.hand.pagamento_parcelado ? "1" : "0",
          qtd_parcelas: this.hand.qtd_parcelas,
          desconto_avista: this.hand.desconto_avista,
          porc_entrada: this.hand.porc_entrada,
          qtd_dias: this.hand.qtd_dias,
          tipo_baixa: this.hand.tipo_baixa,
          juros: this.hand.juros,
          multa: this.hand.multa,
          indice_monetaria: this.hand.indice_monetaria,
          indicemonetario_id: this.hand.indicemonetario_id,
          type_valor_honorario: this.hand.type_valor_honorario ? "1" : "0",
          valor_honorario: this.hand.valor_honorario,
          type_outros: this.hand.type_outros ? "1" : "0",
          outros: this.hand.outros,
          descricao_outros: this.hand.descricao_outros,
          data_final_vigiencia: this.hand.data_final_vigiencia,
          is_stone: 1,
        })
        .then(
          (response) => {
            console.log(response)
            this.getAcordos();
            this.dialog = false;
            //this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.notificacao("", response.body.message, "success");
          },
          (response) => {
            console.log(response)
            this.dialog = false;
          //  this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.notificacao("", response.body.message, "error");
          }
        );
    },
    editarAcordo() {
      this.$http
        .put("AcordoCrud/" + this.hand.id, {
          nome_regra: this.hand.nome_regra,
          data_vigiencia: this.hand.data_vigiencia,
          descricao: this.hand.descricao,
          pagamento_parcelado: this.hand.pagamento_parcelado ? "1" : "0",
          qtd_parcelas: this.hand.qtd_parcelas,
          desconto_avista: this.hand.desconto_avista,
          porc_entrada: this.hand.porc_entrada,
          qtd_dias: this.hand.qtd_dias,
          tipo_baixa: this.hand.tipo_baixa,
          juros: this.hand.juros,
          multa: this.hand.multa,
          indice_monetaria: this.hand.indice_monetaria,
          indicemonetario_id: this.hand.indicemonetario_id,
          type_valor_honorario: this.hand.type_valor_honorario ? "1" : "0",
          valor_honorario: this.hand.valor_honorario,
          type_outros: this.hand.type_outros ? "1" : "0",
          outros: this.hand.outros,
          descricao_outros: this.hand.descricao_outros,
          data_final_vigiencia: this.hand.data_final_vigiencia,
        })
        .then(
          (response) => {
            this.getAcordos();
            this.dialog = false;
           // this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.notificacao("", response.body.message, "success");
          },
          (response) => {
            this.dialog = false;
            //this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.notificacao("", response.body.message, "error");
          }
        );
    },
    inativarConf() {
      this.carregando = true;
      this.$http.delete("AcordoCrud/" + this.hand.id).then(
        (response) => {
          this.getAcordos();
          this.modalDelete = false;
          this.notificacao("", response.body.message, "success");
        },
        (response) => {
          this.modalDelete = false;
          this.notificacao("", response.body.message, "error");
        }
      );
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    getVigencia(data) {
      this.hand.data_vigiencia = data;
    },
    getDataFinal(data) {
      this.hand.data_final_vigiencia = data;
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "DAY"));
    },
  },
};
</script>

<style>
.degrade {
  background: #f12711;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f5af19, #f12711);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f5af19, #f12711);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

td {
  font-weight: bold !important;
  color: #000 !important;
}

.mx_h {
  height: 24%;
  margin-left: 0 !important;
}

.degrade-titulo-02 {
  background: #00b7ff;
  background: linear-gradient(
    90deg,
    #ac0042 0%,
    rgba(255, 255, 255, 0.3477766106442577) 43%,
    rgba(255, 255, 255, 0) 100%
  );
}

.table-secundary {
  border-bottom: solid #00000021;
  border-left: solid #00000021;
  border-right: solid #00000021;
  background-color: #eeeeee;
}

.text-res {
  max-width: 40ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
