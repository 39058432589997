<template>
  <div>
    <v-container class="v-container">
      <v-row class="mt-6" id="printarea">
        <v-flex col="8" xs8 md18 class="pa-1 pl-11">
          <h5>Protocolo de Consulta: {{ id }}</h5>
          <h5>Operador: {{ empresaDescricao }}</h5>
        </v-flex>
        <v-flex col="4" xs4 md4 class="pa-1">
          <h5>
            Data/Hora Criação:
            {{
              moment(detalhesConsulta.created_at).format("DD/MM/YYYY HH:mm:ss")
            }}
          </h5>
        </v-flex>
        <v-flex col="12" xs12 md12 class="pa-1 pl-11">
          <v-card class="">
            <v-flex col="12" xs12 md12 class="pa-5">
              <h3 style="color: red">INFORMAÇÃO CONFIDENCIAL.</h3>
            </v-flex>
            <v-flex col="12" xs12 md12 class="pa-5">
              <p>
                Uso exclusivo da empresa associada para auxílio na aprovação de
                crédito. A divulgação de tais informações a terceiros sujeitará
                o infrator às sanções penais.
              </p>
            </v-flex>
          </v-card>
        </v-flex>

        <v-flex col="12" xs12 md12 class="pa-1 pl-11">
          <v-card class="">
            <v-flex col="12" xs12 md12 class="pa-5">
              <h3>Dados do consumidor</h3>
            </v-flex>
            <v-flex col="12" xs12 md12 class="pa-5">
              <p>
                Nome/Razão Social:
                <b>{{ detalhesConsulta.consulta.usuario.name }}</b>
              </p>
              <p>
                CPF/CNPJ: <b>{{ formatarCpfCnpj(detalhesConsulta.cpf_cnpj_consumidor) }}</b>
              </p>
              <p>
                Nascimento/Criação Empresa:<!-- {{ detalhesConsulta.consulta.usuario.data_nascimento }}-->
              </p>
              <p v-if="detalhesConsulta.usuario_monitora == '1'">
                <b>Dados fornecidos pelo consumidor via plataforma STONE.</b>
              </p>
            </v-flex>
          </v-card>
        </v-flex>

        <v-flex col="12" xs12 md12 class="pa-1 pl-11">
          <v-card class="">
            <v-flex col="12" xs12 md12 class="pa-5">
              <h3>Resumo Financeiro</h3>
            </v-flex>
            <v-flex col="12" xs12 md12 class="pa-5">
              <v-data-table :items="resumo" :footer-props="{
                'items-per-page-text': 'Registros por página: ',
                'page-text': '{0}-{1} de {2}',
                'items-per-page-all-text': 'Listar todos',
              }" :hide-default-footer="true" :headers="[
                  { text: 'Origem' },
                  { text: 'Qtd.Registros' },
                  { text: 'Valor Total' },
                ]" item-key="lote" :single-expand="true" show-expand class="elevation-0">
                <template v-slot:item="{ item }">
                  <tr>
                    <td></td>
                    <td>{{ item.detalhe }}</td>
                    <td>{{ item.qtd }}</td>
                    <td>{{ converterDinherio(item.valor) }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-flex>
          </v-card>
        </v-flex>

        <v-flex col="12" xs12 md12 class="pa-1 pl-11">
          <v-card class="">
            <v-flex col="12" xs12 md12 class="pa-5">
              <h3>Telefones do consumidor</h3>
            </v-flex>
            <v-flex col="12" xs12 md12 class="pa-5">
              <v-data-table :headers="[{ text: 'Telefone' }]" :footer-props="{
                'items-per-page-text': 'Registros por página: ',
                'page-text': '{0}-{1} de {2}',
                'items-per-page-all-text': 'Listar todos',
              }" :items="detalhesConsulta.consulta.telefones" :hide-default-footer="true" item-key="lote"
                :single-expand="true" show-expand class="elevation-0">
                <template v-slot:item="{ item }">
                  <tr>
                    <td></td>
                    <td>{{ item.telefone }}</td>
                  </tr>
                </template>
                <v-alert slot="no-data" :value="true">Não possui</v-alert>
              </v-data-table>
            </v-flex>
          </v-card>
        </v-flex>
        <v-flex col="12" xs12 md12 class="pa-1 pl-11">
          <v-card class="">
            <v-flex col="12" xs12 md12 class="pa-5">
              <h3>Registro de negativações</h3>
            </v-flex>
            <v-flex col="12" xs12 md12 class="pa-5">
              <v-data-table :footer-props="{
                'items-per-page-text': 'Registros por página: ',
                'page-text': '{0}-{1} de {2}',
                'items-per-page-all-text': 'Listar todos',
              }" :headers="[
                  { text: 'Credor' },
                  { text: 'Origem' },
                  { text: 'Data Emissão' },
                  { text: 'Data Vencimento ' },
                  { text: 'Documento' },
                  { text: 'Valor' },
                ]" :items="detalhesConsulta.consulta.negativacoes" item-key="lote" :single-expand="true" show-expand
                class="elevation-0" :hide-default-footer="true">
                <template v-slot:item="{ item }">
                  <tr>
                    <td></td>
                    <td>{{ item.name }}</td>
                    <td>MANAUS/AM</td>
                    <td>{{ converterDataTimestamp(item.data_emissao) }}</td>
                    <td>{{ converterDataTimestamp(item.data_vencimento) }}</td>
                    <td>{{ item.numero_titulo }}</td>
                    <td>{{ converterDinherio(item.valor_original) }}</td>
                  </tr>
                </template>
                <v-alert slot="no-data" :value="true">Nada consta</v-alert>
              </v-data-table>
            </v-flex>
          </v-card>
        </v-flex>
        <v-flex col="12" xs12 md12 class="pa-1 pl-11">
          <v-card class="">
            <v-flex col="12" xs12 md12 class="pa-5">
              <h3>Dívidas PGFN</h3>
            </v-flex>
            <v-flex col="12" xs12 md12 class="pa-5">
              <v-data-table dense :items="detalhesConsulta.consulta.lista_pgfn" :hide-default-footer="true" :headers="[
                { text: 'codigoComprot' },
                { text: 'codigoSida' },
                { text: 'codigoTipoSituacao' },
                { text: 'codigoUorg' },
                { text: 'descricaoTipoSituacao' },
                { text: 'nomeUnidade' },
                { text: 'numeroInscricao' },
                { text: 'numeroJuizo' },
                { text: 'numeroProcesso' },
                { text: 'situacaoDescricao' },
                { text: 'situacaoInscricao' },
                { text: 'tipoDevedor' },
                { text: 'tipoRegularidade' },
                { text: 'valorTotalConsolidadoMoeda' },
              ]" item-key="lote" :single-expand="true" :search="search" show-expand class="elevation-0">
                <template v-slot:item="{ item }">
                  <tr>
                    <td></td>

                    <td>
                      {{ item.codigoComprot }}
                    </td>
                    <td>{{ item.codigoSida }}</td>
                    <td>{{ item.codigoTipoSituacao }}</td>
                    <td>{{ item.codigoUorg }}</td>
                    <td>{{ item.descricaoTipoSituacao }}</td>
                    <td>{{ item.nomeUnidade }}</td>
                    <td>{{ item.numeroInscricao }}</td>
                    <td>{{ item.numeroJuizo }}</td>

                    <td>{{ item.numeroProcesso }}</td>
                    <td>{{ item.situacaoDescricao }}</td>
                    <td>{{ item.situacaoInscricao }}</td>
                    <td>{{ item.tipoDevedor }}</td>
                    <td>{{ item.tipoRegularidade }}</td>
                    <td>{{ item.valorTotalConsolidadoMoeda }}</td>
                  </tr>
                </template>

                <v-alert slot="no-data" :value="true">Nada consta</v-alert>
              </v-data-table>
            </v-flex>
          </v-card>
        </v-flex>

        <v-flex col="12" xs12 md12 class="pa-1 pl-11">
          <v-card class="">
            <v-flex col="12" xs12 md12 class="pa-5">
              <h3>Consultas efetuadas</h3>
            </v-flex>
            <v-flex col="12" xs12 md12 class="pa-5">
              <v-data-table :footer-props="{
                'items-per-page-text': 'Registros por página: ',
                'page-text': '{0}-{1} de {2}',
                'items-per-page-all-text': 'Listar todos',
              }" :headers="[
                  { text: 'Data' },
                  { text: 'Empresa' },
                  { text: 'Origem' },
                ]" :items="detalhesConsulta.consulta.consultas" item-key="lote" :single-expand="true" show-expand
                class="elevation-0" :hide-default-footer="true">
                <template v-slot:item="{ item }">
                  <tr>
                    <td></td>
                    <td>{{ moment(item.created_at).format("DD/MM/YYYY") }}</td>
                    <td>{{ item.name }}</td>

                    <td>MANAUS/AM</td>
                  </tr>
                </template>
                <v-alert slot="no-data" :value="true">Não possui</v-alert>
              </v-data-table>
            </v-flex>
          </v-card>
        </v-flex>


      </v-row>
      <v-flex col="12 xs12 md12" class="d-flex justify-center ma-7">
        <v-btn type="button" color="primary" v-print="'#printarea'"><v-icon class="pr-2">mdi-printer</v-icon>Imprimir Relatório</v-btn>
      </v-flex>
    </v-container>
  </div>
</template>

<script>
import helper from "../../mixins/helper";

/*import { Printd } from "printd";*/
export default {
  components: {},
  mixins: [helper],
  data: () => ({
    id: null,

    detalhesConsulta: {},
    resumo: [],
    empresaDescricao: "",
  }),
  created() { },
  mounted() {
    this.id = this.$route.params.id;
    this.buscarDetalhes();
    this.empresaDescricao =
      this.$store.state.auth.user.domain.name +
      "  - " +
      this.$store.state.auth.user.domain.cpf_cnpj;
  },

  methods: {

    buscarDetalhes() {
      this.$http.get(`detalhesConsulta/${this.id}`).then(
        (response) => {
          console.log(response);
          this.detalhesConsulta = response.data;
          var qtdREgistros = 0;
          var vlrRegistrosStone = 0;
          var pgfn_qt = 0;
          var pgfn_vlr = 0;
          this.detalhesConsulta.consulta.negativacoes.map(function (i) {
            qtdREgistros++;
            vlrRegistrosStone += parseFloat(i.valor_original);
          });

          this.detalhesConsulta.consulta.lista_pgfn.map(function (i) {
            pgfn_qt++;
            pgfn_vlr += parseFloat(i.valorTotalConsolidadoMoeda);
          });

          this.resumo.push({
            detalhe: "Negativações Stone",
            qtd: qtdREgistros,
            valor: vlrRegistrosStone,
          });
          this.resumo.push({
            detalhe: "Registros PGFN",
            qtd: pgfn_qt,
            valor: pgfn_vlr,
          });
        },
        (response) => {
          console.log(response);
          // error callback
        }
      );
    },
  },
  watch: {},
};
</script>

<style>
.degrade {
  background: #f12711;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f5af19, #f12711);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f5af19, #f12711);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.rounded-card {
  border-radius: 50px;
  border-style: dashed;
}

td {
  font-weight: bold !important;
  color: #000 !important;
}

.mx_h {
  height: 24%;
  margin-left: 0 !important;
}

.degrade-titulo-02 {
  background: #00b7ff;
  background: linear-gradient(90deg,
      #ac0042 0%,
      rgba(255, 255, 255, 0.3477766106442577) 43%,
      rgba(255, 255, 255, 0) 100%);
}

.table-secundary {
  border-bottom: solid #00000021;
  border-left: solid #00000021;
  border-right: solid #00000021;
  background-color: #eeeeee;
}

.text-res {
  max-width: 40ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
