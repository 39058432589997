<template>
<v-container class="mx_h">
    <v-layout row wrap justify-space-around fill-height>
        <v-row class="mt-6">

            <v-flex xs12 md10 class="pa-1 pl-11">
                <v-text-field v-model="search" prepend-icon="mdi-magnify " label="Permissão" dense single-line color="blue" outlined></v-text-field>
            </v-flex>

            <v-flex xs12 md2 class="d-flex justify-end pr-5 pt-1">
                <v-btn elevation="2" class="primary mr-1" color="white--text " @click="abrirModalNovo">
                    <v-icon>mdi-plus</v-icon>
                    Nova Permissão
                </v-btn>
            </v-flex>
        </v-row>

        <!-- TABELA -->
        <v-flex xs12 md12 class="pa-1">
            <v-card class="mx_h" elevation="0">
                <v-card-text>
                    <v-data-table :headers="headers" :items="list" item-key="lote" :single-expand="true" :search="search" show-expand class="elevation-0">
                        <template v-slot:item="{ item }">
                            <tr>
                                <td></td>
                                <td>{{ item.id }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.label }}</td>
                                

                                <td class="text-center">
                                    <v-btn elevation="0" small icon @click="abrirModalEditar(item)">
                                        <v-icon dark> mdi-square-edit-outline</v-icon>
                                    </v-btn>

                                    <v-btn elevation="0" small icon @click="abrirModalInativar(item)">
                                        <v-icon dark> mdi-delete-outline</v-icon>
                                    </v-btn>

                                </td>
                            </tr>
                        </template>
                        <v-alert slot="no-results" :value="true">
                            Sua busca por "{{ search }}" não encontrou resultados.
                        </v-alert>
                        <v-alert slot="no-data" :value="true">
                            Sem dados
                        </v-alert>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>

    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="900px">
            <v-card>
                <v-form v-model="valid" ref="form" lazy-validation>
                    <v-card-title class="ml-3">
                        <span class="text-h5">{{modal.title}}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="4" sm="4" md="4">
                                    <v-text-field label="Nome" required outlined dense :rules="vazio" v-model="hand.name"></v-text-field>
                                </v-col>
                                <v-col cols="8" sm="8" md="8">
                                    <v-text-field label="Label" required  outlined dense :rules="vazio" v-model="hand.label"></v-text-field>
                                </v-col>

                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text loading v-if="carregando">
                            Salvar
                        </v-btn>
                        <v-btn color="blue darken-1" text v-if="!carregando" :disabled="!valid" @click="validate">
                            Salvar
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="dialog = false">
                            Cancelar
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-row>

    <v-row justify="center">
        <v-dialog v-model="modalDelete" persistent max-width="400px">
            <v-card>
                <v-form v-on:submit.prevent="inativaPermissao">
                    <v-card-title>
                        <span class="text-h5">Excluir permissão:<br> {{ hand.name }}</span>
                    </v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red darken-1" text loading v-if="carregando">
                            Excluir
                        </v-btn>
                        <v-btn color="red darken-1" text v-if="!carregando" type="submit">
                            Excluir
                        </v-btn>
                        <v-btn color=" darken-1" text @click="modalDelete= false">
                            Cancelar
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-row>

</v-container>
</template>

<script>
import helper from '../../../mixins/helper'

export default {
    components: {},
    mixins: [helper],
    data: () => ({
        dialog: false,
        modalDelete: false,
        carregando: false,
        valid: false,
        modal: {
            title: null,
            type: null,

        },
        menu: false,
        vazio: [
            v => !!v || 'O campo não pode estar vazio',
        ],
        hand: {
            id: null,
            name: null,
            label: null,

        },
        list: [],
        search: '',
        headers: [{
                text: 'id',
                value: 'id',
                width: "12%",
                align: 'start'
            },
            {
                text: 'Nome',
                width: "25%",
                value: 'name',
                align: 'start'
            },
            {
                text: 'Label',
                value: 'label',
                align: 'start'
            },
    
            {
                text: 'Ações',
                width: "15%",
                align: 'center',
                value: ''
            },
        ]
        

    }),
    mounted() {

        this.getPemissions();
    },

    methods: {
        notificacao(title, msg, icon) {
            this.$swal({
                toast: true,
                position: "top-center",
                showConfirmButton: false,
                timer: 3000,
                icon: icon,
                title: title,
                text: msg,
            });
        },

        getPemissions() {
            this.$http.get("permissionsCrud").then(
                (response) => {
                    this.list = response.data;
                },
                (response) => {
                    console.log(response);
                }
            );
        },

        validate() {
            if (this.$refs.form.validate()) {
                if (this.modal.type === 'novo') {
                    this.carregando = true;
                    this.criarPermissao()
                } else if (this.modal.type === 'editar') {
                    this.carregando = true;
                    this.editarPermissao()
                }
            }
        },
        abrirModalNovo() {
            this.modal.title = 'Nova Permissão'
            this.modal.type = 'novo'
            this.carregando = false
            this.dialog = true
            this.$refs.form.reset()
            this.$refs.form.resetValidation()

        },
        abrirModalEditar(item) {
            this.modal.title = 'Editar Permissão'
            this.modal.type = 'editar'

            this.hand.id = item.id,
            this.hand.name = item.name
            this.hand.label = item.label

            this.carregando = false
            this.dialog = true

        },
        abrirModalInativar(data) {
            this.hand.id = data.id
            this.hand.name = data.name
            this.carregando = false
            this.modalDelete = true

        },
        criarPermissao() {
            this.$http.post("permissionsCrud", {
                name: this.hand.name,
                label: this.hand.label,


            }).then(
                (response) => {
                    this.getPemissions()
                    this.dialog = false
                    this.$refs.form.reset()
                    this.$refs.form.resetValidation()
                    this.notificacao('', response.body.message, "success")
                },
                (response) => {
                    this.carregando = false
                    this.notificacao('', response.body.message, "error")
                }
            );
        },
        editarPermissao() {
            this.$http.put("permissionsCrud/" + this.hand.id, {
                name: this.hand.name,
                label: this.hand.label,

            }).then(
                (response) => {
                    this.getPemissions()
                    this.dialog = false
                    this.$refs.form.reset()
                    this.$refs.form.resetValidation()
                    this.notificacao('', response.body.message, "success")
                },
                (response) => {
                    this.carregando = false
                    this.notificacao('', response.body.message, "error")
                }
            );
        },
        inativaPermissao() {
            this.carregando = true
            this.$http.delete("permissionsCrud/" + this.hand.id).then(
                (response) => {
                    this.getPemissions()
                    this.modalDelete = false
                    this.notificacao('', response.body.message, "success")
                },
                (response) => {
                    this.modalDelete = false
                    this.notificacao('', response.body.message, "error")
                }
            );
        }
    }
}
</script>

<style>
.degrade {
    background: #f12711;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right,
            #f5af19,
            #f12711);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,
            #f5af19,
            #f12711);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

td {
    font-weight: bold !important;
    color: #000 !important;
}

.mx_h {
    height: 24%;
    margin-left: 0 !important;
}

.degrade-titulo-02 {
    background: #00b7ff;
    background: linear-gradient(90deg,
            #ac0042 0%,
            rgba(255, 255, 255, 0.3477766106442577) 43%,
            rgba(255, 255, 255, 0) 100%);
}

.table-secundary {
    border-bottom: solid #00000021;
    border-left: solid #00000021;
    border-right: solid #00000021;
    background-color: #eeeeee;
}

.text-res {
    max-width: 40ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
