<template>
  <div class="text-center">
    <v-pagination
      v-model="source.current_page"
      :length="source.last_page"
      light
     
      
      @input="next"
    ></v-pagination>
  </div>
</template>
<script>
export default {
  props: ["source"],
  data() {
    return {
      pages: [],
    };
  },
  watch: {
    source() {
     
    },
  },
  methods: {
    next(page) {
    //  console.log(page)
    this.$emit('navigate', page)
  
    },
  },
};
</script>
<style scoped>


</style>


