<template>
  <v-container class="mx_h">
    <v-layout row wrap justify-space-around fill-height>
      <v-row class="mt-6">
        <v-flex xs12 md10 class="pa-1 pl-11">
          <v-text-field v-model="search" prepend-inner-icon="mdi-magnify " label="Perfis" dense filled
            background-color="white" color="blue" rounded></v-text-field>
        </v-flex>

        <v-flex xs12 md2>
          <v-btn color="#0052A0" x-large elevation="0" @click="abrirModalNovo">
            <v-icon style="color: #ffd400; font-weight: 600; letter-spacing: 0.19px" class="mr-1">mdi-plus</v-icon>
            <span style="color: #ffd400; font-weight: 600; letter-spacing: 0.19px">Novo</span>
          </v-btn>
        </v-flex>
      </v-row>

      <!-- TABELA -->
      <v-flex xs12 md12 class="pa-1">
        <v-card class="mx_h" elevation="0">
          <v-card-text>
            <v-data-table :headers="headers" :items="list" item-key="lote" :single-expand="true" :search="search"
              show-expand class="elevation-0">
              <template v-slot:item="{ item }">
                <tr>
                  <td></td>

                  <td>{{ item.name }}</td>
                  <td>{{ item.label }}</td>

                  <td class="text-center">
                    <v-btn elevation="0" small :disabled="item.name == 'Super_Admin'" icon :to="{
                      name: 'Permissões para o perfil',
                      params: { id: item.id, name: item.name },
                    }">
                      <v-icon dark> mdi-account-cancel</v-icon>
                    </v-btn>

                    <v-btn elevation="0" small icon @click="abrirModalEditar(item)">
                      <v-icon dark> mdi-square-edit-outline</v-icon>
                    </v-btn>

                    <v-btn elevation="0" small icon @click="abrirModalInativar(item)">
                      <v-icon dark> mdi-delete-outline</v-icon>
                    </v-btn>

                  </td>
                </tr>
              </template>
              <v-alert slot="no-results" :value="true">
                Sua busca por "{{ search }}" não encontrou resultados.
              </v-alert>
              <v-alert slot="no-data" :value="true"> Sem dados </v-alert>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="900px">
        <v-card>
          <v-toolbar color="orange darken-3" dark flat>
            <h2 class="font-weight-bold">
               {{ modal.title }}
            </h2>
          </v-toolbar>
          
         
          <v-form v-model="valid" ref="form" lazy-validation>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="4" sm="4" md="4">
                    <v-text-field label="Nome do perfil" required rounded filled background-color="white" dense
                      :rules="vazio" v-model="hand.name"></v-text-field>
                  </v-col>
                  <v-col cols="8" sm="8" md="8">
                    <v-text-field label="Descrição do perfil" required rounded filled background-color="white" dense
                      :rules="vazio" v-model="hand.label"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <h4>Restrições de dias e horários de acesso:</h4>
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-switch v-model="hand.segunda_acesso" :value="hand.segunda_acesso" label="Segunda" />
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-text-field label="Horário Inicial" required rounded filled background-color="white" dense
                      v-model="hand.segunda_ini" :value="hand.segunda_ini"></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-text-field label="Horário Final" required rounded filled background-color="white" dense
                      v-model="hand.segunda_fim"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-divider></v-divider>
                  </v-col>

                  <v-col cols="3" sm="3" md="3">
                    <v-switch v-model="hand.terca_acesso" :value="hand.terca_acesso" label="Terça" />
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-text-field label="Horário Inicial" required rounded filled background-color="white" dense
                      v-model="hand.terca_ini"></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-text-field label="Horário Final" required rounded filled background-color="white" dense
                      v-model="hand.terca_fim"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-divider></v-divider>
                  </v-col>

                  <v-col cols="3" sm="3" md="3">
                    <v-switch v-model="hand.quarta_acesso" :value="hand.quarta_acesso" label="Quarta" />
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-text-field label="Horário Inicial" required rounded filled background-color="white" dense
                      v-model="hand.quarta_ini"></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-text-field label="Horário Final" required rounded filled background-color="white" dense
                      v-model="hand.quarta_fim"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-divider></v-divider>
                  </v-col>

                  <v-col cols="3" sm="3" md="3">
                    <v-switch v-model="hand.quinta_acesso" :value="hand.quinta_acesso" label="Quinta" />
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-text-field label="Horário Inicial" required rounded filled background-color="white" dense
                      v-model="hand.quinta_ini"></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-text-field label="Horário Final" required rounded filled background-color="white" dense
                      v-model="hand.quinta_fim"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-divider></v-divider>
                  </v-col>

                  <v-col cols="3" sm="3" md="3">
                    <v-switch v-model="hand.sexta_acesso" :value="hand.sexta_acesso" label="Sexta" />
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-text-field label="Horário Inicial" required rounded filled background-color="white" dense
                      v-model="hand.sexta_ini"></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-text-field label="Horário Final" required rounded filled background-color="white" dense
                      v-model="hand.sexta_fim"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-divider></v-divider>
                  </v-col>

                  <v-col cols="3" sm="3" md="3">
                    <v-switch v-model="hand.sabado_acesso" :value="hand.sabado_acesso" label="Sábado" />
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-text-field label="Horário Inicial" required rounded filled background-color="white" dense
                      v-model="hand.sabado_ini"></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-text-field label="Horário Final" required rounded filled background-color="white" dense
                      v-model="hand.sabado_fim"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-divider></v-divider>
                  </v-col>

                  <v-col cols="3" sm="3" md="3">
                    <v-switch v-model="hand.domingo_acesso" :value="hand.domingo_acesso" label="Domingo" />
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-text-field label="Horário Inicial" required rounded filled background-color="white" dense
                      v-model="hand.domingo_ini"></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-text-field label="Horário Final" required rounded filled background-color="white" dense
                      v-model="hand.domingo_fim"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text loading v-if="carregando">
                Salvar
              </v-btn>
              <v-btn color="blue darken-1" text v-if="!carregando" :disabled="!valid" @click="validate">
                Salvar
              </v-btn>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="modalDelete" persistent max-width="400px">
        <v-card>
          <v-form v-on:submit.prevent="inativarPerfil">
            <v-card-title>
              <span class="text-h5">Excluir Perfil: {{ hand.name }}</span>
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text loading v-if="carregando">
                Excluir
              </v-btn>
              <v-btn color="red darken-1" text v-if="!carregando" type="submit">
                Excluir
              </v-btn>
              <v-btn color=" darken-1" text @click="modalDelete = false">
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import helper from "../../../mixins/helper";

export default {
  components: {},
  mixins: [helper],
  data: () => ({
    dialog: false,
    modalDelete: false,
    carregando: false,
    valid: false,
    modal: {
      title: null,
      type: null,
    },
    menu: false,
    vazio: [(v) => !!v || "O campo não pode estar vazio"],
    hand: {
      id: null,
      name: null,
      label: null,
      segunda_acesso: null,
      segunda_ini: null,
      segunda_fim: null,

      terca_acesso: null,
      terca_ini: null,
      terca_fim: null,

      quarta_acesso: null,
      quarta_ini: null,
      quarta_fim: null,

      quinta_acesso: null,
      quinta_ini: null,
      quinta_fim: null,

      sexta_acesso: null,
      sexta_ini: null,
      sexta_fim: null,

      sabado_acesso: null,
      sabado_ini: null,
      sabado_fim: null,
    },
    list: [],
    search: "",
    headers: [

      {
        text: "Nome",
        width: "25%",
        value: "name",
        align: "start",
      },
      {
        text: "Descrição Perfil",
        value: "label",
        align: "start",
      },
      {
        text: "Ações",
        width: "15%",
        align: "center",
        value: "",
      },
    ],
  }),
  mounted() {
    this.getPerfis();
  },

  methods: {
    notificacao(title, msg, icon) {
      this.$swal({
        toast: true,
        position: "top-center",
        showConfirmButton: false,
        timer: 3000,
        icon: icon,
        title: title,
        text: msg,
      });
    },

    getPerfis() {
      this.$http.get("perfisCrud").then(
        (response) => {
          this.list = response.data;
        },
        (response) => {
          console.log(response);
        }
      );
    },

    validate() {
      this.hand.segunda_acesso = this.hand.segunda_acesso ? 1 : 0;

      if (this.$refs.form.validate()) {
        if (this.modal.type === "novo") {
          this.carregando = true;
          this.criarPerfil();
        } else if (this.modal.type === "editar") {
          this.carregando = true;
          this.editarPerfil();
          this.carregando = false;
        }
      }
    },
    abrirModalNovo() {
      this.modal.title = "Novo Perfil";
      this.modal.type = "novo";
      this.carregando = false;
      this.dialog = true;

      this.hand.segunda_acesso = true;
      this.hand.segunda_ini = "00:00:00";
      this.hand.segunda_fim = "23:59:59";

      this.hand.terca_acesso = true;
      this.hand.terca_ini = "00:00:00";
      this.hand.terca_fim = "23:59:59";
      this.hand.quarta_acesso = true;
      this.hand.quarta_ini = "00:00:00";
      this.hand.quarta_fim = "23:59:59";

      this.hand.quinta_acesso = true;
      this.hand.quinta_ini = "00:00:00";
      this.hand.quinta_fim = "23:59:59";

      this.hand.sexta_acesso = true;
      this.hand.sexta_ini = "00:00:00";
      this.hand.sexta_fim = "23:59:59";

      this.hand.sabado_acesso = true;
      this.hand.sabado_ini = "00:00:00";
      this.hand.sabado_fim = "23:59:59";

      this.hand.domingo_acesso = true;
      this.hand.domingo_ini = "00:00:00";
      this.hand.domingo_fim = "23:59:59";

      // this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    abrirModalEditar(item) {
      this.modal.title = "Editar Perfil";
      this.modal.type = "editar";

      this.hand = item;

      this.hand.segunda_acesso = this.hand.segunda_acesso == 1;
      this.hand.terca_acesso = this.hand.terca_acesso == 1;
      this.hand.quarta_acesso = this.hand.quarta_acesso == 1;
      this.hand.quinta_acesso = this.hand.quinta_acesso == 1;
      this.hand.sexta_acesso = this.hand.sexta_acesso == 1;
      this.hand.sabado_acesso = this.hand.sabado_acesso == 1;
      this.hand.domingo_acesso = this.hand.domingo_acesso == 1;

      this.carregando = false;
      this.dialog = true;
    },
    abrirModalInativar(data) {
      this.hand.id = data.id;
      this.hand.sigla = data.sigla;
      this.carregando = false;
      this.modalDelete = true;
    },
    criarPerfil() {
      this.$http
        .post("perfisCrud", {
          data: this.hand,
        })
        .then(
          (response) => {
            this.getPerfis();
            this.dialog = false;
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.notificacao("", response.body.message, "success");
          },
          (response) => {
            this.carregando = false;
            this.notificacao("", response.body.message, "error");
          }
        );
    },
    editarPerfil() {
      this.$http
        .put("perfisCrud/" + this.hand.id, {
          data: this.hand,
        })
        .then(
          (response) => {
            this.getPerfis();
            this.dialog = false;
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.notificacao("", response.body.message, "success");
          },
          (response) => {
            this.carregando = false;
            this.notificacao("", response.body.message, "error");
          }
        );
    },
    inativarPerfil() {
      this.carregando = true;
      this.$http.delete("perfisCrud/" + this.hand.id).then(
        (response) => {
          this.getPerfis();
          this.modalDelete = false;
          this.notificacao("", response.body.message, "success");
        },
        (response) => {
          this.modalDelete = false;
          this.notificacao("", response.body.message, "error");
        }
      );
    },
  },
};
</script>

<style>
.degrade {
  background: #f12711;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f5af19, #f12711);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f5af19, #f12711);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

td {
  font-weight: bold !important;
  color: #000 !important;
}

.mx_h {
  height: 24%;
  margin-left: 0 !important;
}

.degrade-titulo-02 {
  background: #00b7ff;
  background: linear-gradient(90deg,
      #ac0042 0%,
      rgba(255, 255, 255, 0.3477766106442577) 43%,
      rgba(255, 255, 255, 0) 100%);
}

.table-secundary {
  border-bottom: solid #00000021;
  border-left: solid #00000021;
  border-right: solid #00000021;
  background-color: #eeeeee;
}

.text-res {
  max-width: 40ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
