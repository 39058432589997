

import { each } from "lodash";
import { mapActions } from "vuex";
import paginationComponent from "../../components/paginationComponent";
import indicadorBottomComponent from "../../components/indicadorBottomComponent";
import helper from '../../mixins/helper'

export default {
  components: { paginationComponent, indicadorBottomComponent },
  mixins: [helper],
  data: () => ({

    value: 'recent',
    ultimolote: '',
    dialog: false,
    dialog_impressao: false,
    dialog_exportar: false,
    dialog_log: false,
    dialog_validacao: false,
    dialog_invalidacao: false,
    dialog_sem_confirmação: false,

    empresa: '',
    lote: '',
    loading: false,
    pagination: [],
    list: [],
    list_registros: [],
    checked: [],
    registros: [],
    checkbox: false,
    linhas: [],
    row: "cpf",
    items: null,
    load: false,
    load_validar: false,

    disabled: false,

    motivo: null,
    motivos: [],
    motivo_observacao: null,

    singleExpand: false,
    expanded: [],
    btn_validar: false,
    senha: null,
    isExpanded: false,
    headers: [

      // { text: '#'},
      { text: 'Nome/Razão Social(Credor)', value: 'name', align: 'start' },
      { text: 'CPF/CNPJ(Credor)', value: 'cpf_cnpj', align: 'center' },
      { text: 'Lote', value: 'lote', align: 'center' },
      { text: 'Registros', value: 'registros', align: 'center' },
      { text: 'Valor Original', value: 'valor_original', align: 'center' },
      { text: 'Valor Vencido', value: 'valor_vencido', align: 'center' },
      { text: 'Data Inserção', value: 'data', align: 'center' },
      { text: 'Ações', width: "15%", align: 'center', value: '' },

    ],

    headers_log: [

      // { text: '#'},
      { text: 'Data/Hora', value: 'data', align: 'start' },
      { text: 'Usuario', value: 'name', align: 'center' },
      { text: 'IP', value: 'user_ip', align: 'center' },
      { text: 'Transação', value: 'transacao', align: 'center' },
      { text: 'Ação', value: 'acao', align: 'center' },
      { text: 'Motivo', value: 'motivo', align: 'center' },
      { text: 'Observação', value: 'observacao', width: "15%", align: 'center' },
      { text: 'Status', value: 'status_nome', width: "15%", align: 'center' },

    ],

    indicadores: {
      qtProtocolo: 0,
      qtCredores: 0,
      qtDevedores: 0,
      qtLotes: 0,
      valor_original: 0,
      valor_vencido: 0,
    },

    visualizacao: {
      empresa: "",
      status: "",
      cpf_cnpj: "",
      email: "",
      data_insercao: "",
      devedor: "",
      dev_cpfCnpj: "",
      valor_original: "",
      valor_vencido: "",
      especie: "",
      data_emissao: "",
      data_vencimento: "",
      protocolo: "",
      motivo_observacao: "",
      motivo: "",

    },
    log_log: {
      status: "",
      protocolo: "",
    }



  }),
  mounted() {
    this.getNegativar();

  },
  methods: {
    ...mapActions("auth", ["enviarDividaFile", "ActionSendDividaLote"]),

    getNegativar() {
      this.$http.get("getAll/01").then(
        (response) => {
          this.list = response.data.data;
          this.pagination = response.data;
        },
        (response) => {
          console.log(response);
          // error callback
        }
      );
      this.$http.get("getIndicadores/01").then(
        (response) => {
          this.indicadores = response.body
        },
        (response) => {
          console.log(response);
          // error callback
        }
      );
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    buscar() {
      this.$http.post("getAll/01", this.busca).then(
        (response) => {
          //  console.log(response.body)
          this.list = response.data.data;
          this.pagination = response.data;
        },
        (response) => {
          console.log(response);
          // error callback
        }
      );

    },
    retornoBusca(lista, paginacao) {

      if (lista !== null) {
        this.list = lista
        this.pagination = paginacao

      } else {
        this.getNegativar()
      }
    },
    downloadDocumento() {
      //path_anexo
      const file = this.visualizacao.path_anexo;

      if (file) {
        this.$http.get("anexoprotocolo?documento=" + file, {
          responseType: "blob"

        }).then(
          (response) => {
            console.log(response.headers.map['content-type'])
            let blob = new Blob([response.data], { type: response.headers.map['content-type'] }),
              url = window.URL.createObjectURL(blob)

            window.open(url) // 
          },
          (response) => {
            console.log(response);
          }
        );
      } else {
        alert('Documento inexistente')
        // this.notificacao("","O documento não existe em nossa base de dados");
      }
    },


    navigate(page) {
      this.$http.get("getNegativar?page=" + page).then(
        (response) => {
          this.list = response.data.data;
          this.pagination = response.data;
        },
        (response) => {
          console.log(response);
          // error callback
        }
      );
    },

    historico(empresa, protocolo) {
      this.empresa = empresa
      this.$http.get("getProtocolo/" + protocolo).then(
        (response) => {

          this.visualizacao.empresa = response.body.nome_empresa
          this.visualizacao.status = response.body.status_nome
          this.visualizacao.cpf_cnpj = this.formatarCpfCnpj(response.body.cnpj_empresa)
          this.visualizacao.email = response.body.email_empresa

          this.visualizacao.data_insercao = this.converterDataTimestamp(response.body.created_at)
          this.visualizacao.devedor = response.body.name
          this.visualizacao.dev_cpfCnpj = this.formatarCpfCnpj(response.body.cpf_cnpj)
          this.visualizacao.valor_original = this.converterDinherio(response.body.valor_original)
          this.visualizacao.valor_vencido = this.converterDinherio(response.body.valor_vencido)
          this.visualizacao.especie = response.body.especie
          this.visualizacao.data_emissao = this.converterDataTimestamp(response.body.data_emissao)
          this.visualizacao.data_vencimento = this.converterDataTimestamp(response.body.data_vencimento)
          this.visualizacao.protocolo = response.body.protocolo
          this.visualizacao.motivo_observacao = response.body.motivo_observacao
          if (response.body.motivo != null) {

            this.visualizacao.motivo = response.body.motivo + "  - " + response.body.motivo_desc
          }
        },
        (response) => {
          console.log(response);
          // error callback
        }
      );
      this.dialog = true


    },
    log(empresa, protocolo) {
      this.empresa = empresa
      this.log_log.protocolo = protocolo
      this.$http.get("getLogProtocolo/" + protocolo).then(
        (response) => {
          this.registros = response.body;
        },
        (response) => {
          console.log(response);
          // error callback
        }
      );
      this.dialog_log = true


    },



    notificacao(title, msg, icon) {
      this.$swal({
        toast: true,
        position: "center-end",
        showConfirmButton: false,
        timer: 3000,
        icon: icon,
        title: title,
        text: msg,
      });
    },
    registro(newValue, expand) {
      console.log(expand, this.load || expand)
      if (this.load || expand) {
        return
      }
      var lote = (typeof newValue === "object") ? newValue.item.lote : newValue

      this.load = true
      this.ultimolote = lote
      this.list_registros = []
      this.checked = []
      //  this.list_registros.filter(re => re.lote !== lote)
      // console.log(this.checked)
      this.$http.get("getLote/" + lote).then(
        (response) => {
          this.list_registros = response.body
          // this.list_registros = [...response.body, ...this.list_registros]

        },
        (response) => {
          console.log(response);
          // error callback
        }
      ).finally(() => { this.load = false })
    },
    addOrRemove(id, lote, protocolo) {

      each
      var index = this.checked.findIndex((res) => res.id == id)

      if (index == -1) {
        var lista_validacao = []
        lista_validacao = { id: id, lote: lote, protocolo: protocolo }
        this.checked.push(lista_validacao)
      } else {
        this.checked.splice(index, 1)
      }

      //  this.list_registros.filter(re => this.cheked.includes(re.id))
      //this.list_registros.filter(re => !this.cheked.includes(re.id))
    },
    impressao() {
      this.dialog_impressao = true
    },
    exportar() {
      this.dialog_exportar = true
    },
    validar() {
      if (this.checked.length <= 0) {
        this.dialog_sem_confirmação = true
      } else {
        this.dialog_validacao = true
        this.senha = null
      }
    },
    enviarValidacao() {
      this.load_validar = true

      this.$http.post("validar", { validacao: this.checked, senha: this.senha }).then(
        (response) => {

          this.load_validar = false
          this.$swal({ title: response.body.message, icon: "success", buttons: true })
            .then((willDelete) => {
              if (willDelete) {
                this.dialog_validacao = false
                this.registro(this.checked[0].lote, false);
                this.getNegativar()
                this.checked = []

              }
            });
        },
        (response) => {
          this.notificacao("Desculpe", response.body.message, "error")
          this.load_validar = false
          // console.log(response);
          // error callback
        }
      );

    },
    invalidar() {
      if (this.checked.length <= 0) {
        this.dialog_sem_confirmação = true
      } else {
        this.dialog_invalidacao = true
        this.senha = null
        this.motivo_observacao = null
        this.$http.get("motivos?finalidade=invalidar_titulos").then((response) => {
          this.motivos = response.data.motivo

          console.log('aqui')
          console.log(response.data.motivo);
        },
          (response) => {
            console.log(response);
            // error callback
          }
        );
      }
    },
    enviarInvalidacao() {
      if (this.motivo != null) {
        this.load_validar = true


        this.$http.post("invalidar", { invalidar: this.checked, senha: this.senha, motivo: this.motivo, observacao: this.motivo_observacao }).then(
          (response) => {

            this.load_validar = false
            this.$swal({ title: response.body.message, icon: "success", buttons: true })
              .then((willDelete) => {
                if (willDelete) {
                  this.dialog_invalidacao = false
                  this.registro(this.checked[0].lote, false);
                  this.getNegativar()
                  this.checked = []
                }
              });
          },
          (response) => {
            this.notificacao("Desculpe", response.body.message, "error")
            this.load_validar = false
            // console.log(response);
            // error callback
          }
        );
      } else {
        this.notificacao("Por favor", "Insira o Motivo", "error")
      }
    },

  },

  watch: {


    senha(val) {
      if (val != null && val.length > 8) {
        this.btn_validar = true
      } else {
        this.btn_validar = false

      }
    },

  },


}