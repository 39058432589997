<template>
  <v-card
    max-width="100%"
    class="mx-auto elevation-0"
    elevation="0"
    color="white "
  >
    <v-app-bar color="white" flat>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-container class="fill-height" fluid>
      <v-row fluid>
        <v-col cols="12" align-self="center">
          <v-tabs color="orange" fixed-tabs v-model="tabs" class="pb-0">
            <v-tab :key="0">Pessoa Jurídica</v-tab>
            <v-tab :key="1">Pessoa Física </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabs">
            <v-tab-item key="pj" class="pa-3">
              <form-pj-component> </form-pj-component>
            </v-tab-item>
            <v-tab-item key="pf">
              <form-pf-component></form-pf-component>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-row class="pa-3" justify="center">
        <v-btn
          color="#FFDD00"
          elevation="2"
          right
          width="250px"
          x-large
          @click="voltarListEmpresa()"
        >
          <v-icon color="#0052a0">mdi-home</v-icon>
          <span style="color: #0052a0; font-weight: 600">HOME</span>
        </v-btn>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import FormPfComponent from "./FormPfComponent.vue";
import FormPjComponent from "./FormPjComponent.vue";

export default {
  components: { FormPjComponent, FormPfComponent },
 
  data: () => ({
    tabs: 0,
  }),
   mounted() {
    this.id_empresa = this.$route.params.empresa_id ?? null;
    if (this.id_empresa  ) {
     this.tabs = this.$route.params.tipo_fj == "0" ? 1 : 0;
    }
  },

  methods: {
    voltarListEmpresa() {
      this.$router.push({
        name: "Cadastro de Credores",
      });
    },
    getEtapa() {
      this.$http.get("getEtapa").then(
        (response) => {
          this.list = response.data.data;
          this.pagination = response.data;
        },
        (response) => {
          console.log(response);
          // error callback
        }
      );
    },
  },
};
</script>

