<template>
  <v-container>
    <v-dialog v-model="dlg_filtros" max-width="600px">
      <v-card>
        <v-card-title> Definir Filtros </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <data-component
                v-model="srcBody.dataInicial"
                labelDate="Período Inicial"
                only-date
                filled
                rounded
                dense
                color="blue darken-4"
                background-color="white"
              ></data-component>
            </v-col>
            <v-col cols="6">
              <data-component
                v-model="srcBody.dataFinal"
                labelDate="Período Final"
                only-date
                filled
                rounded
                dense
                color="blue darken-4"
                background-color="white"
              ></data-component>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-end>
            <v-btn text @click="dlg_filtros = false">Fechar</v-btn>
            <v-btn
              :loading="search_progress"
              text
              color="primary"
              @click="getDados()"
              >Buscar</v-btn
            >
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout row wrap>
      <v-flex xs12 md12 class="pa-1">
        <v-btn color="#FFD400" elevation="0" @click="dlg_filtros = true">
          <v-icon small color="#0052A0">mdi-filter-outline</v-icon
          ><span style="font-weight: 600; color: #0052a0"
            >Definir Filtros</span
          ></v-btn
        >
      </v-flex>
      <v-flex xs12 md4 lg3 class="pa-1">
        <v-card class="mx-auto text-center">
          <v-card-text>
            <v-row>
              <v-col cols="2" lg="2">
                <v-icon x-large color="#0052A0"> mdi-cash </v-icon>
              </v-col>
              <v-col cols="10" lg="10">
                <v-row>
                  <v-col cols="12">
                    <h2 style="text-align: left; margin-top: 10px">
                      {{ dados.qt_dividas }}
                    </h2>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" style="margin-top: -15px; margin-bottom: -15px">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <h4 style="text-align: left">Dívidas enviadas</h4>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>

      <v-flex xs12 md4 lg3 class="pa-1">
        <v-card class="mx-auto text-center">
          <v-card-text>
            <v-row>
              <v-col cols="2" lg="2">
                <v-icon x-large color="#0052A0"> mdi-cash </v-icon>
              </v-col>
              <v-col cols="10" lg="10">
                <v-row>
                  <v-col cols="12">
                    <h2 style="text-align: left; margin-top: 10px">
                      {{ converterDinherio(dados.valor_vencido) }}
                    </h2>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" style="margin-top: -15px; margin-bottom: -15px">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <h4 style="text-align: left">
                  Valor Registrado(valor vencido)
                </h4>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 md4 lg3 class="pa-1">
        <v-card class="mx-auto text-center">
          <v-card-text>
            <v-row>
              <v-col cols="2" lg="2">
                <v-icon x-large color="#0052A0"> mdi-cash </v-icon>
              </v-col>
              <v-col cols="10" lg="10">
                <v-row>
                  <v-col cols="12">
                    <h2 style="text-align: left; margin-top: 10px">
                      {{ dados.qt_negativacoes }}
                    </h2>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" style="margin-top: -15px; margin-bottom: -15px">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <h4 style="text-align: left">Dívidas Negativadas</h4>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 md4 lg3 class="pa-1">
        <v-card class="mx-auto text-center">
          <v-card-text>
            <v-row>
              <v-col cols="2" lg="2">
                <v-icon x-large color="#0052A0"> mdi-cash </v-icon>
              </v-col>
              <v-col cols="10" lg="10">
                <v-row>
                  <v-col cols="12">
                    <h2 style="text-align: left; margin-top: 10px">
                      {{ dados.dividas_notificadas }}
                    </h2>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" style="margin-top: -15px; margin-bottom: -15px">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <h4 style="text-align: left">Notificações</h4>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 md4 class="pa-1">
        <v-card
          class="mx-auto text-center"
          color="#0052A0"
          dark
          max-width="600"
        >
          <v-card-text>
            <div id="chart">
              <apexchart
                type="pie"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </div>
          </v-card-text>

          <v-card-text>
            <div class="text-h4 font-weight-thin">Status das Dívidas</div>
          </v-card-text>

          <v-divider></v-divider>

          <!-- <v-card-actions class="justify-center">
            <v-btn block text> Exibir relatórios </v-btn>
          </v-card-actions>-->
        </v-card>
      </v-flex>

      <v-flex xs12 md4 class="pa-1">
        <v-card
          class="mx-auto text-center"
          color="#0052A0"
          dark
          max-width="600"
        >
          <v-card-text>
            <v-progress-circular
              :size="120"
              :width="15"
              :value="dados.dividas_notificadas_percentual"
              color="#FFD400"
              >{{ dados.dividas_notificadas_percentual }}%</v-progress-circular
            >
          </v-card-text>

          <v-card-text>
            <div class="text-h4 font-weight-thin">Dívidas notificadas</div>
          </v-card-text>

          <v-divider></v-divider>

          <!--<v-card-actions class="justify-center">
            <v-btn block text> Exibir relatórios </v-btn>
          </v-card-actions>-->
        </v-card>
      </v-flex>

      <!--
      <v-col cols="12">
        <complete-casdastro-component></complete-casdastro-component>
      </v-col>
      <v-col cols="12">
        <analise-casdastro-component></analise-casdastro-component>
      </v-col>
      -->
    </v-layout>
  </v-container>
</template>

<script>
import DataComponent from "../../components/DataComponent";

import helper from "../../mixins/helper";

export default {
  components: {
    DataComponent,
  },
  mixins: [helper],
  data: () => ({
    series: [],
    chartOptions: {
      chart: {
        width: "100%",
        type: "pie",
      },
      labels: [],
      theme: {
        monochrome: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -5,
            show: false,
          },
        },
      },

      dataLabels: {
        formatter(val /*, opts*/) {
          // const name = opts.w.globals.labels[opts.seriesIndex];
          return [val.toFixed(1) + "%"];
        },
      },
      legend: {
        show: true,

        position: "bottom",
        labels: {
          colors: "#fff",
          horizontalAlign: "left",
          useSeriesColors: false,
        },
      },
      label: {
        show: false,
      },
    },

    dlg_filtros: false,
    search_progress: false,

    dados: {
      dividas_notificadas: 0,
      qt_dividas: 0,
      valor_registrado: 0,
      qt_negativacoes: 0,
      valor_vencido: 0,
      dividas_notificadas_percentual: 0,
      status_protocolos: [],
    },
    srcBody: {
      dataInicial: "2022-01-01",
      dataFinal: "2022-12-31",
    },
  }),

  mounted() {
    this.srcBody.dataFinal = new Date().toISOString().split('T')[0];

    
    if (this.$store.state.auth.user.menu.length > 0) {
      this.items = this.$store.state.auth.user.menu;
    } else {
      this.$router.push({ name: "Cadastro de Credores" });
    }
    this.getDados();
  },
  methods: {
    async getDados() {
      this.search_progress = true;
      await this.$http.post("relatoriosHome", this.srcBody).then(
        (response) => {
          console.log(response);
          this.dados = response.data;
          this.dados.dividas_notificadas_percentual = Math.round(
            (this.dados.dividas_notificadas / this.dados.qt_dividas) * 100,
            1
          );
          this.chartOptions.labels = [];
          var labels = [];
          this.series = [];
          this.dados.status_protocolos.map((r) => {
            labels.push(r.nome);
            this.series.push(parseInt(r.dividas));
          });
          this.chartOptions = {
            labels: labels,
          };

          this.dlg_filtros = false;
          this.search_progress = false;
        },
        (response) => {
          console.log(response);
          this.search_progress = false;
        }
      );
    },
  },
};
</script>

