
import { jsPDF } from "jspdf";

import JsBarcode from 'jsbarcode';
import moment from 'moment';


var cor_sim_cor_nao = true;



function modelo01(fatura){


   
    var doc = new jsPDF("p", "mm", "a4");
    //Buscar tamanho documento para o processo de alinhamento
    var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();      
    //doc.addImage("https://raw.githubusercontent.com/MrRio/jsPDF/master/examples/images/Octonyan.jpg", "JPEG", 15, 40, 180, 180);
   

    const logo = require('@/assets/logo_teste.jpg');
    var imgLogo = new Image();
    imgLogo.src = logo

    doc.addImage( imgLogo,"JPEG",   5, 3, 38, 20);



    //window.location.href = "url_logo_1";
   
    doc.setFontSize(23);
    doc.text("Fatura Stone Up",pageWidth / 2, 15, {align: 'center'});
    //doc.rect(20, 20, (pageWidth*0.50), 35);
    doc.setFont("Helvetica", "normal");
    //Dados Contratante
    doc.setDrawColor(79,79,79);
    doc.roundedRect(5, 25,  (pageWidth*0.60), 33, 1, 1);
    doc.setDrawColor(0, 0, 0);
    doc.setFontSize(8);
    doc.setFont("Helvetica", "bold");
    doc.text("Dados do contratante:",7, 30);
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(11);
    doc.text("Razão Social: "+fatura.empresa.name,7, 35);
    doc.text("CNPJ/CPF: "+fatura.empresa.cpf_cnpj,7, 40);
    doc.text("Endereço: "+fatura.empresa.endereco + ', '+fatura.empresa.numero + ' - '+ fatura.empresa.bairro ,7, 45);
    doc.text("Cep: "+fatura.empresa.cep  ,7, 50);
    doc.text("Município/UF: "+fatura.empresa.municipio + "/"+fatura.empresa.uf  ,7, 55);

    //Fim dados Contratante

    //Cabeçalho Fatura -- Inicio

    var largura_id = (pageWidth*0.60) + 5 ;
    doc.setDrawColor(79,79,79);
    doc.roundedRect(largura_id, 25,  (pageWidth*0.355), 33, 1, 1);
    doc.setDrawColor(0, 0, 0);
   
    doc.text("Nº Fatura: ",largura_id+2, 30);
    doc.setFont("Helvetica", "bold");
    doc.text(fatura.fatura.id.toString(),largura_id + 2 + 18, 30);
    doc.setFont("Helvetica", "normal");
    doc.text("Competência: "  + converterDataTimestamp(fatura.fatura.data_inicial) + ' à '+ converterDataTimestamp(fatura.fatura.data_final)  ,largura_id+2, 35);
    doc.text("Vencimento: "   + converterDataTimestamp(fatura.fatura.data_vencimento)   ,largura_id+2, 40);
    doc.setFillColor(255, 0, 0);
    doc.roundedRect(largura_id + 35, 48,  39.5, 10, 1, 1);
    doc.text("Valor Total: "   ,largura_id+2, 55);
    doc.text(converterDinherio(fatura.fatura.valor_total )  ,largura_id + 40+2, 55);
   

    //Cabeçalho Fatura -- Fim

    //Items Faturas -- Inicio
    largura_id =5;
    var altura_ini = 60 ;
    doc.setDrawColor(79,79,79);

    
    doc.roundedRect(5, altura_ini,  78.8,190, 1, 1);


    var 
   
    margin =7,
    maxLineWidth = pageWidth - margin * 2,
    
    fontSize = 9;
    doc.setFont("Helvetica", "normal");


    var altura_ini_items = altura_ini+10;
    doc.text("Detalhes do faturamento (Plano):", 7 ,  altura_ini_items);
    altura_ini_items = altura_ini+15;

    doc.setFontSize(7);
    for(var count = 0 ; count< fatura.items.length; count++){


      var textoProcesso = fatura.items[count].descricao +"...: "+converterDinherio(fatura.items[count].subtotal);
      var textLines_ = doc
      .setFont("helvetica")
      .setFontSize(fontSize)
      .splitTextToSize(textoProcesso, maxLineWidth *.40);
  
      doc.text(textLines_, margin,altura_ini_items);
     
     // doc.text(fatura.items[count].descricao, 7 ,  altura_ini_items);
     if(textoProcesso.length>46){
      altura_ini_items+=8;
     }else{
       altura_ini_items+=4;
     }

    }
    altura_ini_items+=6;
    var text_consideracoes ="Caro cliente, para mais detalhe sobre a sua fatura, acesse: www.stoneup.com.br na área do cliente.\n\nAtenção: Antes de realizar o pagamento de seu boleto, verifique o nome do beneficiário e o nº do CNPJ que constam no ato do pagamento - nesses campos devem constar os seguintes dados:    \n* Beneficiário: JUNO pagamentos    \n* Beneficiário final: Stone Ativos    \n* CNPJ: 18.404.899/0001-73";
    var textLines = doc
    .setFont("helvetica")
    .setFontSize(fontSize)
    .splitTextToSize(text_consideracoes, maxLineWidth *.38);

    doc.text(textLines, margin,altura_ini_items);
   textLines = doc
   .setFont("helvetica")
   .setFontSize(9)
   .setTextColor(255, 0, 0)
   .splitTextToSize("A Stone Ativos não se responsabiliza por eventuais pagamentos indevidos sem a conferência adequada dos dados do beneficiário, bem como o número do CNPJ.",  maxLineWidth *.38);
    doc.text(textLines, margin,altura_ini_items + 50);

    //Items Faturas --Fm


    //Detalhes serviços -- Início
   
    largura_id =5;
    altura_ini = 60 ;
 

    let colunas = [
      {
        label: '#',
        column_name: "row_id",
        width: 10,
        center: false,
      },
      {
        label: 'Código',
        column_name: "servico_id",
        width: 13,
        center: true,
      },
      {
        label: 'Descrição',
        column_name: "nome",
        width: 30,
        center: false
      },
      {
        label: 'Data/Hora',
        column_name: "created_at",
        width: 50,
        center: false
      },
      {
        label: 'Qtd.',
        column_name: "qtd_utilizada",
        width: 20,
        center: false
      }
    ];
    altura_ini = altura_ini +10;
    largura_id =5+3;
    var acumulador_coluna_width = (pageWidth*0.40)+3;
    var acumulador_tabela_altura = altura_ini + 5;
   // var total_altura_tabela = acumulador_tabela_altura;
    
    doc.setFontSize(8);
    doc.setLineWidth(0.01);
    doc.setDrawColor(0, 0, 0);

    //Pintar Linhas celulas
    var altura_cores_linhas =  acumulador_tabela_altura;
    fatura.servicos.map(function(){   
      if(cor_sim_cor_nao){
        cor_sim_cor_nao = cor_sim_cor_nao? false : true;
        doc.setFillColor(233,233,233);    
        
      }else{
        cor_sim_cor_nao = cor_sim_cor_nao? false : true;
        doc.setFillColor(255,255,255);    
      }
        
     // doc.setFillColor(cores.r,cores.g,cores.b);  
      doc.rect(pageWidth*0.40, altura_cores_linhas-3.8, (pageWidth*0.58) , 6,'F' );
      altura_cores_linhas+=4;

    });
    

    //doc.line(pageWidth*0.40,  acumulador_tabela_altura-2, (pageWidth*.98), acumulador_tabela_altura-2);
    doc.line(pageWidth*0.40, altura_ini+1., (pageWidth*0.98), altura_ini+1);
   
    for(var i = 0 ; i<colunas.length ; i++){
     // doc.setFont("Helvetica", "bold");
      var xOffset = acumulador_coluna_width;
      var text = colunas[i].label; 
      if(colunas[i].center){            
        xOffset = (acumulador_coluna_width+(colunas[i].width/2))+ ( (colunas[i].width/2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2)); 
      }
      doc.text(text ,xOffset, altura_ini);
      doc.setFont("courier");
      
      acumulador_tabela_altura =altura_ini + 4;
      for(var row_count = 0 ; row_count <fatura.servicos.length ;row_count++ ){
        var column_name = colunas[i].column_name;       
       
        text=""; 
        if(column_name == 'row_id'){
          text=(row_count+1).toString();
        }else  if(column_name == 'created_at'){
          text= moment(fatura.servicos[row_count][column_name].toString()).format("DD/MM/YYYY HH:mm");
        }
        else{
        text = fatura.servicos[row_count][column_name].toString();
        }
        if(colunas[i].center){           
          xOffset = acumulador_coluna_width+ ( (colunas[i].width/2) - ((doc.getStringUnitWidth(text)/2) * (colunas[i].width/2) / 2)); 
        }
        doc.setTextColor(0, 0, 0); 
        doc.text(text,xOffset, acumulador_tabela_altura);
        acumulador_tabela_altura +=4;
      
       // total_altura_tabela =row_count*10;
             
      }     
      acumulador_coluna_width +=colunas[i].width;
    
    }
    var textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    var textOffset =  ((doc.internal.pageSize.width - textWidth) - (pageWidth*0.40))/ 2;

    
    doc.line(pageWidth*0.40,  acumulador_tabela_altura-2, (pageWidth*.98), acumulador_tabela_altura-2);
    
    doc.text(textOffset + (pageWidth*0.40), acumulador_tabela_altura+2, "Fim");

    doc.setFontSize(11);

    doc.setDrawColor(0, 0, 0);
    largura_id =5;
    altura_ini = 60 ;

    doc.setDrawColor(79,79,79);
    doc.roundedRect(pageWidth*0.40, altura_ini,  (pageWidth*0.58),190, 1, 1);
    doc.setFontSize(8);
    doc.setFont("Helvetica", "bold");
    doc.text("Detalhamento de utilização:",pageWidth*0.40 +3, altura_ini +5);
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(9);
    //Detalhes serviços -- Fim


    


    doc.setTextColor(0, 0, 0);
    JsBarcode("#itf",fatura.documentos_cobranca[0].codigo_barras , {format: "itf"});

    const img = document.querySelector('img#itf');
    

    doc.addImage(img.src, 'JPEG', 40, pageHeight -45, 135, 20);
    
    doc.setFontSize('11');

    var footer_margin_left = 12;



   doc.text("Stone Up", footer_margin_left,pageHeight -20 );
   doc.text("www.stoneup.com.br", footer_margin_left,pageHeight -15 );
   doc.text("CNPJ: 18.404.899/0001-73", footer_margin_left,pageHeight -10 );

    //Pre-visualização do documento
    var string = doc.output('datauristring');
    var iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>"
    var x = window.open();
    x.document.open();
    x.document.write(iframe);
    x.document.close();

}






function converterDataTimestamp(timestamp) {

  if (timestamp != null) {

      var data = timestamp.split('-')
      return data[2] + '/' + data[1] + '/' + data[0]
  } else {
      return ''
  }
}

function         converterDinherio(num) {
  var formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
  });
if (num != null) {
  return formatter.format(parseFloat(num).toFixed(2))
} else {
  num = 0.0
  return formatter.format(parseFloat(num).toFixed(2))

}
}
const modelos = {modelo_1: modelo01 }
export default modelos;

