<template>
  <v-navigation-drawer
    :mini-variant.sync="mini"
 
    color="#FFA900"
    app
    permanent

  
 
  >
    <v-sheet align="center" justify="center" color="#FFA900" class="pa-4"
      ><v-img
        src="../../assets/SVG/negativa_logo_white_big.svg"
        contain
        width="78px"
        height="58px"
      ></v-img>
    </v-sheet>

    <v-list dense>
      <v-list-item-group v-for="item in items" :key="item.id">
        <v-list-item
          dense
          exact-active-class="ativo2"
          v-show="item.sub_menu == '0'"
          :to="item.to"
        >
          <v-list-item-icon>
            <v-icon color="white">{{ item.icon }} </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="bold font-weight-bold">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group color="#0052A0" v-show="item.sub_menu != '0'" dense>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon color="white">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-bold">{{
              item.title
            }}</v-list-item-title>
          </template>
          <v-list-item dense v-for="sub in item.sub" :to="sub.to" :key="sub.id">
            <v-list-item-icon>
              <v-icon color="#F27C00">mdi-menu-right </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="color_active2 font-weight-bold">{{
                sub.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list-item-group>
    </v-list>
    <template v-slot:append>
      <!--<div class="pa-2 text-center">
        <v-btn text href="https://eever.com.br/" color="" target="_blank">
         <v-img src="../../">
          <strong style="font-size: 8px"
            >eEver Soluções Tecnológicas</strong
          ></v-btn
        >
      </div>-->
    </template>
  </v-navigation-drawer>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    items: {},
    drawer2: {
      type: Boolean,
      default: true,
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
  methods: {
    ...mapActions("auth", ["ActionSignOut"]),
    async sair() {
      try {
        await this.ActionSignOut();
        this.$router.push({ name: "login" });
      } catch (error) {
        alert(
          error.data ? error.data.message : "Não foi possivel sair do sistema"
        );
      }
    },
  },

  data: () => ({}),
   computed: {
    
  },
};
</script>
<style scoped>
.color_active_blue {
  color: #fff !important;
  font-size: 12x !important;
}
.color_active2 {
  color: white;
}
.v-list-item__title {
  color: white;
}
.ativo2 {
  background-color: #0052a0;
  opacity: 1;
}
</style>