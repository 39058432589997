<template>
  <v-container fluid class="mx-0 my-0 d-flex ind">
    <v-layout row class="justify-center align-content-center">
      <v-flex xs12 md12 sm12 lg12 class="text-center">
        <v-icon size="500" color="orange ">mdi-emoticon-cry-outline</v-icon>
        <h1 class="font-weight-thin titulo">Desculpe você não tem permissão</h1>
        <h3 class="">
          Para obrter permissão entre em contato com os administradores
        </h3>
        <v-btn icon @click="voltar" elevation="0" x-large color="orange">
          <v-icon>mdi-keyboard-backspace </v-icon> Voltar</v-btn
        >
      </v-flex>
    </v-layout>
  </v-container>
</template>


<script>
export default {
  data: () => ({}),

  methods: {
    voltar() {
        this.$router.push({name:'Home'})

    },
  },
};
</script>

<style scoped>
.titulo {
  font-size: 4em;
  color: darkorange;
}
</style>
