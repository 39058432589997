<template>
  <v-layout row wrap justify-space-around>
    <v-bottom-navigation fixed color="orange">
      <v-chip class="ma-3" outlined color="purple darken-4" label
        >Qt Protocolos {{ source.qtProtocolo }}</v-chip
      >
      <v-chip class="ma-3" outlined color="orange" label
        >Qt Credores {{ source.qtCredores }}</v-chip
      >
      <v-chip class="ma-3" outlined color="blue darken-4" label
        >Qt Devedores {{ source.qtDevedores }}</v-chip
      >
      <v-chip class="ma-3" outlined color="indigo darken-4" label
        >Qt Lotes {{ source.qtLotes }}</v-chip
      >
      <v-chip class="ma-3" outlined color="teal darken-4" label
        >Valor Total Original {{ converterDinherio(source.valor_original) }}</v-chip
      >
      <v-chip class="ma-3" outlined color="red darken-4" label
        >Valor Total Vencido {{ converterDinherio(source.valor_vencido )}}</v-chip
      >
    </v-bottom-navigation>
  </v-layout>
</template>
<script>
import helper from "../mixins/helper";
export default {
  mixins: [helper],
  props: {
    source: {
      qtProtocolo: {
        default: 0,
      },
      qtCredores: {
        default: 0,
      },
      qtDevedores: {
        default: 0,
      },
      qtLotes: {
        default: 0,
      },
      valor_original: {
        default: 0,
      },
      valor_vencido: {
        default: 0,
      },
    },
  },
  data: () => ({}),

  methods: {},
};
</script>


