
import { isCPF } from "vuetify-masks-br";

import DataComponent from "../../components/DataComponent";
export default {
    components: {DataComponent},
    mixins: [],
    data: () => ({
        dialog: false,
        modalDelete: false,
        
        show1: false,
        loading: true,
        trocarSenha: false,
        forcaSenha: '',
        senhaValida: false,
        error: '',
        empresas: [],
        empresaSelecionada: null,
        roles: [],
        cpfRule: [

            (v) => isCPF(v) || "CPF não é válido.",
        ],
        senhaRules1: [
            (v) => !!v || "Senha é obrigatória",
            (v) => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(v) || 'A senha deve conter letras maisculas, letras minúsculas, números e caracteres especiais(@,$,&...)',
        ],

        usuario: {
            descricao: '',
            title: '',
            name: '',
            id: '',
            email: '',
            password: '',
            telefone: '',
            whatsapp: '',
            picture: '',
            data_nascimento: '',
            cpf_cnpj: '',
            rg: '',
            status: '',
            observacao: '',
            empresa_id: '',
            role_id: '',
            empresas :[],

        },
        pagination: [],
        list: [],
        empresasDisponiveis : [],
       

        search: '',
        filtro_aplicao: 0,
        filtro_empresa: 0,
        aplicacoes: [
            { id: 0, name: 'Todas Aplicações' },
            { id: 1, name: 'StoneUp Admin' },
            { id: 2, name: 'Stone Negativa' },
            { id: 3, name: 'Stone Monitora' },
        ],
        headerEmpresas:[
            { text: 'Id'},
            { text: 'Descrição'},
            { text: 'Ações'},
        ],
        headers: [
 
            { text: 'Nome', value: 'name', align: 'start' },
            { text: 'E-mail', value: 'email', align: 'start' },
            { text: 'Aplicação', value: 'aplicacao', align: 'start' },
            { text: 'Status', value: 'status', align: 'start' },
            { text: 'Empresa', value: 'empresa', align: 'start' },
            { text: 'Perfil de acesso', value: 'label', align: 'start' },

            { text: 'Ações', width: "15%", align: 'center', value: '' },
        ],

    }),
    created() {

    },
    mounted() {
        this.rolesGet();
        this.listaEmpresas();
        this.getUsers();
       

    },



    methods: {
        notificacao(title, msg, icon) {
            this.$swal({
                toast: true,
                position: "top-center",
                showConfirmButton: false,
                timer: 3000,
                icon: icon,
                title: title,
                text: msg,
            });
        },
        removePeriodo(item) {
            let index = this.usuario.empresas.findIndex(
                (old) => old === item
              );
        
              this.usuario.empresas.splice(index, 1);
          },


          empresaAdd() {      
          
            let empresaSelecionada =this.empresaSelecionada
            empresaSelecionada = {
                id : empresaSelecionada.id,
                name : empresaSelecionada.label
            }
            console.log(empresaSelecionada)

            let index = this.usuario.empresas.findIndex(
              (old) => old.id == empresaSelecionada.id
            );
      
            //alert(index);
            if (index === -1) {
              this.usuario.empresas.push(empresaSelecionada);
            
            } else {
              this.notificacao("Duplicidade", "O usuário já possui permissão a empresa selecionada.", "error");
            }
          },


        limparUsuario() {
            this.usuario = {
                descricao: null,
                title: null,
                name:  null,
                id: null,
                email:  null,
                password:  null,
                telefone: null,
                whatsapp:  null,
                picture:  null,
                data_nascimento:  null,
                cpf_cnpj:  null,
                rg:  null,
                status:  null,
                observacao:  null,
                empresa_id:  null,
                role_id:  null,
                empresas :[]

            };

        },

        async rolesGet() {

            try {
                //ert(this.empresa_id)
                this.roles = [];

                // this.empresaId = 0
                const { data: result } = await this.$http.post("rolesforcompany");

                console.log(result);
                this.roles = result;


            }
            catch (exception) {
                console.log(exception);
            }
        },
        async listaEmpresas() {
            this.empresaId = 1;
             try {
                  this.empresas = [];
                  this.empresaId = 0
                  const { data: result } = await this.$http.get("users/empresasdisponiveis");
                  console.log(result);
                  this.empresas = result;
  
                  // this.empresas.push({text: '--Todas--', value: 0});
  
              }
              catch (exception) {
                  console.log(exception);
              }

        },

        async getUsers() {
            const { data: result } = await this.$http.get(`usuarios?aplicacao=${this.filtro_aplicao}&empresa=${this.filtro_empresa}`)
            console.log(result)
            this.list = result;
        },
        verificaForcaSenha() {

        },

        async abriModal(id) {

            this.limparUsuario();
         
            this.usuario.descricao = id == 0 ? 'Novo usuário' : 'Edição de Usuário'
            if (id != 0) {
                const { data: result } = await this.$http.get("usuarios/" + id)
                console.log(result)

                this.usuario.status = this.usuario.status == 'ativo' ? true : false;
                this.usuario = result;
                this.usuario.alterar_senha = false;
            } else {
                this.usuario.alterar_senha = true;
                this.usuario.id = 0;
            }


            this.loading = false
            this.dialog = true
        },
        changeStatus() {
            //alert(this.usuario.alterar_senha)
        },
        abrirModalInativar(id, texto) {
            this.usuario.id = id
            this.usuario.descricao = texto
         //   this.loading = false
            this.modalDelete = true

        },
        formSubmit() {

            this.loading = true;
            this.error = "";
            if (this.usuario.name <= 0) {
                this.error += '*O campo nome não pode está vazio'

            }
            if (this.usuario.id == 0) {
                if (this.usuario.password <= 0) {
                    this.error += '\n*O campo senha não pode está vazio'
                    if (this.senhaValida) {
                        this.error += '\n*A senha não atende os requisitos de segurança'
                    }
                }
            }
            
            if (this.error == "") {
                this.criarUsuario();
            }
            this.loading = false;
        },
        criarUsuario() {
            this.usuario.id = this.usuario.id === null ? 0 : this.usuario.id;
            this.usuario.alterar_senha =  this.trocarSenha
           // alert(this.trocarSenha)
            //alert(this.usuario.id )
            this.$http.post("usercrud/" + this.usuario.id, {
                data: this.usuario
            }).then(
                (response) => {
                    this.getUsers()
                    this.usuario.descricao = ''
                    this.dialog = false
                    this.notificacao('', response.body, "success")
                },
                (response) => {
                    this.notificacao('', response.body.message, "error")
                }
            );
        },
        descricaoAplicacao(id) {
            var descricao = "Indefinido";
            switch (id) {
                case '1':
                    descricao = "StoneUp Admin";
                    break;
                case '2':
                    descricao = "Stone Negativa";
                    break;
                case '3':
                    descricao = "Stone Monitora"
                    break;
            }
            return descricao;

        },
        editarMotivo() {
            this.$http.put("finalidades/" + this.usuario.id, {
                descricao: this.usuario.descricao,
                invalidar_titulos: this.usuario.invalidar_titulos,
                contestar_divida: this.usuario.contestar_divida


            }).then(
                (response) => {
                    this.getUsers()
                    this.usuario.descricao = ''
                    this.dialog = false
                    this.notificacao('', response.body.message, "success")
                },
                (response) => {
                    this.notificacao('', response.body.message, "error")
                }
            );
        },
        inativarMotivo() {
            this.loading = true
            this.$http.delete("finalidades/" + this.usuario.id + '/inativar').then(
                (response) => {
                    this.getUsers()
                    this.usuario.descricao = ''
                    this.dialog = false
                    this.notificacao('', response.body.message, "success")
                    this.loading = false
                    this.modalDelete = false
                },
                (response) => {
                    this.notificacao('', response.body.message, "error")
                    this.loading = false
                    this.modalDelete = false
                }
            );
        }


    },
    watch: {



    }
}