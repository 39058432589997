
import paginationComponent from "../../components/paginationComponent";
import helper from '../../mixins/helper'
import DataComponent from "../../components/DataComponent";
import LogComponent from "../../components/LogComponent";
import HistoryComponent from "../../components/HistoryComponent";

import SearchProtocolsComponent from "../../components/SearchProtocolsComponent";
export default {
    components: { paginationComponent, DataComponent, LogComponent, HistoryComponent, SearchProtocolsComponent },
    mixins: [helper],
    data: () => ({
        formasPagamento: null,
        formas_Pagamento: [],
        dataPagamento: null,
        idTitulo: null,
        idNegociacao: null,
        numParcela: null,
        tipo: null,
        idAcordo: null,
        valorTotal: 0,
        valorPago: 0,
        troco: 0,
        textoWhats :"",
        dialog: false,
        sheet: false,

        items: [],
        loading: false,
        pagination: [],
        list: [],
        states: [],
        registros: {},
        dialog_log: false,
        dialog_history: false,
        visualizacao: {},
        protocolo: '',


        obrigatorio: [
            (v) => !!v || "Este campo é obrigatória",
        ],
    }),
    created() {

    },
    mounted() {
        //this.getProtocolos();

    },

    methods: {
        retornoBusca(list_emit) {
            

            this.list = list_emit;
        },
        getProtocolos() {
            this.$http.get("protocolos").then(
                (response) => {

                    this.list = response.body.data;
                    this.pagination = response.body;
                },
                (response) => {
                    console.log(response);
                    // error callback
                }
            );

        },
        
        save(date) {
            this.$refs.menu.save(date);
        },
       


        navigate(page) {
            this.$http.get("protocolos?page=" + page).then(
                (response) => {
                    this.list = response.body.data;
                    this.pagination = response.body;
                },
                (response) => {
                    console.log(response);
                    // error callback
                }
            );
        },

        gerarPdf(dados, obs) {

            let foot = "";
            var totalTitulos =  0 ;
            var totalVencido = 0 ;
            var totalOriginal = 0;

            var newDados =[];
            dados.map((i)=>{
                
             
                var elemento = {
                    protocolo : i.protocolo,
                    lote : i.lote,
                    name : i.cliente.name,
                    cpf_cnpj : i.cliente.cpf_cnpj,
                    numero_titulo : i.numero_titulo,
                    data_vencimento : i.data_vencimento,
                    valor_vencido : i.valor_vencido,
                    valor_original : i.valor_original,
                    created_at : i.created_at,
                    
                }

                totalVencido += parseFloat(i.valor_vencido);
                totalOriginal += parseFloat(i.valor_original);
                totalTitulos ++;

                newDados.push(elemento);

            })

            foot =  `Total devedores: ${totalTitulos};  Qtd. Títulos: ${totalTitulos}; Total Valor Original: ${  this.converterDinherio(totalOriginal)}; Total valor Vencido ${ this.converterDinherio(totalVencido)}`
   
            this.$http.post('geradorRelatorioPdf', {
                tableheaders:
                    [

                        // { text: '#'},
                        { text: 'Protocolo', value: 'protocolo', align: 'start' },
                        { text: 'Lote', value: 'lote', align: 'center' },
                        { text: 'Cliente', value: 'name', align: 'center' },
                        { text: 'CPF/CNPJ', value: 'cpf_cnpj', align: 'center', format: 'cpfcnpj' },
                        { text: 'Num. Título', value: 'numero_titulo', align: 'center' },
                        { text: 'Data Vencimento', value: 'data_vencimento', align: 'center',  format: 'datetime'},
                        { text: 'Valor Vencido', value: 'valor_vencido', align: 'center', format: 'money' },
                      
                    ]

                    //, format: 'money' 
                ,
                items: newDados,
                tipo: 'relatorio_negativa',
                obs: obs,
                title: 'Relatório de registros a serem aprovados',
                footer: foot

            }, {
                responseType: "blob",
            }).then(
                (response) => {
                    console.log(response.headers.map["content-type"]);
                    let blob = new Blob([response.data], {
                        type: response.headers.map["content-type"],
                    }),
                        url = window.URL.createObjectURL(blob);

                    window.open(url); //
                },
                (response) => {
                    console.log(response);
                }

            )

        },
        getData(data) {
            this.dataPagamento = data
        },
        notificacao(title, msg, icon) {
            this.$swal({
                toast: true,
                position: "center-end",
                showConfirmButton: false,
                timer: 3000,
                icon: icon,
                title: title,
                text: msg,
            });
        },
        visualizar(item) {
            this.items = item
            this.dialog = !this.dialog
        },
        setLog(dialog) {
            this.dialog_log = dialog
        },
        setHistory(dialog) {
            this.dialog_history = dialog
        },
        log(protocolo) {

            this.protocolo = protocolo
            this.$http.get("getLogProtocolo/" + protocolo).then(
                (response) => {
                    this.dialog_log = true
                    console.log(response.body)
                    this.registros = response.body;

                },
                (response) => {
                    console.log(response);
                    // error callback
                }
            );

        },
        historico(protocolo) {

            this.$http.get("protocolos/get/" + protocolo).then(
                (response) => {

                    console.log(response.body[0].coautores);
                    this.visualizacao.empresa = response.body[0].nome_empresa
                    this.visualizacao.status = response.body[0].status_nome
                    this.visualizacao.cpf_cnpj = this.formatarCpfCnpj(response.body[0].cnpj_empresa)
                    this.visualizacao.email = response.body[0].email_empresa

                    this.visualizacao.data_insercao = response.body[0].data_insercao
                    this.visualizacao.devedor = response.body[0].name
                    this.visualizacao.dev_cpfCnpj = this.formatarCpfCnpj(response.body[0].cpf_cnpj)
                    this.visualizacao.valor_original = this.converterDinherio(response.body[0].valor_original)
                    this.visualizacao.valor_vencido = this.converterDinherio(response.body[0].valor_vencido)
                    this.visualizacao.especie = response.body[0].descricaoEspecie
                    this.visualizacao.data_emissao = this.converterDataTimestamp(response.body[0].data_emissao)
                    this.visualizacao.data_vencimento = this.converterDataTimestamp(response.body[0].data_vencimento)
                    this.visualizacao.protocolo = response.body[0].protocolo
                    this.visualizacao.motivo_observacao = response.body[0].motivo_observacao
                    this.visualizacao.coautores = response.body[0].coautores
                    this.visualizacao.path_anexo = response.body[0].path_anexo
                    if (response.body[0].motivo != null) {

                        this.visualizacao.motivo = response.body[0].motivo + "  - " + response.body[0].motivo_desc
                    }
                    this.dialog_history = true
                },
                (response) => {
                    console.log(response);
                    // error callback
                }
            );



        }








    },



}