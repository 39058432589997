<template>
  <v-container class="mx_h">
    <v-row class="mt-6">
      <v-expansion-panels flat :value="0">
        <v-expansion-panel>
          <v-expansion-panel-header> Filtros </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-flex col="3" xs4 md3 class="pa-1">
                <data-component
                  v-model="dataInicial"
                  labelDate="Pesquisa Inicial"
                  only-date
                  filled
                  rounded
                  dense
                  color="blue darken-4"
                  background-color="white"
                ></data-component>
              </v-flex>
              <v-flex col="3" xs4 md3 class="pa-1">
                <data-component
                  v-model="dataFinal"
                  labelDate="Pesquisa Final"
                  only-date
                  color="blue darken-4"
                  filled
                  rounded
                  dense
                  background-color="white"
                ></data-component>
              </v-flex>
              <v-flex col="4" xs6 md6 class="pa-1">
                <v-row>
                  <v-col cols="2">
                    <v-radio-group v-model="row2" row style="margin-top: -12px">
                      <v-radio
                        label="CPF"
                        class="font-weight-bold"
                        color="blue darken-4"
                        value="cpf"
                      ></v-radio>
                      <v-radio
                        label="CNPJ"
                        class="font-weight-bold"
                        color="blue darken-4"
                        value="cnpj"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="6">
                    <v-flex xs12 md12>
                      <v-text-field
                        v-model="cpf_cnpj2"
                        label="CPF"
                        only-date
                        filled
                        rounded
                        dense
                        background-color="white"
                        v-if="row2 == 'cpf'"
                        :rules="cpfRule2"
                        v-cpf:model="cpf_cnpj2"
                        color="blue darken-4"
                      ></v-text-field>
                      <v-text-field
                        v-model="cpf_cnpj2"
                        label="CNPJ"
                        filled
                        rounded
                        dense
                        background-color="white"
                        v-if="row2 == 'cnpj'"
                        :rules="cnpjRule"
                        v-cnpj:model="cpf_cnpj2"
                        color="orange darken-4"
                      ></v-text-field>
                    </v-flex>
                  </v-col>
                </v-row>
              </v-flex>
              <v-flex col="2" xs12 md2 class="pa-1">
                <v-btn
                  color="#ffa900"
                  x-large
                  elevation="0"
                  @click="listarConsultas"
                >
                  <v-icon
                    style="
                      color: #0052a0;
                      font-weight: 600;
                      letter-spacing: 0.19px;
                    "
                    class="mr-1"
                    >mdi-magnify</v-icon
                  >
                  <span
                    style="
                      color: #0052a0;
                      font-weight: 600;
                      letter-spacing: 0.19px;
                    "
                    >BUSCAR</span
                  ></v-btn
                >
              </v-flex>
              <v-flex col="2" xs12 md2 class="pa-1">
                <v-btn
                  color="#0052A0"
                  x-large
                  elevation="0"
                  @click="abrirDialogConsulta"
                >
                  <v-icon
                    style="
                      color: #ffd400;
                      font-weight: 600;
                      letter-spacing: 0.19px;
                    "
                    class="mr-1"
                    >mdi-plus-circle</v-icon
                  >
                  <span
                    style="
                      color: #ffd400;
                      font-weight: 600;
                      letter-spacing: 0.19px;
                    "
                    >NOVA CONSULTA</span
                  ></v-btn
                >
              </v-flex>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row justify="center">
        <v-dialog v-model="dialogConsulta" persistent max-width="600px">
          <v-card>
            <!-- <v-form v-on:submit.prevent="motivoSubmit">-->

            <v-card-title>
              <span class="text-h5">Nova consulta de consumidor</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-radio-group v-model="row" row>
                  <v-radio
                    label="CPF"
                    class="font-weight-bold"
                    color="orange"
                    value="cpf"
                  ></v-radio>
                  <v-radio
                    label="CNPJ"
                    class="font-weight-bold"
                    color="orange"
                    value="cnpj"
                  ></v-radio>
                </v-radio-group>
                <v-flex xs12 md12>
                  <v-text-field
                    v-model="cpf_cnpj"
                    label="CPF"
                    :loading="loading"
                    rounded
                    filled
                    background-color="white"
                    v-if="row == 'cpf'"
                    :rules="cpfRule"
                    v-cpf:model="cpf_cnpj"
                    dense
                    color="orange darken-4"
                  ></v-text-field>
                  <v-text-field
                    v-model="cpf_cnpj"
                    label="CNPJ"
                    rounded
                    filled
                    background-color="white"
                    v-if="row == 'cnpj'"
                    :rules="cnpjRule"
                    v-cnpj:model="cpf_cnpj"
                    dense
                    color="orange darken-4"
                  ></v-text-field>
                </v-flex>
                <!--
                <v-flex xs12 md12>
                  <vue-recaptcha sitekey="10904315">
                    <button>Click me</button>
                  </vue-recaptcha>
                </v-flex>-->
                <v-flex xs12 md12>
                  <span
                    >*Os dados pertinentes as consultas são da data da geração
                    do documento.</span
                  >
                </v-flex>
                <v-flex xs12 md12 v-if="consultaGerada">
                  <router-link
                    :to="{
                      name: 'Consulta ao Consumidor',
                      params: { id: idNovaConsulta },
                    }"
                    >Clique aqui para exibir a consulta efetuada.</router-link
                  >
                </v-flex>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                :loading="ldg_nova_consulta"
                text
                @click="criarConsulta()"
              >
                Efetuar Consulta
              </v-btn>
              <v-btn color="blue darken-1" text @click="dialogConsulta = false">
                Fechar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-flex col="12" xs12 md12>
        <v-card class="mx_h" elevation="0">
          <v-card-text>
            <v-data-table
              :headers="[
                { text: 'CPF/CNPJ' },
                { text: 'Consumidor' },
                { text: 'Data Consulta' },
                { text: 'Ações' },
              ]"
              :footer-props="{
                'items-per-page-text': 'Registros por página: ',
                'page-text': '{0}-{1} de {2}',
                'items-per-page-all-text': 'Listar todos',
              }"
              :items="listaConsultas"
              item-key="lote"
              :single-expand="true"
              :search="search"
              show-expand
              class="elevation-0"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td></td>

                  <td>{{ formatarCpfCnpj(item.cpf_cnpj_consumidor) }}</td>
                  <td>{{ item.nome }}</td>
                  <td>{{ item.data_consulta }}</td>
                  <td>
                    <v-btn
                      elevation="0"
                      small
                      icon
                      :to="{
                        name: 'Consulta ao Consumidor',
                        params: { id: item.id },
                      }"
                    >
                      <v-icon dark> mdi-magnify</v-icon>
                    </v-btn>
                  </td>
                  <!--
                  <td class="text-res">{{ item.descricao }}</td>
                  <td>{{ converterDataTimestamp(item.data_vigiencia) }}</td>
                  <td>
                    {{ converterDataTimestamp(item.data_final_vigiencia) }}
                  </td>

                  <td class="text-center">
                    <v-btn
                      elevation="0"
                      small
                      icon
                      @click="abrirModalVer(item)"
                    >
                      <v-icon dark> mdi-card-bulleted-settings-outline</v-icon>
                    </v-btn>
                    <v-btn
                      elevation="0"
                      small
                      icon
                      @click="abrirModalEditar(item)"
                    >
                      <v-icon dark> mdi-square-edit-outline</v-icon>
                    </v-btn>

                    <v-btn
                      elevation="0"
                      small
                      icon
                      @click="abrirModalInativar(item)"
                    >
                      <v-icon dark> mdi-delete-outline</v-icon>
                    </v-btn>
                  </td>-->
                </tr>
              </template>
              <v-alert slot="no-results" :value="true">
                Sua busca por "{{ search }}" não encontrou resultados.
              </v-alert>
              <v-alert slot="no-data" :value="true"> Sem dados </v-alert>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-row>
  </v-container>
</template>

<script>
import { isCNPJ, isCPF } from "vuetify-masks-br";
import helper from "../../mixins/helper";
import DataComponent from "../../components/DataComponent";
//import { VueRecaptcha } from "vue-recaptcha";
export default {
  components: {
    DataComponent,
  },
  mixins: [helper],
  data: () => ({
    row: "cpf",
    cpf_cnpj: null,
    ldg_nova_consulta: false,
    row2: "cpf",
    cpf_cnpj2: null,
    idNovaConsulta: null,
    dialogConsulta: false,
    consultaGerada: true,
    dataInicial: null,
    dataFinal: null,
    listaConsultas: [],
    habilitarBusca: false,
    cpfRule: [
      (v) => !!v || "CPF é obrigatória",
      (v) => isCPF(v) || "CPF não é válido.",
    ],
    cnpjRule: [
      (v) => !!v || "CNPJ é obrigatória",
      (v) => isCNPJ(v) || "CNPJ não é válido.",
    ],
  }),
  created() {},
  mounted() {
    this.listarConsultas();
  },

  methods: {
    abrirDialogConsulta() {
      this.consultaGerada = false;
      this.dialogConsulta = true;
    },

    listarConsultas() {
      this.$http
        .post("getConsultasEmpresa", {
          dataInicial: this.dataInicial,
          dataFinal: this.dataFinal,
          cpf_cnpj: this.cpf_cnpj2,
        })
        .then(
          (response) => {
            console.log(response);
            this.listaConsultas = response.data;
          },
          (response) => {
            console.log(response);
            // error callback
          }
        );
    },
    notificacao(title, msg, icon) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
        icon: icon,
        title: title,
        text: msg,
      });
    },

    requisicarConsuta() {
      this.ldg_nova_consulta = true;
      this.$http
        .post("consultarConsumidor", {
          cpf_cnpj: this.cpf_cnpj,
        })
        .then(
          (response) => {
            console.log(response);
            this.idNovaConsulta = response.data.protocolo_consulta;
            this.consultaGerada = true;
            this.notificacao(
              "Sucesso",
              "Busca realizada com sucesso",
              "success"
            );
            this.listarConsultas();
            this.ldg_nova_consulta = false;
          },
          (response) => {
            console.log(response);
            this.ldg_nova_consulta = false;
            // error callback
          }
        );
    },
    getDataInicial(data) {
      this.dataInicial = data;
    },
    getDataFinal(data) {
      this.dataFinal = data;
    },

    criarConsulta() {
      this.ldg_nova_consulta = true;
      this.$http
        .post("VerificarConsultaRecorrenteConsumidor", {
          cpf_cnpj: this.cpf_cnpj,
        })
        .then(
          (response) => {
            this.ldg_nova_consulta = false;

            if (response.data === '"1"') {
              this.$swal({
                toast: false,
                position: "center",

                showConfirmButton: true,
                showCancelButton: true,
                
                cancelButtonText: "Não",
                 confirmButtonColor: "#2778c4",
                 cancelButtonColor: "#ffd400",
                title: "Busca já feita Recentemente",
                text: "Notamos que sua empresa já consultou este cpf/cnpj recentemente, deseja efetuar busca assim mesmo?",
              }).then((result) => {
                this.ldg_nova_consulta = false;
                if (result.isConfirmed) {
                  this.requisicarConsuta();
                } else if (result.isDenied) {
                  this.cpf_cnpj = null;
                }
              });
            } else {
              this.requisicarConsuta();
            }
          },
          (response) => {
            console.log(response);
            // error callback
          }
        );
      /*
       */
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
};
</script>

<style>
.degrade {
  background: #f12711;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f5af19, #f12711);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f5af19, #f12711);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

td {
  font-weight: bold !important;
  color: #000 !important;
}

.mx_h {
  height: 24%;
  margin-left: 0 !important;
}

.degrade-titulo-02 {
  background: #00b7ff;
  background: linear-gradient(
    90deg,
    #ac0042 0%,
    rgba(255, 255, 255, 0.3477766106442577) 43%,
    rgba(255, 255, 255, 0) 100%
  );
}

.table-secundary {
  border-bottom: solid #00000021;
  border-left: solid #00000021;
  border-right: solid #00000021;
  background-color: #eeeeee;
}

.text-res {
  max-width: 40ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
