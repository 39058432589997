
import paginationComponent from "../../components/paginationComponent";
import helper from '../../mixins/helper'
import DataComponent from "../../components/DataComponent";
import LogComponent from "../../components/LogComponent";
import HistoryComponent from "../../components/HistoryComponent";

import SearchProtocolsComponent from "../../components/SearchProtocolsComponent";
export default {
    components: { paginationComponent, DataComponent, LogComponent, HistoryComponent, SearchProtocolsComponent },
    mixins: [helper],
    data: () => ({
        busca: {
            servico: null,
            emissao_ini : null,
            emissao_fim : null,
            fatura_id: null,
            
        },
        dialog: false,

        loading: false,
        listServicos: [],



    }),
    created() {

    },
    mounted() {
     //   this.getProtocolos();
        this.buscarServicos();

    },

    methods: {

        buscar() {
            this.loading =  true
            this.$http.get("Serviclist").then(
                (response) => {

                    this.list = response.body.data;
                    this.loading =  false

                },
                (response) => {
                    console.log(response);
                    this.loading =  false
                    // error callback
                }
            );
            

        },
        buscarServicos() {
            this.$http.get("Serviclist").then(
                (response) => {
                    this.listServicos = response.data;
                    console.log(response.data)
                },
                (response) => {
                    console.log(response);
                }
            );
        },

        getData(data) {
            this.dataPagamento = data
        },
        notificacao(title, msg, icon) {
            this.$swal({
                toast: true,
                position: "center-end",
                showConfirmButton: false,
                timer: 3000,
                icon: icon,
                title: title,
                text: msg,
            });
        }




    },



}