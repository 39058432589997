<template>
  <v-app id="inspire">
    <navigation-drawer :items="items" :mini="miniMenu" temporary></navigation-drawer>
    <app-bar :items_menu="items_menu" :nome="nome" :empresa="empresa" :isInadimplente="isInadimplente"
      @emit-drawer="getDrawer"></app-bar>

    <v-main class="pa-2 ma-0 white">
      <h3 style="margin-bottom: 0px; margin-top: 5px; margin-left: 15px">
        {{ this.$route.name }}
      </h3>
      <router-view> </router-view>
      <v-dialog v-model="inadimplenteDialogShow" max-width="600">
        <v-sheet class="pa-4 text-center mx-auto" elevation="12" max-width="600" rounded="lg" width="100%">
          <v-icon class="mb-5" color="error" size="112">mdi-alert</v-icon>

          <h2 class="text-h5 mb-6">Faturas em aberto</h2>

          <p class="mb-4 text-medium-emphasis text-body-2">
            Prezado cliente, identificamos faturas pendentes no seu plano. Solicitamos a regularização do pagamento para
            evitar a suspensão do seu acesso à plataforma.
            <br><br>
            Agradecemos pela sua atenção.
            <br>
            Atenciosamente, equipe StoneUp.
          </p>

          <v-divider class="mb-4"></v-divider>

          <div class="text-end">
            <v-btn class="text-none" color="success" @click="inadimplenteDialogShow = false" variant="flat" width="90"
              rounded>
              Fechar
            </v-btn>
          </div>
        </v-sheet>
      </v-dialog>

      <v-dialog v-model="dialogWhatsapp" content-class="my-custom-dialog" max-width="300">
        <v-card>
          <v-toolbar color="green dense">
            <v-row>
              <v-col cols="2">
                <v-avatar color="#0052a0" class="border" size="40">
                  <v-img src="../assets/stonenegativa.svg"></v-img>
                </v-avatar>
              </v-col>
              <v-col cols="10">
                <h3 class="white--text">Equipe StoneUp</h3>
                <h5 class="white--text">SAC:{{ telefone }}</h5>

                <v-btn fab dark small @click="dialogWhatsapp = false" style="
                    position: fixed;
                    right: 2px;
                    top: 2px;
                    width: 15px;
                    height: 15px;
                  "><v-icon small>mdi-close</v-icon></v-btn>

                <!-- https://api.whatsapp.com/send?phone=5592984186833&text=Teste-->
              </v-col>
            </v-row>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <div style="height: 180px; width: 100%; margin-top: 25px">
                <v-chip style="width: 90%">Fale conosco!</v-chip>
                <div style="margin-top: 15px">
                  <v-textarea v-model="textoMensagem" height="115" full-width style="width: 85%" filled rounded dense>
                  </v-textarea>
                  <v-btn style="position: absolute; bottom: 35px; right: 2px" small @click="enviarMensagem()"
                    color="success"><v-icon small>mdi-send</v-icon></v-btn>
                </div>
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-btn color="green" @click="exibirWhats()" style="
          position: fixed;
          bottom: 25px;
          right: 25px;
          display: inline-block;
        " fab><v-icon color="white">mdi-whatsapp</v-icon>
      </v-btn>

    </v-main>
    <footer-stone> </footer-stone>
  </v-app>
</template>

<script>
import NavigationDrawer from "../containers/layout/NavigationDrawer";
import { mapActions } from "vuex";
import AppBar from "../containers/layout/AppBar";
import FooterStone from "../containers/layout/FooterStone";

export default {
  components: { NavigationDrawer, AppBar, FooterStone },
  name: "Dashboard",
  data: () => ({
    drawer: false,
    miniMenu: false,
    empresa: "",
    isInadimplente: false,

    dialogWhatsapp: false,
    textoMensagem: null,
    ambiente: null,
    telefone: null,
    type: null,
    second: 0,
    time: 0,
    degrade: "degrade",
    items_menu: [],
    nome: "",
    inadimplenteDialogShow: false,
  }),

  methods: {
    ...mapActions("auth", ["ActionSignOut"]),
    async sair() {
      try {
        await this.ActionSignOut();
        this.$router.push({ name: "login" });
      } catch (error) {
        alert(
          error.data ? error.data.message : "Não foi possivel sair do sistema"
        );
      }
    },
    exibirWhats() {
      this.dialogWhatsapp = true;
    },
    carregarNumero() {
      this.$http.get("suportetelefone").then((response) => {
        this.telefone = response.body;
      });
    },
    enviarMensagem() {
      var telefone = "";

      this.$http.get("suportetelefone").then((response) => {
        this.dialogWhatsapp = false;
        telefone = response.body;
        window.open(
          `https://api.whatsapp.com/send?phone=${telefone}&text=${this.textoMensagem}`,
          "_blank"
        );
        this.textoMensagem = null;
      });
    },

    NavigateTo(where) {
      this.$router.push({ name: where });
    },
    getDrawer() {
      var width = screen.width;

      console.log(width);

      if (width > 1110) {
        this.miniMenu = !this.miniMenu;
        this.drawer2 = true;
      } else {
        this.miniMenu = false;
        this.drawer2 = !this.drawer2;
      }
    },
  },
  mounted() {
    this.nome = this.$store.state.auth.user.name;
    this.ambiente = this.$store.state.auth.user.ambiente;
    console.log(this.$store.state.auth.user);
    this.isInadimplente = this.$store.state.auth.user.domain.status_financeiro;

    this.empresa =
      this.$store.state.auth.user.domain.name +
      "  - " +
      this.$store.state.auth.user.domain.cpf_cnpj;
    if (this.$store.state.auth.user.domain.status_financeiro == "INADIMPLENTE") {
      this.inadimplenteDialogShow = true;
    }

  },
  created() {
    this.carregarNumero();
    this.items = [];

    if (this.$store.state.auth.user.menu.length > 0) {
      this.items = this.$store.state.auth.user.menu;
    } else {
      this.$router.push({ name: "Cadastro de Credores" });
    }
  },
  computed: {
    /* second() {
     
    },*/
  },
};
</script>

<style>
.bold {
  font-weight: 700;
}

h3 {
  color: #0066ff !important;
  font-family: "Montserrat";
}

.v-card__title {
  color: #0066ff !important;
  font-family: "Montserrat";
  font-weight: 700;
}


.v-select.v-input--dense .v-select__selection--comma {
  color: #0052a0;
  font-weight: 600;
}

.v-card__title {
  color: white !important;
  background-color: #026ea7;
  font-family: "Montserrat";
  font-weight: 700;
}

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 45px;
  width: 150px;
  font-family: "Montserrat";

  font-weight: 600;
  background-color: #5e6164;
  color:
    #ffd400;
  font-size: 1.0625em;
}

.swal2-confirm.btn.btn-success {
  background-color: #0052a0;
  color: #ffd400;
  font-family: "Montserrat";
  /* border-color: #DD6B55;*/
  font-weight: 600;
  margin-right: 7.5px;
  margin-left: 7.5px;
  height: 35px;
  letter-spacing: 0.25px;
  min-width: 130px;
  border-radius: 30px;
}

.swal2-styled.swal2-confirm {
  background-color: #0052a0;
  color: #ffd400;
  font-family: "Montserrat";
  /* border-color: #DD6B55;*/
  font-weight: 600;
  margin-right: 7.5px;
  margin-left: 7.5px;
  height: 35px;
  letter-spacing: 0.25px;
  min-width: 130px;
  border-radius: 30px;
}

.swal2-styled.swal2-cancel {
  background-color: #ffd400;


  color: #0052a0;
  font-family: "Montserrat";
  /* border-color: #DD6B55;*/
  font-weight: 600;
  margin-right: 7.5px;
  margin-left: 7.5px;
  height: 35px;
  letter-spacing: 0.25px;
  min-width: 130px;
  border-radius: 30px;
}

.my-custom-dialog {
  bottom: 0 !important;
  right: 0 !important;
  position: absolute !important;
}

.swal2-cancel {
  background-color: #ffd400;
  color: #0052a0;
  /* border-color: #DD6B55;*/
  font-family: "Montserrat";
  font-weight: 600;
  margin-right: 7.5px;
  margin-left: 7.5px;
  height: 35px;
  letter-spacing: 0.25px;
  min-width: 130px;
  border-radius: 30px;
}

.swal2-html-container {
  font-family: "Montserrat";

  font-weight: 600;
}

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 45px;
  width: 150px;
  font-family: "Montserrat";

  font-weight: 600;
  background-color: #2778c4;
  color: #ffd400;
  font-size: 1.0625em;
}

.swal2-title {
  font-family: "Montserrat";
  color: #0052a0;
  font-weight: 600;
}

.swal2-html-container {
  font-family: "Montserrat";

  font-weight: 600;
}

.color_back {
  background: -webkit-linear-gradient(to right, #232526, #414345);
  background: linear-gradient(to right, #232526, #414345);
}

.color_active {
  color: #026ea7 !important;
  font-size: 0.875rem !important;
}


.txt_sombra {
  text-shadow: 0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff,
    0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff, 0 1px 2px rgb(0 0 0 / 30%),
    0 3px 9px rgb(0 0 0 / 10%);
}

.txt_sombra_black {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.507);
}

.v-application--wrap {
  flex: 1 1 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  position: relative;
}

.v-text-field {
  font-family: "Montserrat";
}

.card-stone-background {
  background-color: #cee2ef;
  color: #026ea7;
}

.card__text .card-stone-background {
  background-color: #cee2ef;
  color: #026ea7;
}

.theme--light.v-subheader {
  color: #026ea7;
  font-family: "Montserrat";
  font-weight: 600;
}

.degrade {
  background: linear-gradient(90deg,
      #f9a800 0.06%,
      #f27c00 47.93%,
      #ffd400 100%);
}

.degrade_stone {
  background: linear-gradient(90deg,
      #046fd3 0.06%,
      #0052a0 47.93%,
      #013668 100%);
}

.ativo {
  background-color: #f6f6f6;
}

.cinza {
  background-color: #212121;
}

.border {
  padding: 2px;
  background-color: #ffd400 !important;
}

.v-btn {
  border-radius: 30px;
  font-family: "Montserrat";
}

.v-btn-stn-orange {
  border-radius: 30px;
  font-family: "Montserrat";
  background-color: #ffa900;
  color: #0052a0;
}

.v-container {
  font-family: "Montserrat";

  margin-left: 5px;
  margin-right: 5px;
  max-width: max-content;
  margin-bottom: 5px;
}

.text-start {
  font-family: "Montserrat";
  font-weight: 600;
  color: red;
}

.v-data-table-header {
  background-color: #95bdf8;

  font-family: "Montserrat";
  font-weight: 600;
}

tr span {
  color: #026ea7;
}

.v-data-table table th+th {
  border-left: 1px solid #026ea7ad;
}

.v-data-table table td+td {
  border-left: 1px solid #60e2d9b7;
}

.theme--light.v-input input,
.theme--light.v-input textarea {
  font-size: 13px;
  color: #0052a0;
  font-weight: 600;
  font-family: "Montserrat";
  font-weight: 600;
  letter-spacing: 0.19px;
}

.v-select__selections {
  font-family: "Montserrat";
  color: #0052a0;
  font-weight: 600;
  font-size: 13px;
}

.theme--light.v-label {
  color: #0052a0;
}


.theme--light.v-select .v-select__selections {
  font-family: "Montserrat";
  color: #0052a0;
  font-weight: 600;
}

.theme--light.v-stepper .v-stepper__label {
  font-family: "Montserrat";
  color: #0052a0;
  font-weight: 600;
}

.v-input__control .v-input__slot {
  background: white;
}

.v-stepper__step .v-stepper__step--inactive {
  color: #0052a0;
}

.v-stepper__step__step .orange {
  background-color: #0052a0;
}


.v-data-footer {
  color: #013668;
}

.v-text-field.v-text-field:not(.v-text-field-flat, .v-data-footer__select *)>.v-input__control>.v-input__slot {
  box-shadow: inset 0px 3px 6px #00000029;

  height: 50px;
  color: #2778c4;
}

.v-input--checkbox>.v-input__control>.v-input__slot {
  background-color: transparent;
}

.v-input__control .v-input__slot {
  background-color: transparent;
}

.v-container {
  font-family: "Montserrat";

  margin-left: 5px;
  margin-right: 5px;
  max-width: max-content;
  margin-bottom: 5px;
}

.v-chip__content {
  color: #026ea7;
}
</style>
