import paginationComponent from "../../../components/paginationComponent";
import helper from '../../../mixins/helper'
import LogComponent from "../../../components/LogComponent";
import HistoryComponent from "../../../components/HistoryComponent";
import BaixaComponent from "../../../components/BaixaComponent";
import {each} from "lodash";
import {isCNPJ, isCPF} from "vuetify-masks-br";
import BaixaLoteComponent from "../../../components/BaixaLoteComponent"
import SearchProtocolsComponent from "../../../components/SearchProtocolsComponent";

export default {
    components: {
        paginationComponent,
        LogComponent,
        HistoryComponent,
        BaixaComponent,
        BaixaLoteComponent,
        SearchProtocolsComponent
    },
    mixins: [helper],
    data: () => ({
        cod: null,
        formasPagamento: null,
        formas_Pagamento: [],
        dataPagamento: null,
        idTitulo: null,
        idNegociacao: null,
        numParcela: null,
        anexo_lote: null,
        tipo: null,
        check: false,
        idAcordo: null,
        valorTotal: 0,
        valorPago: 0,
        troco: 0,
        dialog: false,
        sheet: false,
        search: null,

        items: null,
        loading: false,
        pagination: [],
        list: [],
        list2: [],
        states: [],
        registros: {},
        dialog_log: false,
        dialog_history: false,
        dialog_baixa: false,
        dialog_baixa_lote: false,
        visualizacao: [],
        protocolo: '',
        motivos: [],
        protocols: [],
        all: false,
        obrigatorio: [
            (v) => !!v || "Este campo é obrigatória",
        ],
    }),
    mounted() {
        this.getProtocolos();
        var texto = this.$store.state.auth.user.domain.cpf_cnpj
        var er = /[^a-z0-9]/gi;
        texto = texto.replace(er, "");
        this.cod = texto
    },
    methods: {
        getProtocolos() {
            this.list = []
            this.$http.get("protocoloBaixa").then(
                (response) => {

                    this.list = response.body.data;
                    this.list2 = response.body.data;
                    this.pagination = response.body;
                },
                (response) => {
                    console.log(response);
                    // error callback
                }
            );

        },
        atualizar(dados) {
            this.getProtocolos()
            this.anexo_lote = null

            this.baixarModeloSolicitacaoBaixa('', dados.dados)
        },
        save(date) {
            this.$refs.menu.save(date);
        },
        retornoBusca(lista, paginacao) {
            console.log(lista)

            if (lista !== null) {
                this.list = lista
                this.pagination = paginacao

            }else{
                this.getProtocolos()
            }
        },
        navigate(page) {
            this.all =  false
            this.$http.get("protocoloBaixa?page=" + page).then(
                (response) => {
                    this.list = response.body.data;
                    this.pagination = response.body;
                },
                (response) => {
                    console.log(response);
                    // error callback
                }
            );
        },
        getData(data) {
            this.dataPagamento = data
        },
        notificacao(title, msg, icon) {
            this.$swal({
                toast: true,
                position: "center-end",
                showConfirmButton: false,
                timer: 3000,
                icon: icon,
                title: title,
                text: msg,
            });
        },
        visualizar(item) {
            this.items = item
            this.dialog = !this.dialog
        },
        setLog(dialog) {
            this.dialog_log = dialog
        },
        setHistory(dialog) {
            this.dialog_history = dialog
        },
        log(protocolo) {

            this.protocolo = protocolo
            this.$http.get("getLogProtocolo/" + protocolo).then(
                (response) => {
                    this.registros = response.body;
                    this.dialog_log = true
                },
                (response) => {
                    console.log(response);
                    // error callback
                }
            );

        },
        historico(protocolo) {

            this.$http.get("protocolos/get/" + protocolo).then(
                (response) => {
                    console.log(response)
                    this.visualizacao.empresa = response.body[0].nome_empresa
                    this.visualizacao.status = response.body[0].status_nome
                    this.visualizacao.cpf_cnpj = this.formatarCpfCnpj(response.body[0].cnpj_empresa)
                    this.visualizacao.email = response.body[0].email_empresa

                    this.visualizacao.data_insercao = (response.body[0].created_at)
                    this.visualizacao.devedor = response.body[0].name
                    this.visualizacao.dev_cpfCnpj = this.formatarCpfCnpj(response.body[0].cpf_cnpj)
                    this.visualizacao.valor_original = this.converterDinherio(response.body[0].valor_original)
                    this.visualizacao.valor_vencido = this.converterDinherio(response.body[0].valor_vencido)
                    this.visualizacao.especie = response.body[0].descricaoEspecie
                    this.visualizacao.data_emissao = this.converterDataTimestamp(response.body[0].data_emissao)
                    this.visualizacao.data_vencimento = this.converterDataTimestamp(response.body[0].data_vencimento)
                    this.visualizacao.protocolo = response.body[0].protocolo
                    this.visualizacao.motivo_observacao = response.body[0].motivo_observacao
                    if (response.body[0].motivo != null) {

                        this.visualizacao.motivo = response.body[0].motivo + "  - " + response.body[0].motivo_desc
                    }


                    this.dialog_history = true
                },
                (response) => {
                    console.log(response);
                    // error callback
                }
            );


        },
        baixaMultiplos() {
            if (this.protocols.length > 0) {
                this.dialog_baixa = true
                this.visualizacao = this.protocols
                this.$http.get("getMotivos").then(
                    (response) => {
                        this.motivos = response.data
                    },
                    (response) => {
                        console.log(response);
                        // error callback
                    }
                );
            } else {
                this.notificar(this, 'Desculpe', 'Selecione pelo menos um protocolo')
            }
        },
        baixa(item) {
            this.visualizacao = [item]
            this.dialog_baixa = true
            this.$http.get("getMotivos").then(
                (response) => {
                    this.motivos = response.data
                },
                (response) => {
                    console.log(response);
                    // error callback
                }
            );


        },
        addOrRemoveAll() {


            this.protocols = this.list

            this.all = !this.all
            if (this.all == false) {
                this.protocols = []
            }

            console.log( this.protocols)

        },
        addOrRemove(item) {
            if(this.all){
               //   this.all = !this.all
            }


            each
            var index = this.protocols.findIndex((res) => res.protocolo === item.protocolo)


            if (index == -1) {
                this.protocols.push(item)
            } else {

                this.protocols.splice(index, 1)
            }


            console.log('lista', this.list)


        },
        validarArquivo() {
            if (this.anexo_lote != null) {
                if (this.anexo_lote.type === 'application/vnd.ms-excel') {
                    this.dialog_baixa_lote = true
                    this.verificarArquivo()
                } else {
                    this.notificar(this, 'Desculpe', 'Formato do arquivo inválido')
                }

            }
        },
        verificarFile() {
            if (this.anexo_lote != null) {
                this.validarArquivo()
            } else {
                this.notificar(this, 'Desculpe', 'Insira o arquivo primeiro')
            }
        },
        verificarArquivo() {
            var reader = new FileReader();
            this.linhas_total = 0
            this.linhas_com_erros = 0
            this.linhas_com_sucesso = 0
            var csv = null;
            var cont = 1
            if (this.anexo_lote != null) {
                reader.readAsText(this.anexo_lote);
                var vm = this;
                reader.onload = function () {
                    console.log('Aberto onload')
                    csv = reader.result;
                    var lines = csv.split("\n");
                    var result = [];

                    var headers = lines[0].split(";");
                    vm.linhas_total = lines.length - 2
                    for (var i = 1; i < lines.length - 1; i++) {
                        var obj = {};
                        var objEnviar = {};
                        var currentline = lines[i].split(";");
                        cont = 1;
                        for (var j = 0; j < headers.length; j++) {
                            console.log('Aberto for Header')
                            if (typeof currentline[j] == "undefined" || currentline[j] == "") {
                                //obj[headers[j]] = currentline[j];

                                if (headers[j] == "nome") {
                                    obj[headers[j]] = "CAMPO EM BRANCO";
                                   
                                    cont++;
                                }
                                if (headers[j] == "pessoa_juridica") {
                                    obj[headers[j]] = "CAMPO EM BRANCO";
                                    cont++;
                                }
                                if (headers[j] == "cpf_cnpj") {
                                    obj[headers[j]] = "CAMPO EM BRANCO";
                                    cont++;
                                }
                                if (headers[j] == "endereco") {
                                    obj[headers[j]] = "CAMPO EM BRANCO";
                                    cont++;
                                }
                                if (headers[j] == "bairro") {
                                    obj[headers[j]] = "CAMPO EM BRANCO";
                                    cont++;
                                }
                                if (headers[j] == "numero") {
                                    obj[headers[j]] = "CAMPO EM BRANCO";
                                    cont++;
                                }
                                if (headers[j] == "municipio") {
                                    obj[headers[j]] = "CAMPO EM BRANCO";
                                    cont++;
                                }
                                if (headers[j] == "uf") {
                                    obj[headers[j]] = "CAMPO EM BRANCO";
                                    cont++;
                                }
                                if (headers[j] == "cep") {
                                    obj[headers[j]] = "CAMPO EM BRANCO";
                                    cont++;
                                }
                                if (headers[j] == "rg") {
                                    obj[headers[j]] = "OK";
                                    objEnviar[headers[j]] = currentline[j];
                                }
                                if (headers[j] == "telefone") {
                                    obj[headers[j]] = "OK";
                                    objEnviar[headers[j]] = currentline[j];
                                }
                                if (headers[j] == "email") {
                                    obj[headers[j]] = "OK";
                                    objEnviar[headers[j]] = currentline[j];
                                }
                                if (headers[j] == "cliente_observacao") {
                                    obj[headers[j]] = "OK";
                                    objEnviar[headers[j]] = currentline[j];

                                }
                                // DADOS DA DIVIDA
                                if (headers[j] == "numero_titulo") {
                                    obj[headers[j]] = "CAMPO EM BRANCO";
                                    cont++;
                                }
                                if (headers[j] == "nosso_numero") {
                                    obj[headers[j]] = "CAMPO EM BRANCO";
                                    cont++;
                                }
                                if (headers[j] == "especie") {
                                    obj[headers[j]] = "CAMPO EM BRANCO";
                                    cont++;
                                }
                                if (headers[j] == "valor_original") {
                                    obj[headers[j]] = "CAMPO EM BRANCO";
                                    cont++;
                                }
                                if (headers[j] == "valor_vencido") {
                                    obj[headers[j]] = "CAMPO EM BRANCO";
                                    cont++;
                                }
                                if (headers[j] == "data_emissao") {
                                    obj[headers[j]] = "CAMPO EM BRANCO";
                                    cont++;
                                }
                                if (headers[j] == "data_vencimento") {
                                    obj[headers[j]] = "CAMPO EM BRANCO";
                                    cont++;
                                }
                                if (headers[j] == "divida_observacao") {
                                    obj[headers[j]] = "OK";

                                }


                            } else {

                                if (headers[j] == "nome") {
                                    if (currentline[j].length <= 4) {
                                        cont++;
                                        obj[headers[j]] = "MINÍMO 4 LETRAS";
                                    } else {
                                        objEnviar[headers[j]] = currentline[j];
                                        obj[headers[j]] = "OK";
                                    }
                                }
                                if (headers[j] == "data_nascimento") {
                                    var formatoValido = /^(\d{2})[-/](\d{2})[-/](\d{4})$/;

                                    if (!formatoValido.test(currentline[j])) {
                                        cont++;
                                        obj[headers[j]] = "FORMATO ERRADO";
                                    } else {
                                        var dia = currentline[j].split("/")[0];
                                        var mes = currentline[j].split("/")[1];
                                        var ano = currentline[j].split("/")[2];
                                        var MyData = new Date(ano, mes - 1, dia);

                                        if ((MyData.getMonth() + 1 != mes)
                                            || (MyData.getDate() != dia)
                                            || (MyData.getFullYear() != ano)) {
                                            cont++;
                                            obj[headers[j]] = "VALORES INVÁLIDOS";
                                        } else {
                                            objEnviar[headers[j]] = currentline[j];
                                            obj[headers[j]] = "OK";
                                        }

                                    }
                                }
                                if (headers[j] == "pessoa_juridica") {
                                    if (isNaN(currentline[j])) {
                                        cont++;
                                        obj[headers[j]] = "PERMITIDO SOMENTE NÚMEROS";
                                    } else if ((currentline[j] == 1) || (currentline[j] == 0)) {
                                        objEnviar[headers[j]] = currentline[j];
                                        obj[headers[j]] = "OK";
                                    } else {

                                        obj[headers[j]] = "INVÁLIDO";
                                    }
                                }
                                if (headers[j] == "cpf_cnpj") {
                                    if (isNaN(currentline[j])) {
                                        cont++;
                                        obj[headers[j]] = "PERMITIDO SOMENTE NÚMEROS";
                                    } else if (currentline[j - 1] == 0) {
                                        if (!isCPF(currentline[j])) {
                                            cont++;
                                            obj[headers[j]] = "CPF INVÁLIDO";
                                        } else {
                                            objEnviar[headers[j]] = currentline[j];
                                            obj[headers[j]] = "OK";
                                        }
                                    } else if (currentline[j - 1] == 1) {
                                        if (!isCNPJ(currentline[j])) {
                                            cont++;
                                            obj[headers[j]] = "CNPJ INVÁLIDO";
                                        } else {
                                            objEnviar[headers[j]] = currentline[j];
                                            obj[headers[j]] = "OK";
                                        }
                                    } else {
                                        cont++;
                                        obj[headers[j]] = "PESSOA JURIDICA INVÁLIDO";
                                    }
                                }
                                if (headers[j] == "rg") {
                                    if (isNaN(currentline[j])) {
                                        cont++;
                                        obj[headers[j]] = "PERMITIDO SOMENTE NÚMEROS";
                                    } else if (currentline[j].length < 10) {
                                        cont++;
                                        obj[headers[j]] = "NO MÍNIMO 10 DÍGITOS";
                                    } else {
                                        objEnviar[headers[j]] = currentline[j];
                                        obj[headers[j]] = "OK";
                                    }
                                }
                                if (headers[j] == "endereco") {
                                    obj[headers[j]] = "OK";
                                    objEnviar[headers[j]] = currentline[j];
                                }
                                if (headers[j] == "bairro") {
                                    obj[headers[j]] = "OK";
                                    objEnviar[headers[j]] = currentline[j];
                                }
                                if (headers[j] == "numero") {
                                    obj[headers[j]] = "OK";
                                    objEnviar[headers[j]] = currentline[j];
                                }
                                if (headers[j] == "municipio") {
                                    obj[headers[j]] = "OK";
                                    objEnviar[headers[j]] = currentline[j];
                                }
                                if (headers[j] == "uf") {
                                    if (currentline[j].length != 2) {
                                        cont++;
                                        obj[headers[j]] = "EXATAMENTE 2 CARACTERES";
                                    } else {
                                        objEnviar[headers[j]] = currentline[j];
                                        obj[headers[j]] = "OK";
                                    }
                                }
                                if (headers[j] == "cep") {
                                    if (isNaN(currentline[j])) {
                                        cont++;
                                        obj[headers[j]] = "PERMITIDO SOMENTE NÚMEROS";
                                    } else if (currentline[j].length < 8) {
                                        cont++;
                                        obj[headers[j]] = "NO MÍNIMO 8 DÍGITOS";
                                    } else {
                                        objEnviar[headers[j]] = currentline[j];
                                        obj[headers[j]] = "OK";
                                    }
                                }
                                if (headers[j] == "telefone") {
                                    if (isNaN(currentline[j])) {
                                        cont++;
                                        obj[headers[j]] = "PERMITIDO SOMENTE NÚMEROS";
                                    } else if (currentline[j].length < 11) {
                                        cont++;
                                        obj[headers[j]] = "NO MÍNIMO 11 DÍGITOS";
                                    } else {
                                        objEnviar[headers[j]] = currentline[j];
                                        obj[headers[j]] = "OK";
                                    }
                                }
                                if (headers[j] == "email") {
                                    var email = /.+@.+\..+/;
                                    if (!email.test(currentline[j])) {
                                        cont++;
                                        obj[headers[j]] = "FORMATO INVÁLIDO";
                                    } else {
                                        objEnviar[headers[j]] = currentline[j];
                                        obj[headers[j]] = "OK";
                                    }

                                }
                                if (headers[j] == "numero_titulo") {
                                    if (isNaN(currentline[j])) {
                                        cont++;
                                        obj[headers[j]] = "PERMITIDO SOMENTE NÚMEROS";
                                    } else {
                                        objEnviar[headers[j]] = currentline[j];
                                        obj[headers[j]] = "OK";
                                    }
                                }
                                if (headers[j] == "nosso_numero") {
                                    if (isNaN(currentline[j])) {
                                        cont++;
                                        obj[headers[j]] = "PERMITIDO SOMENTE NÚMEROS";
                                    } else {
                                        objEnviar[headers[j]] = currentline[j];
                                        obj[headers[j]] = "OK";
                                    }
                                }
                                if (headers[j] == "especie") {
                                    if (isNaN(currentline[j])) {
                                        cont++;
                                        obj[headers[j]] = "PERMITIDO SOMENTE NÚMEROS";
                                    } else {
                                        objEnviar[headers[j]] = currentline[j];
                                        obj[headers[j]] = "OK";
                                    }
                                }
                                if (headers[j] == "valor_original") {
                                    currentline[j] = currentline[j].replace(",", ".")
                                    if (isNaN(currentline[j])) {
                                        cont++;
                                        obj[headers[j]] = "PERMITIDO SOMENTE NÚMEROS";
                                    } else {
                                        objEnviar[headers[j]] = currentline[j];
                                        obj[headers[j]] = "OK";
                                    }
                                }
                                if (headers[j] == "valor_vencido") {
                                    currentline[j] = currentline[j].replace(",", ".")
                                    if (isNaN(currentline[j])) {
                                        cont++;
                                        obj[headers[j]] = "PERMITIDO SOMENTE NÚMEROS";
                                    } else {
                                        objEnviar[headers[j]] = currentline[j];
                                        obj[headers[j]] = "OK";
                                    }
                                }
                                if (headers[j] == "data_emissao") {
                                    var formatoEmissaoValido = /^(\d{2})[-/](\d{2})[-/](\d{4})$/;

                                    if (!formatoEmissaoValido.test(currentline[j])) {
                                        cont++;
                                        obj[headers[j]] = "FORMATO ERRADO";
                                    } else {
                                        var diaE = currentline[j].split("/")[0];
                                        var mesE = currentline[j].split("/")[1];
                                        var anoE = currentline[j].split("/")[2];
                                        var MyDataE = new Date(anoE, mesE - 1, diaE);

                                        if ((MyDataE.getMonth() + 1 != mesE)
                                            || (MyDataE.getDate() != diaE)
                                            || (MyDataE.getFullYear() != anoE)) {
                                            cont++;
                                            obj[headers[j]] = "VALORES INVÁLIDOS";
                                        } else {
                                            objEnviar[headers[j]] = currentline[j];
                                            obj[headers[j]] = "OK";
                                        }

                                    }
                                }
                                if (headers[j] == "data_vencimento") {
                                    var formatoVencimentoValido = /^(\d{2})[-/](\d{2})[-/](\d{4})$/;

                                    if (!formatoVencimentoValido.test(currentline[j])) {
                                        cont++;
                                        obj[headers[j]] = "FORMATO ERRADO";
                                    } else {
                                        var diaV = currentline[j].split("/")[0];
                                        var mesV = currentline[j].split("/")[1];
                                        var anoV = currentline[j].split("/")[2];
                                        var MyDataV = new Date(anoV, mesV - 1, diaV);

                                        if ((MyDataV.getMonth() + 1 != mesV)
                                            || (MyDataV.getDate() != diaV)
                                            || (MyDataV.getFullYear() != anoV)) {
                                            cont++;
                                            obj[headers[j]] = "VALORES INVÁLIDOS";
                                        } else {
                                            obj[headers[j]] = "OK";
                                            objEnviar[headers[j]] = currentline[j];
                                        }

                                    }
                                }
                                if (headers[j] == "divida_observacao") {
                                    obj[headers[j]] = "OK";
                                    objEnviar[headers[j]] = currentline[j];
                                }
                                if (headers[j] == "cliente_observacao") {

                                    obj[headers[j]] = "OK";
                                    objEnviar[headers[j]] = currentline[j];
                                }

                            }
                        }
                        if (cont > 1) {
                            obj["linha"] = i + 1;
                            vm.linhas_com_erros++
                            result.push(obj);
                        } else {
                            vm.linhas_com_sucesso++
                            result.push(objEnviar);
                        }
                        //vm.linhas = obj

                    }
                    if (cont > 1) {
                        // eslint-disable-next-line no-unused-vars
                        vm.linhas = result;

                        vm.isTable = true
                        vm.isSend = false
                    } else {
                        vm.arquivo_lote = result; //JSON
                        vm.isTable = false
                        vm.isSend = true
                        vm.isArquivo = true
                        vm.notificacao("Sucesso", "Arquivo válido", "success")
                    }// eslint-disable-next-line no-unused-vars


                }

            }
        },

    },


}